import { ConversionUnit } from "../../enums/ConversionUnit";
import InvalidDataError from "../../errors/InvalidDataError";
import BaseConverter from './BaseConverter';

export default class DistanceConverter extends BaseConverter {
    public static readonly CENTIMETERS_PER_METER = 100;
    public static readonly CENTIMETERS_PER_KILOMETER = 100000;
    public static readonly CENTIMETERS_PER_INCH = 2.54;
    public static readonly CENTIMETERS_PER_FOOT = 30.48;
    public static readonly CENTIMETERS_PER_YARD = 91.44;
    public static readonly CENTIMETERS_PER_MILE = 160934;
    public static readonly METERS_PER_CENTIMETER = 0.01;
    public static readonly METERS_PER_KILOMETER = 1000;
    public static readonly METERS_PER_INCH = 0.0254;
    public static readonly METERS_PER_FOOT = 0.3048;
    public static readonly METERS_PER_YARD = 0.9144;
    public static readonly METERS_PER_MILE = 1609.34;
    public static readonly KILOMETERS_PER_CENTIMETER = 0.00001;
    public static readonly KILOMETERS_PER_METER = 0.001;
    public static readonly KILOMETERS_PER_INCH = 0.0000254;
    public static readonly KILOMETERS_PER_FOOT = 0.0003048;
    public static readonly KILOMETERS_PER_YARD = 0.0009144;
    public static readonly KILOMETERS_PER_MILE = 1.60934;
    public static readonly INCHES_PER_CENTIMETER = 0.393701;
    public static readonly INCHES_PER_METER = 39.3701;
    public static readonly INCHES_PER_KILOMETER = 39370.1;
    public static readonly INCHES_PER_FOOT = 12;
    public static readonly INCHES_PER_YARD = 36;
    public static readonly INCHES_PER_MILE = 63360;
    public static readonly FEET_PER_CENTIMETER = 0.0328084;
    public static readonly FEET_PER_METER = 3.28084;
    public static readonly FEET_PER_KILOMETER = 3280.84;
    public static readonly FEET_PER_INCH = 0.0833333;
    public static readonly FEET_PER_YARD = 3;
    public static readonly FEET_PER_MILE = 5280;
    public static readonly YARDS_PER_CENTIMETER = 0.0109361;
    public static readonly YARDS_PER_METER = 1.09361;
    public static readonly YARDS_PER_KILOMETER = 1093.61;
    public static readonly YARDS_PER_INCH = 0.0277778;
    public static readonly YARDS_PER_FOOT = 0.333333;
    public static readonly YARDS_PER_MILE = 1760;
    public static readonly MILES_PER_CENTIMETER = 0.0000062137;
    public static readonly MILES_PER_METER = 0.000621371;
    public static readonly MILES_PER_KILOMETER = 0.621371;
    public static readonly MILES_PER_INCH = 0.000015783;
    public static readonly MILES_PER_FOOT = 0.000189394;
    public static readonly MILES_PER_YARD = 0.000568182;

    private value: number;
    private fromUnit: ConversionUnit;

    constructor(value: number, fromUnit: ConversionUnit) {
        super();

        if (value < 0) {
            throw new InvalidDataError(`DistanceConverter constructor() Value "${value}" must be greater than or equal to zero.`);
        }

        if (DistanceConverter.convertsFrom().indexOf(fromUnit) === -1) {
            throw new InvalidDataError(`DistanceConverter constructor() Unit "${fromUnit}" cannot be handled.`);
        }

        this.value = value;
        this.fromUnit = fromUnit;
    }

    public static convertsFrom(): Array<ConversionUnit> {
        return [
            ConversionUnit.CENTIMETERS,
            ConversionUnit.METERS,
            ConversionUnit.KILOMETERS,
            ConversionUnit.INCHES,
            ConversionUnit.FEET,
            ConversionUnit.YARDS,
            ConversionUnit.MILES,
        ];
    }

    public static convertsTo(): Array<ConversionUnit> {
        return [
            ConversionUnit.CENTIMETERS,
            ConversionUnit.METERS,
            ConversionUnit.KILOMETERS,
            ConversionUnit.INCHES,
            ConversionUnit.FEET,
            ConversionUnit.YARDS,
            ConversionUnit.MILES,
        ];
    }

    public convert(toUnit: ConversionUnit): number {
        switch (toUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.convertToCentimeters();
            case ConversionUnit.METERS:
                return this.convertToMeters();
            case ConversionUnit.KILOMETERS:
                return this.convertToKilometers();
            case ConversionUnit.INCHES:
                return this.convertToInches();
            case ConversionUnit.FEET:
                return this.convertToFeet();
            case ConversionUnit.YARDS:
                return this.convertToYards();
            case ConversionUnit.MILES:
                return this.convertToMiles();
            default:
                throw new InvalidDataError(`DistanceConverter.convert() Unit "${toUnit}" is not a valid unit. See "convertsTo()"`);
        }
    }

    public convertToCentimeters(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.CENTIMETERS_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.CENTIMETERS_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.CENTIMETERS_PER_INCH;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.CENTIMETERS_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.CENTIMETERS_PER_YARD;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.CENTIMETERS_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToCentimeters() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToMeters(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.METERS_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.METERS_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.METERS_PER_INCH;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.METERS_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.METERS_PER_YARD;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.METERS_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToMeters() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToKilometers(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.KILOMETERS_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.KILOMETERS_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.KILOMETERS_PER_INCH;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.KILOMETERS_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.KILOMETERS_PER_YARD;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.KILOMETERS_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToKilometers() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToInches(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.INCHES_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.INCHES_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.INCHES_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.INCHES_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.INCHES_PER_YARD;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.INCHES_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToInches() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToFeet(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.FEET_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.FEET_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.FEET_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.FEET_PER_INCH;
            case ConversionUnit.FEET:
                return this.value;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.FEET_PER_YARD;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.FEET_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToFeet() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToYards(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.YARDS_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.YARDS_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.YARDS_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.YARDS_PER_INCH;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.YARDS_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value;
            case ConversionUnit.MILES:
                return this.value * DistanceConverter.YARDS_PER_MILE;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToYards() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    public convertToMiles(): number {
        switch (this.fromUnit) {
            case ConversionUnit.CENTIMETERS:
                return this.value * DistanceConverter.MILES_PER_CENTIMETER;
            case ConversionUnit.METERS:
                return this.value * DistanceConverter.MILES_PER_METER;
            case ConversionUnit.KILOMETERS:
                return this.value * DistanceConverter.MILES_PER_KILOMETER;
            case ConversionUnit.INCHES:
                return this.value * DistanceConverter.MILES_PER_INCH;
            case ConversionUnit.FEET:
                return this.value * DistanceConverter.MILES_PER_FOOT;
            case ConversionUnit.YARDS:
                return this.value * DistanceConverter.MILES_PER_YARD;
            case ConversionUnit.MILES:
                return this.value;
            default:
                throw new InvalidDataError(`DistanceConverter.convertToMiles() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }
}
