// tslint:disable: variable-name
import { Table } from '../enums/Table';
import { deepCopy } from "../utils/deepCopy";
import CrudModel from "./generic/CrudModel";
import InvalidDataError from '../errors/InvalidDataError';

export default class Unit extends CrudModel<Unit> {

    public id: number;
    public serial: string;
    public rf_id: string;
    public uuid: string;
    public batch_id: number;
    public firmware_id: number;
    public created_at?: Date;
    public updated_at?: Date;

    constructor() {
        super();
        this.rf_id = null;
        this.serial = null;
        this.uuid = null;
        this.firmware_id = null;
        this.created_at = null;
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.NODES;
    }

    public duplicate(): Unit {
        const properties = deepCopy(this);
        const newCopy = new Unit();
        newCopy.parseJson(properties);
        return newCopy;
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for User parseData()');
        }

        this.id = data.unit_id;
        this.serial = data.unit_serial;
        this.rf_id = data.unit_rf_id;
        this.uuid = data.unit_uuid;
        this.batch_id = data.unit_batch_id;
        this.firmware_id = data.unit_firmware_id;
        this.created_at = data.unit_created_at;
        this.updated_at = data.unit_updated_at;
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for Unit ParseJson');
        }

        this.id = json.id;
        this.serial = json.serial;
        this.rf_id = json.rf_id;
        this.uuid = json.uuid;
        this.batch_id = json.batch_id;
        this.firmware_id = json.firmware_id;

        if (json.created_at) {
            this.created_at = json.created_at;
        }
        
        if (json.updated_at) {
            this.updated_at = json.updated_at;
        }
    }
}
