import { Chart } from 'angular-highcharts';
import { Component, Injectable, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

import { CustomEvent } from 'src/app/enums/CustomEvent';
import { Event } from 'src/app/core/enums/Event';
import { TritonChart } from 'src/app/enums/TritonChart';
import EventBusService from 'src/app/core/services/EventBusService';
import IEventListener from 'src/app/core/interfaces/IEventListener';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
// import ThemeAngularService from 'src/app/services/theme/theme-angular.service';

// @ts-ignore
HighchartsMore(Highcharts);

// @ts-ignore
HighchartsSolidGauge(Highcharts);

const tritonScoreColours = {
    readiness: '#a9ec96',
    focus: '#71a4f1',
    intensity: '#f83f4a',
};

const readinessColours = {
    today: '#b6ffa1',
    week: '#86f168',
    month: '#3aaf19',
};

const focusColours = {
    butterfly: '#f1be6b',
    backstroke: '#ef7e40',
    breaststroke: '#3ad0e3',
    freestyle: '#867cf0',
};

const intensityColours = {
    distance: '#f83f4a',
    active: '#bf2049',
    swimIntensity: '#950e7a',
};

@Component({
    selector: 'app-triton-activity-gauge',
    templateUrl: './triton-activity-gauge.component.html',
    styleUrls: ['./triton-activity-gauge.component.scss'],
})
@Injectable()
export class TritonActivityGaugeComponent implements OnInit {

    @Input() type: TritonChart;
    @Input() data: any;
    @Input() height: string | number;
    @Input() width: number;
    @Input() initialValue: any;
    @Input() manualWorkout: boolean;
    @Input() initialRadius: number;
    @Input() initialInnerRadius: number;
    @Input() tritonScore: number;
    @Input() score: number;

    private onThemeChangedHandler: IEventListener;
    private eventBus: EventBusService;

    public toolTipColor: string;
    public chartColours: any;
    public chart: Chart;
    public scoreLabelRef: any;
    public tritonScoreLabelRef: any;

    series: Array<any> = [];

    constructor(
        private eventBusAngularService: EventBusAngularService,
        // private themeAngularService: ThemeAngularService
    ) {
        this.eventBus = this.eventBusAngularService.service;
    }

    ngOnInit() {
        this.onThemeChangedHandler = this.eventBus.addListener(Event.CUSTOM, this.onThemeChanged);

        // if (this.themeAngularService.darkMode) {
        this.toolTipColor = '#FFFFFF';
        // } else {
        //     this.toolTipColor = '#000000';
        // }

        if (this.type === TritonChart.INTENSITY) {
            this.chartColours = intensityColours;
        } else if (this.type === TritonChart.READINESS) {
            this.chartColours = readinessColours;
        } else if (this.type === TritonChart.FOCUS) {
            this.chartColours = focusColours;
        } else if (this.type === TritonChart.SCORE) {
            this.chartColours = tritonScoreColours;
        } else {
            this.chartColours = tritonScoreColours;
        }

        let initialRadius = this.initialRadius || 112;
        let initialInnerRadius = this.initialInnerRadius || 103;

        if (this.manualWorkout) {
            this.series.push({
                name: 'Readiness',
                data: [{
                    color: this.chartColours.readiness,
                    radius: `${initialRadius}%`,
                    innerRadius: `${initialInnerRadius}%`,
                    y: this.data.readiness,
                    lineWidth: 5,
                }]
            });
        } else {
            for (const [key, value] of Object.entries(this.data)) {
                this.series.push({
                    name: key,
                    data: [
                        {
                            color: this.chartColours[key],
                            radius: `${initialRadius}%`,
                            innerRadius: `${initialInnerRadius}%`,
                            y: value,
                            lineWidth: 5,
                        },
                    ],
                });
                initialInnerRadius -= 20;
                initialRadius -= 20;
            }
        }

        // @TODO to show initial value (or avg) for our use case we need
        // to overide the this.refresh(data) with another value. However,
        // it requires a tooltip object
        // const val = this.initialValue;

        // Highcharts.wrap(Highcharts.Tooltip.prototype, 'hide', function(p, delay) {
        //     if (this.options.alwaysVisible) {
        //         const data = this.chart.series[0].data[0];
        //         return this.refresh(data);
        //     }

        //     p.call(this, delay);
        // });

        this.renderChart();
    }

    ngOnDestroy() {
        this.onThemeChangedHandler.remove();
    }

    renderChart() {
        const makeLabel = function(chart, score?: number, label?: string): void {
            if (!score) {
                if (this.tritonScore) {
                    score = this.tritonScore;
                    label = '<span class="gauge-center-label" style="font-size:10px;">Triton Score</span>';
                } else if (this.score) {
                    score = this.score;
                    label = '';
                } else {
                    return;
                }
            }

            const newX = chart.plotWidth / 2 + chart.plotLeft;
            const newY = chart.plotHeight / 2 + chart.plotTop + 13;

            if (!this.scoreLabelRef) {
                this.scoreLabelRef = chart.renderer
                    .text(`<span class="gauge-center-label" style="font-size:42px;">${score}</span>`, newX, newY)
                    .attr({ align: 'center' })
                    .css({
                        color: chart.toolTipColor,
                        fontSize: '1.2rem',
                        fontFamily: 'PT Sans',
                        'text-align': 'center',
                    })
                    .add();
            } else {
                this.scoreLabelRef.attr({
                    text: `<span class="gauge-center-label" style="font-size:42px;">${score}</span>`
                });
            }

            if (!this.tritonScoreLabelRef) {
                this.tritonScoreLabelRef = chart.renderer
                    .text(label, newX, newY + 14)
                    .attr({ align: 'center' })
                    .css({
                        color: chart.toolTipColor,
                        fontSize: '1.2rem',
                        fontFamily: 'PT Sans',
                        'text-align': 'center',
                    })
                    .add();
            } else {
                this.tritonScoreLabelRef.attr({
                    text: label
                });
            }
        }.bind(this);

        const gaugeClick = function(value: number): void {
            makeLabel(this.chart, value);
        }.bind(this);

        this.chart = new Chart({
            chart: {
                type: 'solidgauge',
                height: this.height || '100%',
                backgroundColor: 'none',
                width: this.width || '170',
                style: {
                    overflow: 'visible',
                    fontFamily: 'PT Sans'
                },
                spacingLeft: 0,
                spacingBottom: 0,
                spacingRight: 0,
                spacingTop: 0,
                events: {
                    load() {
                        makeLabel(this);
                    },
                    redraw() {
                        makeLabel(this);
                    },
                    click(event) {
                        if (event && event.target) {
                            const element: any = event.target;
                            if (element.classList.contains('gauge-center-label')) {
                                makeLabel(this);
                            }
                        }
                    }
                }
            },
            credits: { enabled: false },
            title: { text: null },
            tooltip: { enabled: false },
            pane: {
                startAngle: 180,
                endAngle: 450,
                background: null,
            },
            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: [],
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: { enabled: false },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true,
                },
                series: {
                    point: {
                        events: {
                            click(event) {
                                gaugeClick(event.point.y);
                            }
                        }
                    }
                }
            },
            legend: { enabled: true },
            series: [...this.series],
        });
    }

    public onThemeChanged = (data: any): void => {
        const type: CustomEvent = data.type;
        const isDarkMode: boolean = data.darkMode;

        switch (type) {
            case CustomEvent.THEME_CHANGED:
                if (isDarkMode) {
                    this.toolTipColor = '#FFFFFF';
                } else {
                    this.toolTipColor = '#000000';
                }

                this.renderChart();
                break;
        }
    }
}
