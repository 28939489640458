// tslint:disable: variable-name
import Unit from './Unit';
import { Gender } from '../enums/Gender';
import { deepCopy } from '../utils/deepCopy';
import InvalidDataError from '../errors/InvalidDataError';
import UserTeam from './UserTeam';
import Preferences from './Preferences';
import CrudModel from './generic/CrudModel';
import { TrainingType } from '../enums/TrainingType';
import { UserType } from '../enums/UserType';
import { Table } from '../enums/Table';
import TritonScore from './triton_score/TritonScore';
import FocusScore from './triton_score/FocusScore';
import { LogLevel } from '../enums/LogLevel';

export default class User extends CrudModel<User> {
    public id: number;
    public type: UserType;
    public email: string;
    public first_name: string;
    public last_name: string;
    public phone_number: string;
    public dial_code: number;
    public country_code: string;
    public validated_number: boolean;
    public node_id: number;
    public image: string;
    public height: number;
    public weight: number;
    public birth_date: Date;
    public gender: Gender;
    public para_athlete: boolean;
    public training_type: TrainingType;
    public training_frequency: number;
    public triton_score_id: number;
    public unit_updated_at: Date;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public node?: Unit;
    public teams: Array<UserTeam>;
    public preferences: Preferences;
    public full_name: string;
    public live_log_token_id?: number;
    public live_log_level?: LogLevel;
    public triton_score?: TritonScore;

    constructor() {
        super();
        this.id = undefined;
        this.type = undefined;
        this.teams = [];
        this.first_name = '';
        this.last_name = '';
        this.email = '';
        this.phone_number = '';
        this.dial_code = undefined;
        this.country_code = '';
        this.validated_number = false;
        this.node_id = null;
        this.image = '';
        this.height = undefined;
        this.weight = undefined;
        this.birth_date = null;
        this.gender = undefined;
        this.para_athlete = false;
        this.training_type = undefined;
        this.training_frequency = undefined;
        this.triton_score_id = null;
        this.unit_updated_at = null;
        this.live_log_token_id = null;
        this.live_log_level = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.teams = [];
    }
    
    public getTableName(): string {
        return Table.USERS;
    }

    public getDerivedProperties(): Array<string> {
        return [
            'full_name'
        ];
    }

    public duplicate(): User {
        const properties = deepCopy(this);
        const newCopy = new User();
        newCopy.parseJson(properties);
        
        return newCopy;
    }

    public parseJson(json: any): void {
        super.parseJson(json);

        if (json.node) {
            this.node = new Unit();
            this.node.parseJson(json.node);
        }

        if (json.teams) {
            json.teams.forEach((team: any) => {
                const parsedTeam = new UserTeam();
                parsedTeam.parseJson(team);

                this.teams.push(parsedTeam);
            });
        }

        if (json.triton_score) {
            this.triton_score = new TritonScore();
            this.triton_score.parseJson(json.triton_score);
        }

        this.deriveData();
    }

    public parseData(data: any): void {
        super.parseData(data);
        
        if (data.unit_id) {
            this.node = new Unit();
            this.node.parseData(data);
        }

        if (data.triton_score_id) {
            const arrayData = [data];

            const tritonScore = new TritonScore();
            tritonScore.parseData(arrayData);

            // Currently we don't parse an entire TritonScore from a single data set.
            // It's the job of the developer to separately parse the FocusScore and
            // set it to the property themselves.
            // Since the TritonScore's IntensityScore and FocusScore share the same StrokeScores
            // but the IntensityScore StrokeScores have no submodels,
            // we currently can't join the same table twice and distinguish
            // which StrokeScores each model should parse

            if (tritonScore.focus_score_id) {
                const focusScore = new FocusScore();
                focusScore.parseData(arrayData);
                tritonScore.focus = focusScore;
            }

            this.triton_score = tritonScore;
        }

        this.deriveData();
    }

    public deriveData(): void {
        this.full_name = this.getFullName();
    }

    public getTeamsCoached(): Array<UserTeam> {
        const teamsCoached: Array<UserTeam> = [];

        this.teams.forEach((team) => {
            if (team.isCoach()) {
                teamsCoached.push(team);
            }
        });

        return teamsCoached;
    }

    public addTeam(userTeam: UserTeam) {
        if (userTeam.user_id !== this.id) {
            throw new InvalidDataError('User Team is not meant for this user');
        }

        for (let i = 0; i < this.teams.length; i++) {
            if (userTeam.id === this.teams[i].id) {
                this.teams[i].parseJson(userTeam);
                return;
            }
        }

        this.teams.push(userTeam.duplicate());
    }

    public deleteTeam(userTeam: UserTeam): void {
        for (let i = 0; i < this.teams.length; i++) {
            const curTeam = this.teams[i];
            if (curTeam === userTeam) {
                this.teams.splice(i, 1);
                return;
            }
        }

        throw new InvalidDataError(`deleteTeam() Exact UserTeam with id ${userTeam.id}.`);
    }

    public getUserTeam(teamId: number): UserTeam {
        for (let i = 0; i < this.teams.length; i++) {
            const userTeam = this.teams[i];

            if (userTeam.team_id === teamId) {
                return userTeam;
            }
        }

        throw new InvalidDataError(`No user team found for team_id ${teamId} for user ${this.id}`);
    }

    private getFullName(): string {
        const firstName = this.first_name;
        const lastName = this.last_name;

        let fullName = '';
        if (firstName && lastName) {
            fullName = firstName + ' ' + lastName;
        } else if (firstName) {
            fullName = firstName;
        } else if (lastName) {
            fullName = lastName;
        }

        return fullName;
    }
}
