import { ConversionUnit } from '../../enums/ConversionUnit';
import { Pool } from '../../enums/Pool';
import { StrokeType } from '../../enums/StrokeType';
import { SwimPhase } from '../../enums/SwimPhase';
import { Table } from '../../enums/Table';
import { deepCopy } from '../../utils/deepCopy';
import CrudModel from '../generic/CrudModel';
import DateTimeConverter from '../../utils/converters/DateTimeConverter';
import FocusPhaseScore from './FocusPhaseScore';
import InvalidDataError from '../../errors/InvalidDataError';
import IllegalStateError from '../../errors/IllegalStateError';

export default class StrokeScore extends CrudModel<StrokeScore> {
    public id: number;
    public triton_score_id: number;
    public focus_score_id: number;
    public intensity_score_id: number;
    public stroke_type: StrokeType;
    public pool: Pool;
    public stroke_intensity_score: number;
    public volume: number;
    public focus_stroke_score: number;
    public focus_everyone_score: number;
    public likeyou_score: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public focus_phase_scores: Array<FocusPhaseScore>;

    constructor() {
        super();
        this.focus_phase_scores = [];
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }

    public duplicate(): StrokeScore {
        const properties = deepCopy(this);
        const strokeScore = new StrokeScore();
        strokeScore.parseJson(properties);
        return strokeScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for StrokeScore parsedata');
        }

        this.id = data[0].stroke_score_id;
        this.triton_score_id = data[0].stroke_score_triton_score_id;
        this.focus_score_id = data[0].stroke_score_focus_score_id;
        this.intensity_score_id = data[0].stroke_score_intensity_score_id;
        this.stroke_type = data[0].stroke_score_stroke_type;
        this.pool = data[0].stroke_score_pool;
        this.stroke_intensity_score = data[0].stroke_score_stroke_intensity_score;
        this.volume = data[0].stroke_score_volume;
        this.focus_stroke_score = data[0].stroke_score_focus_stroke_score;
        this.focus_everyone_score = data[0].stroke_score_focus_everyone_score;
        this.likeyou_score = data[0].stroke_score_likeyou_score;

        if (data[0].stroke_score_created_at) {
            if (data[0].stroke_score_created_at instanceof Date) {
                this.created_at = data[0].stroke_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data[0].stroke_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }
        
        if (data[0].stroke_score_updated_at) {
            if (data[0].stroke_score_updated_at instanceof Date) {
                this.updated_at = data[0].stroke_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data[0].stroke_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].stroke_score_deleted_at) {
            if (data[0].stroke_score_deleted_at instanceof Date) {
                this.deleted_at = data[0].stroke_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data[0].stroke_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        let currentFocusPhaseScoreId: number;
        let currentFocusPhaseScoreData = [];

        for (let i = 0; i < data.length; i++) { // iterate and group metrics
            const curData = data[i];

            if (!currentFocusPhaseScoreId) {
                currentFocusPhaseScoreId = curData.focus_phase_score_id;
            }

            if (curData.stroke_score_id !== this.id) {       
                throw new InvalidDataError(`FocusPhaseScore with stroke_score_id ${curData.stroke_score_id} does not belong to StrokeScore with id ${this.id}`);
            } else if (curData.focus_phase_score_id === currentFocusPhaseScoreId) {
                currentFocusPhaseScoreData.push(curData);
            } else {
                if (currentFocusPhaseScoreId) {
                    const focusPhaseScore = new FocusPhaseScore();
                    focusPhaseScore.parseData(currentFocusPhaseScoreData);
                    this.focus_phase_scores.push(focusPhaseScore);
                }

                currentFocusPhaseScoreData = [];
                currentFocusPhaseScoreData.push(curData);
                currentFocusPhaseScoreId = curData.focus_phase_score_id;
            }
        }

        if (currentFocusPhaseScoreId) {
            const newFocusPhaseScore = new FocusPhaseScore();
            newFocusPhaseScore.parseData(currentFocusPhaseScoreData);
            this.focus_phase_scores.push(newFocusPhaseScore);
        }
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for StrokeScore parseJson');
        }
        
        this.id = json.id;
        this.triton_score_id = json.triton_score_id;
        this.focus_score_id = json.focus_score_id;
        this.intensity_score_id = json.intensity_score_id;
        this.stroke_type = json.stroke_type;
        this.pool = json.pool;
        this.stroke_intensity_score = json.stroke_intensity_score;
        this.volume = json.volume;
        this.focus_stroke_score = json.focus_stroke_score;
        this.focus_everyone_score = json.focus_everyone_score;
        this.likeyou_score = json.likeyou_score;
        this.created_at = json.created_at;
        this.updated_at = json.updated_at;
        this.deleted_at = json.deleted_at;

        if (json.focus_phase_scores) {
            const focusPhaseScores: Array<FocusPhaseScore> = [];

            json.focus_phase_scores.forEach((score: FocusPhaseScore) => {
                const focusPhaseScore = new FocusPhaseScore();
                focusPhaseScore.parseJson(score);
                focusPhaseScores.push(focusPhaseScore);
            });

            this.focus_phase_scores = focusPhaseScores;
        }
    }

    public getHighestScoringPhase(): FocusPhaseScore {
        if (!this.focus_phase_scores.length) {
            throw new InvalidDataError(`Stroke Score has no focus_phase_scores and cannot get the highest score.`);
        }

        let maxScore: FocusPhaseScore;

        for (let i = 0; i < this.focus_phase_scores.length; i++) {
            const score = this.focus_phase_scores[i];

            // Ignore GENERAL SwimPhase, for use by data science only
            if (score.phase === SwimPhase.GENERAL) {
                continue;
            }

            if (maxScore === undefined || score.myself_score > maxScore.myself_score) {
                maxScore = score;
            }
        }

        if (!maxScore) {
            throw new IllegalStateError(`StrokeScore getHighestScoringPhase() no valid highest score.`);
        }

        return maxScore;
    }

    public getLowestScoringPhase(): FocusPhaseScore {
        if (!this.focus_phase_scores.length) {
            throw new InvalidDataError(`Stroke Score has no focus_phase_scores and cannot get the lowest score.`);
        }

        let minScore: FocusPhaseScore;

        for (let i = 0; i < this.focus_phase_scores.length; i++) {
            const score = this.focus_phase_scores[i];

            // Ignore GENERAL SwimPhase, for use by data science only
            if (score.phase === SwimPhase.GENERAL) {
                continue;
            }

            if (minScore === undefined || score.myself_score < minScore.myself_score) {
                minScore = score;
            }
        }

        if (!minScore) {
            throw new IllegalStateError(`StrokeScore getLowestScoringPhase() no valid lowest score.`);
        }

        return minScore;
    }

    public getPhaseScoreForPhase(phase: SwimPhase): FocusPhaseScore {
        let index: number;

        switch (phase) {
            case SwimPhase.OVERWATER:
                index = this.focus_phase_scores.findIndex(score => score.phase === SwimPhase.OVERWATER);
                break;
            case SwimPhase.UNDERWATER:
                index = this.focus_phase_scores.findIndex(score => score.phase === SwimPhase.UNDERWATER);
                break;
            case SwimPhase.TRANSITION:
                index = this.focus_phase_scores.findIndex(score => score.phase === SwimPhase.TRANSITION);
                break;
        }

        return this.focus_phase_scores[index];
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.STROKE_SCORES;
    }

    public getDistanceUnit(): ConversionUnit.METERS | ConversionUnit.YARDS {
        return this.pool % 2 === 0
                ? ConversionUnit.METERS
                : ConversionUnit.YARDS;
    }
}
