import { Injectable } from '@angular/core';
import EventBusService from 'src/app/core/services/EventBusService';

@Injectable({
    providedIn: 'root',
})
export default class EventBusAngularService {

    public service: EventBusService;

    constructor() {
        this.service = EventBusService.getInstance();
    }
}
