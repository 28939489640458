import { ConversionUnit } from "../../enums/ConversionUnit";
import { Table } from '../../enums/Table';
import { deepCopy } from "../../utils/deepCopy";
import CrudModel from "../generic/CrudModel";
import DateTimeConverter from "../../utils/converters/DateTimeConverter";
import InvalidDataError from "../../errors/InvalidDataError";

export default class ReadinessScore extends CrudModel<ReadinessScore> {
    public id: number;
    public readiness_score: number;
    public rpe: number;
    public compliance: number;
    public daily_load: number;
    public daily_load_goal: number;
    public acute_load: number;
    public chronic_load: number;
    public injury_risk: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public workout_at: Date;
    public acute_goal?: number;

    constructor() {
        super();
        this.readiness_score = null;
        this.rpe = null;
        this.compliance = null;
        this.daily_load = null;
        this.daily_load_goal = null;
        this.acute_load = null;
        this.chronic_load = null;
        this.injury_risk = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }

    public duplicate(): ReadinessScore {
        const properties = deepCopy(this);
        const readinessScore = new ReadinessScore();
        readinessScore.parseJson(properties);
        return readinessScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for ReadinessScore parseJson');
        }

        this.id = json.id;
        this.readiness_score = json.readiness_score;
        this.rpe = json.rpe;
        this.compliance = json.compliance;
        this.daily_load = json.daily_load;
        this.daily_load_goal = json.daily_load_goal;
        this.acute_load = json.acute_load;
        this.chronic_load = json.chronic_load;
        this.injury_risk = json.injury_risk;

        if (json.created_at) {
            this.created_at = new Date(json.created_at);
        }

        if (json.updated_at) {
            this.updated_at = new Date(json.updated_at);
        }

        if (json.deleted_at) {
            this.deleted_at = new Date(json.deleted_at);
        }

        if (json.workout_at) {
            this.workout_at = new Date(json.workout_at);
        }
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for ReadinessScore parsedata');
        }

        this.id = data.readiness_score_id;
        this.readiness_score = data.readiness_score_readiness_score;
        this.rpe = data.readiness_score_rpe;
        this.compliance = data.readiness_score_compliance;
        this.daily_load = data.readiness_score_daily_load;
        this.daily_load_goal = data.readiness_score_daily_load_goal;
        this.acute_load = data.readiness_score_acute_load;
        this.chronic_load = data.readiness_score_chronic_load;
        this.injury_risk = data.readiness_score_injury_risk;

        if (data.readiness_score_created_at) {
            if (data.readiness_score_created_at instanceof Date) {
                this.created_at = data.readiness_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data.readiness_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.readiness_score_updated_at) {
            if (data.readiness_score_updated_at instanceof Date) {
                this.updated_at = data.readiness_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data.readiness_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.readiness_score_deleted_at) {
            if (data.readiness_score_deleted_at instanceof Date) {
                this.deleted_at = data.readiness_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data.readiness_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.readiness_score_workout_at) {
            if (data.readiness_score_workout_at instanceof Date) {
                this.workout_at = data.readiness_score_workout_at;
            } else {
                const workoutAtDateTimeConverter = new DateTimeConverter(data.readiness_score_workout_at, ConversionUnit.SQL_DATETIME);
                this.workout_at = workoutAtDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }
    }

    public getAcuteGoal() {
        if (!this.acute_goal) {
            this.acute_goal = this.chronic_load * 1.15;
        }

        return this.acute_goal;
    }

    public getDailyScore() {
        if (this.getAcuteGoal() === 0) {
            return 0;
        }

        let dailyGoal = 0;
        if (this.daily_load_goal > 0) {
            dailyGoal = this.daily_load / this.daily_load_goal * 100;
        } else {
            dailyGoal = this.daily_load / this.getAcuteGoal() * 100;
        }

        return Math.round(dailyGoal);
    }

    public getWeeklyScore() {
        if (this.getAcuteGoal() === 0) {
            return 0;
        }

        return this.acute_load > this.getAcuteGoal() ? 100 : Math.round(100 * this.acute_load / this.getAcuteGoal());
    }

    public getMonthScore() {
        if (this.getAcuteGoal() === 0) {
            return 0;
        }

        if (this.acute_load > this.getAcuteGoal()) {
            return Math.round(100 * this.chronic_load / this.acute_load);
        }

        // 87 is the ratio of chronic load to acute load.
        // constant determined by the data science team.
        return 87;
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.READINESS_SCORES;
    }
}
