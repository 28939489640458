import InvalidDataError from "../../errors/InvalidDataError";

export default class Model<T> {
    /**
     * Model initialization conventions:
     * 1) strings are initialized as ''
     * 2) numbers are initialized as undefined
     * 3) Models are initialized as null
     * 4) _date AND _at represent Dates and can be initialized in any way
     * 5) _id represent keys to other Models and can be iniialzed in any way
     **/
    constructor() {
        this.parseJson.bind(this);
        this.duplicate.bind(this);
        this.object.bind(this);
    }

    /**
     * Returns a model that is an exact duplicate of this one
     */
    public duplicate(): T {
        throw new Error('Classes that extend Model must implement duplicate()');
    }

    /**
     * Turns this model into a javascript object with all properties
     * accounted for
     */
    public object(): any {
        const object: any = {};
        const keys = Object.keys(this);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            if ((this as any)[key] instanceof Model) {
                object[key] = (this as any)[key].object();
            } else {
                object[key] = (this as any)[key];
            }
        }
    
        return object;
    }

    /**
     * Loads properties from object provided. The provided object
     * can not include any properties not associated with this model
     * 
     * @param data - object representing a Model of this type
     */
    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('Model.parseJson(): No Data');
        }     

        const keys = Object.keys(json);

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];

            // @TODO: This can be uncommented once users have all been
            // transitioned to the new architecture and the database
            // for production matches staging, this will enforce strict
            // object parsing for our Models
            //
            // if (!this.hasOwnProperty(key)) {
            //     throw new InvalidDataError(`Model.parseJson(): Unknown property ${key}`);
            // } 
            
            // Database naming conventions dictate that DATETIME columns must end in '_at',
            // DATE columns must end in '_date', relations into other tables must be numbers 
            // and end in '_id'. These postfixes are exclusively reserved for those data types.
            if (key.endsWith('_at') || key.endsWith('_date')) {
                if (!json[key]) {
                    (this as any)[key] = null;
                } else if (json[key] instanceof Date) {
                    (this as any)[key] = json[key];
                } else if (typeof json[key] === 'string') {
                    (this as any)[key] = new Date(json[key]);
                } else {
                    throw new InvalidDataError(`Model.parseJson() '${key}' exists but is not a string or a Date object. ${key}: ${json[key]}`);
                }
            } else if (key.endsWith('_id') && json[key] !== null && json[key] !== undefined) {
                if (!(typeof json[key] === 'number')) {
                    throw new InvalidDataError(`Model.parseJson() '${key}' exists but is not a number, '_id' is a reserved postfix for numbers representing row id's. ${key}: ${json[key]}`);
                }

                (this as any)[key] = json[key];
            } else if (!(typeof (this as any)[key] === 'object')) {
                if (typeof (this as any)[key] === 'string') {
                    if (!json[key]) {
                        (this as any)[key] = null;
                        continue;
                    }
                }

                (this as any)[key] = json[key];
            }
        }
    }
}