import InvalidDataError from '../errors/InvalidDataError';

export enum Pool {
    LCM = 0,
    SCY = 1,
    SCM = 2
}

export function poolToName(pool: Pool): string {
    switch (pool) {
        case Pool.SCY:
            return 'scy';
        case Pool.SCM:
            return 'scm';
        case Pool.LCM:
            return 'lcm';
        default:
            throw new InvalidDataError(`Pool toName(): unknown pool ${pool}`);
    }
}