import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TwIconComponent } from './tw-icon.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TwIconComponent],
  exports: [TwIconComponent],
})
export class TwIconModule { }
