import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { StrokeType, strokeTypeToName } from 'src/app/core/enums/StrokeType';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'tw-icon',
    templateUrl: './tw-icon.component.html',
    styleUrls: ['./tw-icon.component.scss'],
})
export class TwIconComponent implements OnInit, OnChanges {

    @Input() strokeType: StrokeType;
    @Input() white: boolean;
    @Input() size: number;

    public imageLink: string;
    public iconAlt: string;

    constructor() { }

    ngOnInit() {
        if (!this.size) {
            this.size = 40;
        }

        if (!this.white) {
            this.white = false;
        }

        this.setImageLink();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setImageLink();
    }

    setImageLink() {
        if (this.strokeType) {
            const strokeName = strokeTypeToName(this.strokeType);
            const white = this.white ? '-white' : '';
            this.imageLink = `../../../assets/images/strokes/${strokeName.toLowerCase()}${white}.svg`;
        }
    }
}
