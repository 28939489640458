<div class="athlete-tile" [ngClass]="[isPrivate ? 'private' : 'public']">
    <ng-container *ngIf="!isFiller">
        <div class="athlete-image" (click)="onClick(user.id)">
            <!--
                <app-triton-score-banner *ngIf="showTritonScore" [type]="'athlete'" [score]="tritonScore" [private]="isPrivate"></app-triton-score-banner>
            -->
            <img [src]="athleteImage" (error)="updateUrl()" *ngIf="athleteImage" />
            <div class="empty-image" *ngIf="athleteImage === ''">
                <app-avatar [user]="user" [inverse]="true"></app-avatar>
            </div>
        </div>
        <div class="athlete-name">{{athleteName}}</div>
    </ng-container>
</div>
