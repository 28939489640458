export enum EmptyState {
    NO_DATA = '-',
    NO_WORKOUTS = 'No workouts to display! Start a new workout!',
    WAIT_FOR_DATA = 'Wait for data',
    NO_WORKOUT_DATA = 'No data in the workout.',
    WORKOUT_NOT_SYNCED = 'Your activity is syncing!',
    NOT_KNOWN_UNIT = 'Unit is not known.',
    TIME_GENERIC = '--',
    TIME_SECONDS = '--:--',
    TIME_MILLISECONDS = '--:--',
}
