import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export default class ConfigAngularService {

    [x: string]: any;

    constructor() {
        Object.assign(this, environment);
    }
}
