import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmptyState } from 'src/app/enums/EmptyState';
import AuthAngularService from 'src/app/services/auth/auth-angular.service';
import DisplayMetricAngularService from 'src/app/services/display-metric/display-metric-angular.service';
import { WorkoutType, workoutTypeToName } from 'src/app/core/enums/WorkoutType';
import Session from 'src/app/core/models/Session';
import User from 'src/app/core/models/User';
import { AthleteChooserComponent } from '../../dialogs/athlete-chooser/athlete-chooser.component';
import Workout from 'src/app/core/models/Workout';

@Component({
    selector: 'app-triton-card-session-header',
    templateUrl: './triton-card-session-header.component.html',
    styleUrls: ['./triton-card-session-header.component.scss'],
})
export class TritonCardSessionHeaderComponent implements OnInit {

    @Input() title: string;
    @Input() subtitle: string;
    @Input() session: Session;

    @Output() iconClick = new EventEmitter();

    private user: User;

    public noData = EmptyState.NO_DATA;
    public sessionType: string;
    public distance: number;
    public displayDistance: string;
    public poolLength: number; 

    constructor(
        private dialog: MatDialog,
        private authAngularService: AuthAngularService,
        private displayMetricService: DisplayMetricAngularService
    ) {}

    ngOnInit(): void {
        this.user = this.authAngularService.getUser();
        this.sessionType = workoutTypeToName(this.session.type_id);
        this.distance = this.session.getAverageDistance();
        this.displayDistance = this.displayMetricService.getDisplayDistance(
            this.user,
            this.session.type_id,
            this.session.workouts[0],
            this.distance
        );

        if (this.session.type_id === WorkoutType.NORMAL && this.session.workouts.length) {
            this.poolLength = this.session.workouts[0].pool_length;
        }
    }

    public openAthleteChooser(): void {
        const users: Array<User> = [];
        const data: Array<any> = [];

        this.session.workouts.forEach((workout: Workout) => {
            if (workout.user) {
                users.push(workout.user);
            } else {
                const user = new User();
                user.id = workout.user_id;
                users.push(user);
            }

            data.push({
                workoutId: workout.id
            });
        });

        this.dialog.open(AthleteChooserComponent, {
                data: {
                    teamId: this.session.team_id,
                    title: this.title,
                    subtitle: 'Choose a participant to view details',
                    users,
                    data,
                }
            }
        );
    }
}
