import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AthleteTileModule } from 'src/app/components/athlete-tile/athlete-tile.module';

import { AthleteGridComponent } from './athlete-grid.component';

@NgModule({
    imports: [CommonModule, AthleteTileModule],
    declarations: [AthleteGridComponent],
    exports: [AthleteGridComponent],
})
export class AthleteGridModule {}
