<div class="triton-card-header justify-content-between d-flex align-items-center">
    <div class="content d-flex justify-content-center flex-column">
        <span class="title">{{title}}</span>
        <span class="subtitle">{{subtitle}}</span>
    </div>
    <div class="action d-flex justify-content-end">
        <a (click)="openAthleteChooser()" color="primary" mat-mini-fab class="d-flex align-items-center justify-content-center routeButton">
            <mat-icon>perm_identity</mat-icon>
        </a>
    </div>
</div>
