import { ConversionUnit } from "../../enums/ConversionUnit";
import { StrokeType } from "../../enums/StrokeType";
import { Table } from '../../enums/Table';
import { deepCopy } from "../../utils/deepCopy";
import CrudModel from "../generic/CrudModel";
import DateTimeConverter from "../../utils/converters/DateTimeConverter";
import InvalidDataError from "../../errors/InvalidDataError";
import StrokeMap from "../generic/StrokeMap";
import StrokeScore from "./StrokeScore";

export default class FocusScore extends CrudModel<FocusScore> {

    public id: number;
    public score: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public workout_at: Date;
    public stroke_scores: Array<StrokeScore>;

    constructor() {
        super();
        this.score = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.workout_at = null;
        this.stroke_scores = [];
    }

    public duplicate(): FocusScore {
        const properties = deepCopy(this);
        const focusScore = new FocusScore();
        focusScore.parseJson(properties);
        return focusScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No data for FocusScore parseJson');
        }

        this.id = json.id;
        this.score = json.score;

        if (json.created_at) {
            this.created_at = new Date(json.created_at);
        }

        if (json.updated_at) {
            this.updated_at = new Date(json.updated_at);
        }

        if (json.deleted_at) {
            this.deleted_at = new Date(json.deleted_at);
        }

        if (json.workout_at) {
            this.workout_at = new Date(json.workout_at);
        }

        if (json.stroke_scores) {
            const strokeScores: Array<StrokeScore> = [];

            json.stroke_scores.forEach((score: StrokeScore) => {
                const strokeScore = new StrokeScore();
                strokeScore.parseJson(score);
                strokeScores.push(strokeScore);
            });

            this.stroke_scores = strokeScores;
        }
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No data for FocusScore parsedata');
        }

        if (!(data instanceof Array)) {
            data = [data];
        }

        this.id = data[0].focus_score_id;
        this.score = data[0].focus_score_score;
        this.created_at = data[0].focus_score_created_at;

        if (data[0].focus_score_created_at) {
            if (data[0].focus_score_created_at instanceof Date) {
                this.created_at = data[0].focus_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data[0].focus_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].focus_score_updated_at) {
            if (data[0].focus_score_updated_at instanceof Date) {
                this.updated_at = data[0].focus_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data[0].focus_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].focus_score_deleted_at) {
            if (data[0].focus_score_deleted_at instanceof Date) {
                this.deleted_at = data[0].focus_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data[0].focus_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].focus_score_workout_at) {
            if (data[0].focus_score_workout_at instanceof Date) {
                this.workout_at = data[0].focus_score_workout_at;
            } else {
                const workoutAtDateTimeConverter = new DateTimeConverter(data[0].focus_score_workout_at, ConversionUnit.SQL_DATETIME);
                this.workout_at = workoutAtDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        let currentStrokeScoreId: number;
        let currentStrokeScoreData = [];

        for (let i = 0; i < data.length; i++) { // iterate and group metrics
            const curData = data[i];

            if (!currentStrokeScoreId) {
                currentStrokeScoreId = curData.stroke_score_id;
            }

            if (curData.focus_score_id !== this.id) {
                throw new InvalidDataError(`StrokeScore with focus_score_id ${curData.focus_score_id} does not belong to FocusScore with id ${this.id}`);
            }

            if (curData.stroke_score_id === currentStrokeScoreId) {
                currentStrokeScoreData.push(curData);
            } else {
                if (currentStrokeScoreId) {
                    const strokeScore = new StrokeScore();
                    strokeScore.parseData(currentStrokeScoreData);
                    this.stroke_scores.push(strokeScore);
                }

                currentStrokeScoreData = [];
                currentStrokeScoreData.push(curData);
                currentStrokeScoreId = curData.stroke_score_id;
            }
        }

        if (currentStrokeScoreId) {
            const newStrokeScore = new StrokeScore();
            newStrokeScore.parseData(currentStrokeScoreData);
            this.stroke_scores.push(newStrokeScore);
        }
    }

    public getFocusStrokeScores(): StrokeMap<StrokeScore> {
        const map = new StrokeMap<StrokeScore>();

        this.stroke_scores.forEach(score => {
            switch (score.stroke_type) {
                case StrokeType.FLY:
                    map.fly = score;
                    break;
                case StrokeType.BACK:
                    map.back = score;
                    break;
                case StrokeType.BREAST:
                    map.breast = score;
                    break;
                case StrokeType.FREE:
                    map.free = score;
                    break;
            }
        });

        return map;
    }

    public getHighestScoringStroke(): StrokeScore {
        if (!this.stroke_scores.length) {
            throw new InvalidDataError('Focus Score has no stroke_scores and cannot get the highest score.');
        }

        let highestStrokeScore: StrokeScore = this.stroke_scores[0];
        this.stroke_scores.forEach(score => {
            if (score.focus_stroke_score > highestStrokeScore.focus_stroke_score) {
                highestStrokeScore = score;
            }
        });

        return highestStrokeScore;
    }

    public getLowestScoringStroke(): StrokeScore {
        if (!this.stroke_scores.length) {
            throw new InvalidDataError('Focus Score has no stroke_scores and cannot get the lowest score.');
        }

        let lowestStrokeScore: StrokeScore = this.stroke_scores[0];
        this.stroke_scores.forEach(score => {
            if (score.focus_stroke_score < lowestStrokeScore.focus_stroke_score) {
                lowestStrokeScore = score;
            }
        });

        return lowestStrokeScore;
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.FOCUS_SCORES;
    }
}
