import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/app/material.module';
import { AthleteGridModule } from 'src/app/components/athlete-grid/athlete-grid.module';
import { AthleteChooserComponent } from './athlete-chooser.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        AthleteGridModule
    ],
    declarations: [AthleteChooserComponent],
    exports: [AthleteChooserComponent],
})
export class AthleteChooserModule { }
