import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'metric',
})
export class MetricPipe implements PipeTransform {
    transform(value: unknown, ...args: unknown[]): unknown {
        return null;
    }
}
