import InvalidDataError from "../errors/InvalidDataError";

export enum Table {
    EMAIL_NOTIFICATIONS = "email_notifications",
    FOCUS_METRIC_SCORES = "focus_metric_scores",
    FOCUS_PHASE_SCORES = "focus_phase_scores",
    FOCUS_SCORES = "focus_scores",
    INTENSITY_SCORES = "intensity_scores",
    INVITES = "invites",
    LOCKS = "locks",
    METRICS = "metrics",
    NODES = "nodes",
    PREFERENCES = "preferences",
    PUSH_NOTIFICATIONS = "push_notifications",
    PUSH_UUIDS = "push_uuids",
    READINESS_SCORES = "readiness_scores",
    REPS = "reps",
    SESSIONS = "sessions",
    SETS = "sets",
    STROKE_SCORES = "stroke_scores",
    SUBSCRIPTIONS = "subscriptions",
    SUBSCRIPTION_UNITS = "subscription_units",
    TEAM_TEAMS = "team_teams",
    TEAMS = "teams",
    TOKENS = "tokens",
    TRITON_SCORES = "triton_scores",
    USER_PROFILES = "user_profiles",
    USER_TEAMS = "user_teams",
    USERS = "users",
    WORKOUTS = "workouts",
}

// @TODO: This should be moved into CrudModel.ts
// queryHelper will need to be updated to accomidate 
// this change
export function tableToPrefix(table: string): string {
    // Map nodes to unit
    if (table === Table.NODES) {
        return 'unit_';
    }

    // Otherwise, remove the last character from the table name
    // and add an underscore
    return `${table.slice(0, -1)}_`;
}

export function tableFromProperty(property: string): Table {
    if (!property) {
        throw new InvalidDataError(`Table.tableFromProperty(): '${property}' is not a valid class property name.`);
    }

    let propertyName: string;

    // 'unit' is a special case where the data
    // is stored in 'nodes' instead of 'units'
    if (property === 'unit') {
        propertyName = 'node';
    } else {
        propertyName = property;
    }

    let table = propertyName;
    if (!property.endsWith('s')) {
        table = `${propertyName}s`;
    }

    const values = Object.values(Table);
    for (let i = 0; i < values.length; i++) {
        if (values[i] === table) {
            return values[i];
        }
    }

    throw new InvalidDataError(`Table.tableFromProperty(): '${property}' can not be mapped to a Table value.`);
}
