import { Chart } from 'angular-highcharts';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highcharts';

import { TritonChart } from 'src/app/enums/TritonChart';
import { MetricProperty } from 'src/app/core/enums/MetricProperty';
import IEventBus from 'src/app/core/interfaces/IEventBus';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
import { Event } from 'src/app/core/enums/Event';
import { CustomEvent } from 'src/app/enums/CustomEvent';
import IEventListener from 'src/app/core/interfaces/IEventListener';

const intensityColours = ['#bf2049', '#f83f4a'];
const focusColours = ['#71a4f1', '#4588ef'];

export interface TritonVerticalBarData {
    name: string;
    value: number;
    metric_property?: MetricProperty;
}

@Component({
    selector: 'app-triton-vertical-bar',
    templateUrl: './triton-vertical-bar.component.html',
    styleUrls: ['./triton-vertical-bar.component.scss'],
})
export class TritonVerticalBarComponent implements OnInit, OnChanges {
    @Input() data: Array<TritonVerticalBarData> = [];
    @Input() width: number;
    @Input() type: 'intensity' | 'focus';
    @Input() hideLabels = false;
    @Input() leftMargin: number;

    private showIcons = false;
    private eventBus: IEventBus;
    private tabClickHandler: IEventListener;

    public seriesColours: Array<string>;
    public seriesValues: Array<number>;
    public chart: Chart;

    constructor(
        eventBusAngularService: EventBusAngularService
    ) {
        this.eventBus = eventBusAngularService.service;
    }

    ngOnInit(): void {
        this.tabClickHandler = this.eventBus.addListener(Event.CUSTOM, this.onTabSelected);
        this.init();
    }

    ngOnChanges(): void {
        this.init();
    }

    ngOnDestroy(): void {
        this.tabClickHandler.remove();
    }

    onTabSelected = (data: any): void => {
        switch (data.type) {
            case CustomEvent.TAB_SELECTED:
                this.init();
        }
    }

    init(): void {
        if (this.type === TritonChart.INTENSITY) {
            this.seriesColours = intensityColours;
            this.showIcons = true;
        } else if (this.type === TritonChart.FOCUS) {
            this.seriesColours = focusColours;
        }

        this.seriesValues = this.data.map(e => e.value);

        if (!this.leftMargin) {
            this.leftMargin = 100;
        }

        this.renderChart();
    }

    renderChart() {
        const displayIcons = this.showIcons;

        this.chart = new Chart({
            chart: {
                type: 'bar',
                backgroundColor: null,
                height: 180,
            },
            title: { text: null },
            tooltip: { enabled: false },
            xAxis: {
                categories: this.data.map((e) => e.name),
                labels: {
                    formatter() {
                        if (displayIcons) {
                            return (
                                '<div><img src="../../../../assets/images/icon-square-' +
                                this.value.toString().toLowerCase() +
                                '.svg" alt=""></div>' +
                                '<div style="visibility:hidden;font-size:10px">  ABCDE</div>'
                            );
                        } else {
                            return `<div style="visibility:hidden;font-size:10px">  ABCDE</div>
                            <span style="color:#299de0;text-align:right;overflow:visible;">${this.value}</span>`;
                        }
                        // super glitched use the length of the ABCDEF string to change size of the icons
                    },
                    y: -5,
                    useHTML: true,
                    enabled: this.hideLabels ? false : true,
                },
                title: { text: null },
                visible: true,
            },
            yAxis: {
                min: 0,
                max: 101,
                labels: { enabled: false },
                title: { text: null },
                visible: false,
            },
            plotOptions: {
                bar: {
                    groupPadding: 0.9,
                    borderRadius: 6.5,
                    pointWidth: 12,
                },
            },
            legend: { enabled: false },
            credits: { enabled: false },
            series: [
                {
                    name: 'Intensity',
                    type: 'bar',
                    data: this.seriesValues,
                    pointPlacement: -0.2,
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                        x: 1000,
                    },
                    color: {
                        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                        stops: [
                            [0, this.seriesColours[0]],
                            [1, this.seriesColours[1]],
                        ],
                    },
                    events: {
                        click: (event) => {
                            this.eventBus.emit(Event.CUSTOM, {
                                type: CustomEvent.DISPLAY_METRIC_SELECTED,
                                property: this.data[event.point.index].metric_property
                            });
                        }
                    }
                },
            ],
        });
    }
}
