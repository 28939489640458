import InvalidDataError from '../errors/InvalidDataError';

export enum WorkoutType {
    NORMAL = 1,
    MOTION = 2,
    POOL_SWIM = 3,
    OPEN_WATER_SWIM = 4,
    DRYLAND_TRAINING = 5,
    WEIGHT_TRAINING = 6,
    RUNNING = 7,
    CYCLING = 8,
    OTHER = 9
}

export function workoutTypeToName(workoutType: WorkoutType): string {
    switch (workoutType) {
        case WorkoutType.NORMAL:
            return 'Swim';
        case WorkoutType.MOTION:
            return 'Motion';
        case WorkoutType.POOL_SWIM:
            return 'Pool Swim (Manual)';
        case WorkoutType.OPEN_WATER_SWIM:
            return 'Open Water Swim';
        case WorkoutType.DRYLAND_TRAINING:
            return 'Dryland Training';
        case WorkoutType.WEIGHT_TRAINING:
            return 'Weight Training';
        case WorkoutType.RUNNING:
            return 'Running';
        case WorkoutType.CYCLING:
            return 'Cycling';
        case WorkoutType.OTHER:
            return 'Other';
        default:
            throw new InvalidDataError(`WorkoutType workoutTypeToName(): unknown WorkoutType ${workoutType}`);
    }
}
