import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/pipes.module';
import { TritonCardModule } from 'src/app/components/triton-card/triton-card.module';
import { TritonChartModule } from 'src/app/components/triton-chart/triton-chart.module';
import { AthleteChooserModule } from './athlete-chooser/athlete-chooser.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PipesModule,
        TritonCardModule,
        TritonChartModule,
        AthleteChooserModule
    ],
    declarations: [],
    exports: [],
})
export class DialogModule { }
