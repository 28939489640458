import { ConversionUnit } from '../../enums/ConversionUnit';
import { MetricProperty } from '../../enums/MetricProperty';
import { StrokeType } from '../../enums/StrokeType';
import { SwimPhase } from "../../enums/SwimPhase";
import { Table } from '../../enums/Table';
import { deepCopy } from '../../utils/deepCopy';
import CrudModel from '../generic/CrudModel';
import DateTimeConverter from '../../utils/converters/DateTimeConverter';
import InvalidDataError from '../../errors/InvalidDataError';

export default class FocusMetricScore extends CrudModel<FocusMetricScore> {

    public id: number;
    public triton_score_id: number;
    public focus_phase_score_id: number;
    public metric_property: MetricProperty;
    public swim_phase: SwimPhase;
    public rolling_trend: number;
    public workout_avg: number;
    public myself_score: number;
    public likeyou_score: number;
    public stroke_type: StrokeType;
    public pool_length: number;
    public everyone_score: number;
    public likeyou_value: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public workout_at: Date;

    constructor() {
        super();
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }

    public duplicate(): FocusMetricScore {
        const properties = deepCopy(this);
        const focusScore = new FocusMetricScore();
        focusScore.parseJson(properties);
        return focusScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for FocusMetricScore parsedata');
        }
        this.id = data.focus_metric_score_id;
        this.triton_score_id = data.focus_metric_score_triton_score_id;
        this.focus_phase_score_id = data.focus_metric_score_focus_phase_score_id;
        this.metric_property = data.focus_metric_score_metric_property;
        this.swim_phase = data.focus_metric_score_swim_phase;
        this.rolling_trend = data.focus_metric_score_rolling_trend;
        this.workout_avg = data.focus_metric_score_workout_avg;
        this.myself_score = data.focus_metric_score_myself_score;
        this.likeyou_score = data.focus_metric_score_likeyou_score;
        this.stroke_type = data.focus_metric_score_stroke_type;
        this.pool_length = data.focus_metric_score_pool_length;
        this.everyone_score = data.focus_metric_score_everyone_score;
        this.likeyou_value = data.focus_metric_score_likeyou_value;

        if (data.focus_metric_score_created_at) {
            if (data.focus_metric_score_created_at instanceof Date) {
                this.created_at = data.focus_metric_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data.focus_metric_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.focus_metric_score_updated_at) {
            if (data.focus_metric_score_updated_at instanceof Date) {
                this.updated_at = data.focus_metric_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data.focus_metric_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.focus_metric_score_deleted_at) {
            if (data.focus_metric_score_deleted_at instanceof Date) {
                this.deleted_at = data.focus_metric_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data.focus_metric_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.focus_metric_score_workout_at) {
            if (data.focus_metric_score_workout_at instanceof Date) {
                this.workout_at = data.focus_metric_score_workout_at;
            } else {
                const workoutAtDateTimeConverter = new DateTimeConverter(data.focus_metric_score_workout_at, ConversionUnit.SQL_DATETIME);
                this.workout_at = workoutAtDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for FocusMetricScore parseJson()');
        }

        this.id = json.id;
        this.triton_score_id = json.triton_score_id;
        this.focus_phase_score_id = json.focus_phase_score_id;
        this.metric_property = json.metric_property;
        this.swim_phase = json.swim_phase;
        this.rolling_trend = json.rolling_trend;
        this.workout_avg = json.workout_avg;
        this.myself_score = json.myself_score;
        this.likeyou_score = json.likeyou_score;
        this.stroke_type = json.stroke_type;
        this.pool_length = json.pool_length;
        this.everyone_score = json.everyone_score;
        this.likeyou_value = json.likeyou_value;

        if (json.created_at) {
            this.created_at = new Date(json.created_at);
        }

        if (json.updated_at) {
            this.updated_at = new Date(json.updated_at);
        }

        if (json.deleted_at) {
            this.deleted_at = new Date(json.deleted_at);
        }

        if (json.workout_at) {
            this.workout_at = new Date(json.workout_at);
        }
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.FOCUS_METRIC_SCORES;
    }
}
