export enum MetricName {
    STROKES = "Strokes",
    AVG_CYCLE_HS = "Stroke Rate",
    BREATHS = "Breaths",
    DPS = "DPS",
    TURN_TIME = "Turn Time",
    TIME_UW = "Underwater Time",
    STROKE_INDEX = "Stroke Index",
    SWOLF = "SWOLF",
    PUSH_STRENGTH = "Push Strength",
    PUSHOFF_MAX_ACCX = "Peak Push",
    UW_MAXDEPTH_CM = "Maximum Depth Underwater",
    UW_SPD_CMPS = "Speed Underwater",
    PUSHOFF_DEPTH = "Pushoff Depth",
    TRANSITION_TIME = "Transition Time",
    PUSH_TIME = "Push Time",
    PERCENT_UNDERWATER = "Percent Underwater",
    SPEED_OW = "Speed Overwater",
    CYCLE_START_HS = "Stroke Rate Start",
    CYCLE_MID_HS = "Stroke Rate Mid",
    CYCLE_END_HS = "Stroke Rate End",
    DIVE_HANG_TIME = "Dive Hang Time",
    DIVE_BLOCK_TIME = "Dive Block Time",
    DIVE_TIME = "Dive Time"
}