import { Component, Input, OnInit } from '@angular/core';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
import ChartAngularService from 'src/app/services/chart/chart-angular.service';

// @ts-ignore
HighchartsMore(Highcharts);

@Component({
    selector: 'app-triton-rep-graph',
    templateUrl: './triton-rep-graph.component.html',
    styleUrls: ['./triton-rep-graph.component.scss'],
})
export class TritonRepGraphComponent implements OnInit {

    @Input() chartOptions: any;

    Highcharts: typeof Highcharts = Highcharts;

    constructor(private chartAngularService: ChartAngularService) {}

    ngOnInit() {
        this.createRepGraph();
    }

    createRepGraph() {
        // this.chartOptions = this.chartAngularService.getRepChart();
    }
}
