export enum UserRole {
    ADMIN_PARENT_TEAM = 'Admin (Parent Team)',
    ADMIN = 'Admin',
    COACH = 'Coach',
    SUPPORT = 'Support',
    MEMBER = 'Member'
}

export function userRoleToName(userRole: UserRole): string {
    switch (userRole) {
        case UserRole.ADMIN_PARENT_TEAM: 
            return 'Admin (Parent Team)';
        case UserRole.ADMIN:
            return 'Admin';
        case UserRole.COACH:
            return 'Coach';
        case UserRole.MEMBER: 
            return 'Athlete';
        case UserRole.SUPPORT:
            return 'Support';
    }
}