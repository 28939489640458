import { ConversionUnit } from '../enums/ConversionUnit';
import { SwimPhase } from '../enums/SwimPhase';
import InvalidDataError from '../errors/InvalidDataError';
import { deepCopy } from '../utils/deepCopy';

export default class DisplayMetric {

    public id: number;
    public property: string;
    public rep_property: string;
    public set_property: string;
    public title: string;
    public abbreviation: string;
    public tracking_unit?: ConversionUnit;
    public displaying_unit?: ConversionUnit;
    public advanced: boolean;
    public description: string;
    public default: boolean;
    public phase: SwimPhase;

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for DisplayMetric parseJson()');
        }

        this.id = json.id;
        this.property = json.property;
        this.rep_property = json.rep_property;
        this.set_property = json.set_property;
        this.title = json.title;
        this.abbreviation = json.abbreviation;
        this.tracking_unit = json.tracking_unit;
        this.displaying_unit = json.displaying_unit;
        this.advanced = json.advanced;
        this.description = json.description;
        this.default = json.default;
        this.phase = json.phase;
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for DisplayMetric parseData()');
        }

        this.id = data.display_metric_id;
        this.property = data.display_metric_property;
        this.rep_property = data.display_metric_rep_property;
        this.set_property = data.display_metric_set_property;
        this.title = data.display_metric_title;
        this.abbreviation = data.display_metric_abbreviation;
        this.tracking_unit = data.display_metric_tracking_unit;
        this.displaying_unit = data.display_metric_displaying_unit;
        this.advanced = data.display_metric_advanced;
        this.description = data.display_metric_description;
        this.default = data.display_metric_default;
        this.phase = data.display_metric_phase;
    }

    public duplicate(): DisplayMetric {
        const properties = deepCopy(this);
        const newCopy = new DisplayMetric();
        newCopy.parseJson(properties);
        return newCopy;
    }
}
