import { ConversionUnit } from '../../enums/ConversionUnit';
import { Table } from '../../enums/Table';
import { deepCopy } from '../../utils/deepCopy';
import CrudModel from '../generic/CrudModel';
import DateTimeConverter from '../../utils/converters/DateTimeConverter';
import FocusScore from './FocusScore';
import IntensityScore from "./IntensityScore";
import InvalidDataError from '../../errors/InvalidDataError';
import ReadinessScore from "./ReadinessScore";

export default class TritonScore extends CrudModel<TritonScore> {

    public id: number;
    public workout_id: number;
    public focus_score_id: number;
    public readiness_score_id: number;
    public intensity_score_id: number
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public focus?: FocusScore;
    public readiness?: ReadinessScore
    public intensity?: IntensityScore;

    constructor() {
        super();
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.readiness_score_id = null;
        this.focus_score_id = null;
        this.intensity_score_id = null;
        this.workout_id = null;
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.TRITON_SCORES;
    }

    public duplicate(): TritonScore {
        const properties = deepCopy(this);
        const tritonScore = new TritonScore();
        tritonScore.parseJson(properties);
        return tritonScore;
    }

    public object(): any {
        const properties = deepCopy(this);
        return properties;
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for TritonScore parseJson');
        }

        this.id = json.id;
        this.workout_id = json.workout_id;
        this.focus_score_id = json.focus_score_id;
        this.readiness_score_id = json.readiness_score_id;
        this.intensity_score_id = json.intensity_score_id;
        this.created_at = json.created_at;
        this.updated_at = json.updated_at;
        this.deleted_at = json.deleted_at;

        if (json.readiness) {
            const readiness = new ReadinessScore();
            readiness.parseJson(json.readiness);
            this.readiness = readiness;
        }

        if (json.intensity) {
            const intensity = new IntensityScore();
            intensity.parseJson(json.intensity);
            this.intensity = intensity;
        }

        if (json.focus) {
            const focus = new FocusScore();
            focus.parseJson(json.focus);
            this.focus = focus;
        }
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for TritonScore parseData');
        }

        this.id = data[0].triton_score_id;
        this.workout_id = data[0].triton_score_workout_id;
        this.focus_score_id = data[0].triton_score_focus_score_id;
        this.readiness_score_id = data[0].triton_score_readiness_score_id;
        this.intensity_score_id = data[0].triton_score_intensity_score_id;
        
        if (data[0].triton_score_created_at) {
            if (data[0].triton_score_created_at instanceof Date) {
                this.created_at = data[0].triton_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data[0].triton_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].triton_score_updated_at) {
            if (data[0].triton_score_updated_at instanceof Date) {
                this.updated_at = data[0].triton_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data[0].triton_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].triton_score_deleted_at) {
            if (data[0].triton_score_deleted_at instanceof Date) {
                this.deleted_at = data[0].triton_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data[0].triton_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        const readiness = new ReadinessScore();
        readiness.parseData(data[0]);
        this.readiness = readiness;

        if (data[0].intensity_score_id) {
            const intensity = new IntensityScore();
            intensity.parseData(data);
            this.intensity = intensity;
        }
        
        // Currently we don't parse an entire TritonScore from a single data set.
        // It's the job of the developer to separately parse the FocusScore and
        // set it to the property themselves.
        // Since the TritonScore's IntensityScore and FocusScore share the same StrokeScores
        // but the IntensityScore StrokeScores have no submodels,
        // we currently can't join the same table twice and distinguish
        // which StrokeScores each model should parse
    }

    public getTritonScore(): number {
        const scoreArray: Array<number> = [];
        let sumOfScores = 0;

        if (this.readiness) {
            sumOfScores += this.readiness.readiness_score;
            scoreArray.push(this.readiness.readiness_score);
        }

        if (this.focus) {
            sumOfScores += this.focus.score;
            scoreArray.push(this.focus.score);
        }

        if (this.intensity) {
            sumOfScores += this.intensity.score;
            scoreArray.push(this.intensity.score);
        }

        return Math.round(sumOfScores / scoreArray.length);
    }
}
