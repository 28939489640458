import { Pipe, PipeTransform } from '@angular/core';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import PoolLengthToIdxConverter from 'src/app/core/utils/converters/PoolLengthToIdxConverter';
import { Pool } from '../../core/enums/Pool';

@Pipe({
    name: 'pool',
})
export class PoolPipe implements PipeTransform {
    transform(poolLengthIdx: number): unknown {
        let pool = '';
        const unit = poolLengthIdx % 2 === 0 ? ConversionUnit.METERS : ConversionUnit.YARDS;
        
        if (poolLengthIdx === undefined) {
            return '-';
        }

        switch (poolLengthIdx) {
            case Pool.LCM:
                pool = 'LCM - 50m';
                break;
            case Pool.SCM:
                pool = 'SCM - 25m';
                break;
            case Pool.SCY:
                pool = 'SCY - 25y';
                break;
            default:
                const converter = new PoolLengthToIdxConverter(poolLengthIdx, ConversionUnit.POOL_LENGTH_IDX);
                const poolLength = converter.convert(unit);
                pool = `${poolLength}${unit}`;

                break;
        }

        return pool;
    }
}
