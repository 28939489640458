<div class="triton-card-header p-1 justify-content-between d-flex align-items-center">
    <div class="content d-flex justify-content-center flex-column col-6">
        <span class="title">{{title}}</span>
        <span class="subtitle">{{subtitle}}</span>
    </div>
    <table class="triton-activities--table col-5 p-0">
        <thead class="w-100">
            <th *ngIf="session.type_id === 1">Course</th>
            <th *ngIf="session.type_id !== 1">Type</th>
            <th>Distance</th>
            <th>Duration</th>
        </thead>
        <tbody class="w-100">
            <tr *ngIf="session" class="text-center">
                <td *ngIf="session.type_id === 1">{{poolLength | pool}}</td>
                <td *ngIf="session.type_id && session.type_id !== 1">{{sessionType || session.type_id}}</td>
                <td *ngIf="!session.type_id">{{noData}}</td>
                <td *ngIf="displayDistance">{{displayDistance}}</td>
                <td *ngIf="!displayDistance">{{noData}}</td>
                <td *ngIf="session.ended_at">{{ session.started_at | dateToTime: session.ended_at }}</td>
                <td *ngIf="!session.ended_at">{{noData}}</td>
            </tr>
        </tbody>
    </table>
    <div class="col-1 d-flex justify-content-end action">
        <a (click)="openAthleteChooser()" color="primary" mat-mini-fab class="d-flex align-items-center justify-content-center routeButton">
            <mat-icon>perm_identity</mat-icon>
        </a>
    </div>
</div>
