import { ConversionUnit } from '../../enums/ConversionUnit';
import InvalidDataError from '../../errors/InvalidDataError';
import BaseConverter from './BaseConverter';

export default class TimerConverter extends BaseConverter {

    private time: number;
    private timeObject: any;
    private fromUnit: ConversionUnit;

    private readonly MILLISECONDS_PER_HOUR: number = 3600000;
    private readonly MILLISECONDS_PER_MINUTE: number = 60000;
    private readonly MILLISECONDS_PER_SECOND: number = 1000;

    constructor(time: number, fromUnit: ConversionUnit) {
        super();

        if (TimerConverter.convertsFrom().indexOf(fromUnit) === -1) {
            throw new InvalidDataError(`TimerConverter constructor() Unit "${fromUnit}" cannot be handled. See "convertsFrom()"`);
        }

        const timeType = typeof time;

        if (timeType !== 'number') {
            throw new InvalidDataError(`TimerConverter constructor() Time "${time}" must be a type of number and not a type of "${timeType}". Unit: "${fromUnit}"`);
        }

        if (isNaN(time)) {
            throw new InvalidDataError(`TimerConverter constructor() Time "${time}" must not be NaN. Unit "${fromUnit}"`);
        }

        if (time < 0) {
            throw new InvalidDataError(`TimerConverter constructor() Time "${time}" must be greater than or equal to zero.`);
        }

        this.time = time;
        this.fromUnit = fromUnit;
    }

    public static convertsFrom(): Array<ConversionUnit> {
        return [
            ConversionUnit.MILLISECONDS,
            ConversionUnit.SECONDS,
            ConversionUnit.MINUTES,
            ConversionUnit.HOURS,
        ];
    }

    public static convertsTo(): Array<ConversionUnit> {
        return [
            ConversionUnit.MILLISECONDS,
            ConversionUnit.SECONDS,
            ConversionUnit.MINUTES,
            ConversionUnit.HOURS,
            ConversionUnit.DURATION,
            ConversionUnit.TIMER_HHMMSS,
            ConversionUnit.TIMER_HHMMSS_MS,
            ConversionUnit.TIMER_AUTO,
            ConversionUnit.TIMER_AUTO_MS,
            ConversionUnit.TIMER_AUTO_PAD,
            ConversionUnit.TIMER_AUTO_PAD_MS,
            ConversionUnit.TIMER_AUTO_ADVANCE,
            ConversionUnit.TIMER_AUTO_ADVANCE_MS,
            ConversionUnit.TIMER_AUTO_ADVANCE_PAD,
            ConversionUnit.TIMER_AUTO_ADVANCE_PAD_MS,
            ConversionUnit.TIMER_AUTO_MS_S
        ];
    }

    public convert(toUnit: ConversionUnit): string {
        if (this.fromUnit === ConversionUnit.SECONDS) {
            this.time = this.time * this.MILLISECONDS_PER_SECOND;
        }

        if (this.fromUnit === ConversionUnit.MINUTES) {
            this.time = this.time * this.MILLISECONDS_PER_MINUTE;
        }

        if (this.fromUnit === ConversionUnit.HOURS) {
            this.time = this.time * this.MILLISECONDS_PER_HOUR;
        }

        this.timeObject = this.convertToTimeObject(this.time);

        switch (toUnit) {
            case ConversionUnit.MILLISECONDS:
                return `${this.time}`;
            case ConversionUnit.SECONDS:
                return this.convertToSeconds();
            case ConversionUnit.MINUTES:
                return this.convertToMinutes();
            case ConversionUnit.HOURS:
                return this.convertToHours();
            case ConversionUnit.DURATION:
                return this.convertToDuration();
            case ConversionUnit.TIMER_HHMMSS:
                return this.convertToTimerHHMMSS();
            case ConversionUnit.TIMER_HHMMSS_MS:
                return this.convertToTimerHHMMSSMS();
            case ConversionUnit.TIMER_AUTO:
                return this.convertToTimerAuto();
            case ConversionUnit.TIMER_AUTO_MS:
                return this.convertToTimerAutoMS();
            case ConversionUnit.TIMER_AUTO_PAD:
                return this.convertToTimerAutoPad();
            case ConversionUnit.TIMER_AUTO_PAD_MS:
                return this.convertToTimerAutoPadMS();
            case ConversionUnit.TIMER_AUTO_ADVANCE:
                return this.convertToTimerAutoAdv();
            case ConversionUnit.TIMER_AUTO_ADVANCE_MS:
                return this.convertToTimerAutoAdvMS();
            case ConversionUnit.TIMER_AUTO_ADVANCE_PAD:
                return this.convertToTimerAutoAdvPad();
            case ConversionUnit.TIMER_AUTO_ADVANCE_PAD_MS:
                return this.convertToTimerAutoAdvPadMS();
            case ConversionUnit.TIMER_AUTO_MS_S:
                return this.convertToTimerAutoMsS();
            default:
                throw new InvalidDataError(`TimerConverter convert() Unit "${toUnit}" is not a valid unit. See "convertsTo()"`);
        }
    }

    private getHours(time: number): number {
        return Math.floor(time / this.MILLISECONDS_PER_HOUR);
    }

    private getMinutes(time: number): number {
        return Math.floor(time / this.MILLISECONDS_PER_MINUTE);
    }

    private getSeconds(time: number): number {
        return Math.floor(time / this.MILLISECONDS_PER_SECOND);
    }

    private convertToHours(): string {
        return (Math.round(((this.time / this.MILLISECONDS_PER_HOUR) + Number.EPSILON) * 100) / 100).toString();
    }

    private convertToMinutes(): string {
        return (Math.round(((this.time / this.MILLISECONDS_PER_MINUTE) + Number.EPSILON) * 100) / 100).toString();
    }

    private convertToSeconds(): string {
        return (Math.round(((this.time / this.MILLISECONDS_PER_SECOND) + Number.EPSILON) * 100) / 100).toString();
    }

    private convertToDuration(): string {
        const hours = this.getHours(this.time);
        this.time -= (hours * this.MILLISECONDS_PER_HOUR);

        const minutes = this.getMinutes(this.time);
        this.time -= (minutes * this.MILLISECONDS_PER_MINUTE);

        return `${hours}h ${minutes}m`;
    }

    private convertToTimerHHMMSS(): string {
        return `${this.timeObject.padHours}:${this.timeObject.padMinutes}:${this.timeObject.padSeconds}`;
    }

    private convertToTimerHHMMSSMS(): string {
        const toTimerHHMMSS = this.convertToTimerHHMMSS();

        return this.appendMs(toTimerHHMMSS);
    }

    private convertToTimerAuto(): string {
        if (this.timeObject.hours > 0) {
            return `${this.timeObject.hours}:${this.timeObject.padMinutes}:${this.timeObject.padSeconds}`;
        }

        if (this.timeObject.minutes > 0) {
            return `${this.timeObject.minutes}:${this.timeObject.padSeconds}`;
        }

        return `${this.timeObject.seconds}`;
    }

    private convertToTimerAutoMS(): string {
        const toTimeAuto = this.convertToTimerAuto();

        return this.appendMs(toTimeAuto);
    }

    private convertToTimerAutoPad(): string {
        if (this.timeObject.hours > 0) {
            return this.convertToTimerHHMMSS();
        }

        if (this.timeObject.minutes > 0) {
            return `${this.timeObject.padMinutes}:${this.timeObject.padSeconds}`;
        }

        return `${this.timeObject.padSeconds}`;
    }

    private convertToTimerAutoPadMS(): string {
        const toTimeAutoPad = this.convertToTimerAutoPad();

        return this.appendMs(toTimeAutoPad);
    }

    private convertToTimerAutoAdv(): string {
        if (this.timeObject.hours > 0 || this.timeObject.minutes > 0) {
            return `${this.timeObject.hours}:${this.timeObject.padMinutes}:${this.timeObject.padSeconds}`;
        }

        return `${this.timeObject.minutes}:${this.timeObject.padSeconds}`;
    }

    private convertToTimerAutoAdvMS(): string {
        const toTimerAutoAdv = this.convertToTimerAutoAdv();

        return this.appendMs(toTimerAutoAdv);
    }

    private convertToTimerAutoAdvPad(): string {
        if (this.timeObject.hours > 0 || this.timeObject.minutes > 0) {
            return this.convertToTimerHHMMSS();
        }

        return `${this.timeObject.padMinutes}:${this.timeObject.padSeconds}`;
    }

    private convertToTimerAutoAdvPadMS(): string {
        const toTimerAutoAdvPad = this.convertToTimerAutoAdvPad();

        return this.appendMs(toTimerAutoAdvPad);
    }

    private convertToTimerAutoMsS(): string {
        if (this.timeObject.minutes > 0) {
            return `${this.timeObject.minutes}:${this.timeObject.padSeconds}.${this.timeObject.padMs}`;
        }

        return `${this.timeObject.seconds}.${this.timeObject.padMs}s`;
    }


    private convertToTimeObject(time: number): any {
        const hours = this.getHours(time);
        time -= (hours * this.MILLISECONDS_PER_HOUR);

        const minutes = this.getMinutes(time);
        time -= (minutes * this.MILLISECONDS_PER_MINUTE);

        const seconds = this.getSeconds(time);
        time -= (seconds * this.MILLISECONDS_PER_SECOND);

        const ms = time / 10;

        return {
            hours,
            padHours: this.padZero(hours),
            minutes,
            padMinutes: this.padZero(minutes),
            seconds,
            padSeconds: this.padZero(seconds),
            ms,
            padMs: this.padZero(ms.toFixed(0))
        };
    }

    private padZero(value: string | number) {
        const newValue = value.toString();

        if (value < 10) {
            return '0' + newValue;
        }

        return newValue;
    }

    private appendMs(value: string): string {
        return `${value}.${this.timeObject.padMs}`;
    }
}
