<div class="athlete-chooser">
    <div mat-dialog-title class="header">
        <div class="container-fluid mb-2">
            <div class="row">
                <div class="col-12 d-flex align-items-center">
                    <div class="d-flex flex-column align-baseline justify-content-end content ml-2">
                        <div class="title">
                            <h3 class="mb-0">{{title}}</h3>
                        </div>
                        <div class="subtitle">
                            <h3 class="mb-0">{{subtitle}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
    <div mat-dialog-content>
        <app-athlete-grid [users]="users" [teamId]="teamId" [showTritonScore]="false"></app-athlete-grid>
    </div>
    <div mat-dialog-actions>
        <button class="cancel-button" mat-button [mat-dialog-close]="true">Close</button>
    </div>
</div>