import * as Highcharts from 'highcharts';
import { identity } from 'rxjs';
import { Injectable } from '@angular/core';
import ConfigAngularService from 'src/app/services/config/config-angular.service';
import { MsToTimePipe } from 'src/app/pipes/ms-to-time/ms-to-time.pipe';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import { MetricProperty } from 'src/app/core/enums/MetricProperty';
import TimerConverter from 'src/app/core/utils/converters/TimerConverter';

const MAX_POINTS = 20;
const MAX_POINTS_FOR_DATA_LABEL_UNITS = 13;

class LapMetric {
    title: any;
    prop: any;
    constructor(title, prop, options) {
        this.title = title;
        this.prop = prop;

        const attrs = {
            units: '',
            convertFormat: identity,
            convertUnits: identity,
            editable: false,
            averageOnReduce: true, // should the metrics be summed or averaged?
            isGroupable: true,
            postCollect: identity, // process to run after collecting values from metrics; accepts array of values
        };

        Object.assign(attrs, options || {});
        Object.assign(this, attrs);
    }

    convert(value) {
        // @ts-ignore
        return this.convertFormat(this.convertUnits(value));
    }
}

@Injectable({
    providedIn: 'root',
})
export default class ChartAngularService {

    public setChart: any;
    public repChart: any;
    public colors: any;
    public symbols: any;

    poolConfig: any;

    lapMetrics = [
        new LapMetric('Time', MetricProperty.SPLIT_TIME, {
            convertFormat: new MsToTimePipe().transform,
            editable: {
                splitInput: true,
            },
            averageOnReduce: false,
            color: '#3769b1',
        }),
        new LapMetric('Stroke Count', MetricProperty.STROKES, {
            editable: {
                maxInputLength: 3,
            },
            averageOnReduce: false,
            color: '#cc2428',
        }),
        new LapMetric('Breath Count', MetricProperty.BREATHS, {
            editable: {
                maxInputLength: 3,
            },
            averageOnReduce: false,
            color: '#eda4a9',
        }),
        new LapMetric('Speed', MetricProperty.AVERAGE_SPD_CMPS, {
            units: 'cm/s',
            color: '#da7e2f',
        }),
        new LapMetric('Stroke Rate', MetricProperty.AVG_CYCLE_HS, {
            units: 'hs/str',
            color: '#6d9575',
        }),
        new LapMetric('DPS', MetricProperty.DPS, {
            convertFormat: this.cmToM,
            units: 'm',
            color: '#e6cf06',
        }),
        new LapMetric('Turn Time', MetricProperty.TURN_TIME, {
            convertFormat: new MsToTimePipe().transform,
            isGroupable: false,
            // there is no turn on the last lap, so remove last turn time
            postCollect: (values) => {
                values.pop();
                return values;
            },
            color: '#525055',
        }),
        new LapMetric('Time UW', MetricProperty.TIME_UW, {
            convertFormat: new MsToTimePipe().transform,
            editable: {
                splitInput: true,
            },
            color: '#6b4c9a',
        }),
        new LapMetric('Push-Off', MetricProperty.PUSH_STRENGTH, {
            color: '#048847',
        }),
        new LapMetric('Stroke Index', MetricProperty.STROKE_INDEX, {
            convertFormat: this.strokeIndexFix,
            color: '#32a4ef',
        }),
        new LapMetric('SWOLF', MetricProperty.SWOLF, {
            color: '#922426',
        }),
    ];

    strokeIndexFix(strokeIndex) {
        if (strokeIndex < 0.1) {
            return strokeIndex * 100;
        }

        return strokeIndex;
    }

    cmToM(distance) {
        return (distance / 100).toFixed(2);
    }

    constructor(private config: ConfigAngularService) {
        this.poolConfig = this.config.poolConfig;
        this.setChart = this.initChart();
        this.repChart = this.initChart();

        this.colors = [
            ['#33a2ec', 'rgba(50, 162, 236, 0.4)', 'rgba(50, 162, 236, 0.5)'], // 0 Blue
            ['#ffce02', 'rgba(255, 206, 1, 0.4)', 'rgba(255, 206, 1, 0.5)'], // 1 Yellow
            ['#37e280', 'rgba(55, 226, 128, 0.4)', 'rgba(55, 226, 128, 0.5)'], // 2 Green
            ['#ef717a', 'rgba(239, 113, 121, 0.4)', 'rgba(239, 113, 121, 0.5)'], // 3 Red
            ['#136FAC', 'rgba(19, 111, 172, 0.4)', 'rgba(19, 111, 172, 0.5)'], // 4 Dark Blue
            ['#C17427', 'rgba(193, 116, 39, 0.4)', 'rgba(193, 116, 39, 0.5)'], // 5 Orange
            ['#008846', 'rgba(0, 136, 70, 0.4)', 'rgba(0, 136, 70, 0.5)'], // 6 Dark Green
            ['#9B333C', 'rgba(155, 51, 60, 0.4)', 'rgba(155, 51, 60, 0.5)'], // 7 Maroon
            ['#9B333C', 'rgba(155, 51, 60, 0.4)', 'rgba(155, 51, 60, 0.5)'],
            ['#008846', 'rgba(0, 136, 70, 0.4)', 'rgba(0, 136, 70, 0.5)'],
            ['#C17427', 'rgba(193, 116, 39, 0.4)', 'rgba(193, 116, 39, 0.5)'],
            ['#136FAC', 'rgba(19, 111, 172, 0.4)', 'rgba(19, 111, 172, 0.5)'],
            ['#ef717a', 'rgba(239, 113, 121, 0.4)', 'rgba(239, 113, 121, 0.5)'],
            ['#37e280', 'rgba(55, 226, 128, 0.4)', 'rgba(55, 226, 128, 0.5)'],
            ['#ffce02', 'rgba(255, 206, 1, 0.4)', 'rgba(255, 206, 1, 0.5)'],
            ['#33a2ec', 'rgba(50, 162, 236, 0.4)', 'rgba(50, 162, 236, 0.5)'],
        ];
        this.symbols = ['circle', 'triangle', 'square', 'diamond'];
        this.symbols = this.symbols.concat(this.symbols).concat(this.symbols);
        // Drop-in fix for Highcharts issue #8477 on older Highcharts versions. The
        // issue is fixed since Highcharts v6.1.1.
        Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function(proceed) {
            // eslint-disable-line no-undef
            const path = proceed.apply(
                this,
                Array.prototype.slice.call(arguments, 1)
            );
            if (path) {
                path.flat = false;
            }
            return path;
        });
    }

    getSeriesWithMaxValues(series) {
        let maxSeries = series[0] || {};
        series.forEach((_series) => {
            if (
                _series.data &&
                maxSeries.data &&
                _series.data.length > maxSeries.data.length
            ) {
                maxSeries = _series;
            }
        });
        return maxSeries;
    }

    getSeriesWithMinValues(series) {
        let minSeries = series[0] || {};
        series.forEach((_series) => {
            if (
                _series.data &&
                minSeries.data &&
                _series.data.length < minSeries.data.length
            ) {
                minSeries = _series;
            }
        });
        return minSeries;
    }

    getDataLabelOptions(metrics, enabled, pointCount) {
        return {
            enabled,
            style: {
                color: '#56697d',
                fontFamily: 'Open Sans, Roboto-Regular',
                fontSize: '10px',
                fontWeight: 600,
                textShadow: 'none',
            },
            allowOverlap: true,
            y: -4,
            verticalAlign: 'bottom',
            useHTML: true,
            crop: false,
            overflow: 'none',
            formatter() {
                if (!this.series.userOptions) {
                    return;
                }
                const showUnits =
                    (pointCount || 0) +
                        metrics[this.series.userOptions.metricIndex].units
                            .length <=
                    MAX_POINTS_FOR_DATA_LABEL_UNITS;
                return (
                    metrics[this.series.userOptions.metricIndex].convertFormat(
                        this.y
                    ) +
                    (showUnits
                        ? ` ${
                              metrics[this.series.userOptions.metricIndex].units
                          }`
                        : '')
                );
            },
        };
    }
    getSetChart(
        sets,
        height,
        metrics,
        seriesEvents,
        chartEvents,
        showDataLabels,
        isApp = false
    ) {
        const series =
            this.getSetChartSeries(sets, metrics, seriesEvents, isApp) || [];
        const self = this;
        const numSeries = series.filter((_series) => _series.data.length)
            .length;
        // const minSeries = getSeriesWithMinValues(series);
        this.setChart.maxSeries = this.getSeriesWithMaxValues(series);
        showDataLabels =
            showDataLabels &&
            numSeries <= 2 &&
            (this.setChart.maxSeries.data || []).length <=
                MAX_POINTS_FOR_DATA_LABEL_UNITS;
        this.setChart.options.chart.height = height;
        this.setChart.options.chart.events = chartEvents;
        if (numSeries === 1 && series[0] && metrics.length) {
            this.setChart.options.yAxis[0].plotLines = [
                {
                    color: '#9AA5B1',
                    dashStyle: 'solid',
                    value:
                        (series[0].data || []).length >= 2
                            ? series[0].data.reduce((a, b) => {
                                  return a + b;
                              }, 0) / series[0].data.length
                            : '',
                    width: 2,
                    label: {
                        useHTML: true,
                        align: 'right',
                        y: -11,
                        x: -5,
                        text:
                            (series[0].data || []).length >= 2
                                ? `Avg ${self.getAvgWithUnits(
                                      series[0].data,
                                      metrics[0]
                                  )}`
                                : '',
                        style: {
                            color: '#fff',
                            fontFamily: 'Open Sans',
                            fontSize: '11px',
                            textShadow: 'none',
                            backgroundColor: 'rgba(154, 165, 177, 0.7)',
                            padding: '4px 6px',
                            borderRadius: '6px 0 0 0',
                            zIndex: 0,
                        },
                    },
                },
            ];
        } else {
            this.setChart.options.yAxis[0].plotLines = undefined;
        }
        const index = sets[0] ? 0 : 1;
        if (
            sets &&
            sets.length &&
            sets[index].reps &&
            sets[index].reps.length > 9
        ) {
            // when reps go to double digits add some padding
            this.setChart.options.chart.style.paddingBottom = '12px';
        } else if (
            sets &&
            sets.length &&
            sets[index].reps &&
            sets[index].reps.length > 16
        ) {
            // sometimes when reps are (even) and in double digits the rep overlaps, fixed it by increasing the height
            this.setChart.options.chart.style.height = '200px';
            this.setChart.options.chart.style.paddingBottom = '15px';
        } else if (
            sets &&
            sets.length &&
            sets[index].reps &&
            sets[index].reps.length > 25
        ) {
            this.setChart.options.chart.style.height = '210px';
            this.setChart.options.chart.style.paddingBottom = '15px';
        }
        this.setChart.options.plotOptions = {
            line: {
                dataLabels: this.getDataLabelOptions(
                    metrics,
                    showDataLabels,
                    (self.setChart.maxSeries.data || []).length
                ),
            },
        };
        this.setChart.options.xAxis.labels.step =
            Math.floor(
                (self.setChart.maxSeries.data || []).length / MAX_POINTS
            ) + 1;

        this.setChart.options.xAxis.labels.formatter = function() {
            if (
                this.value < 0 ||
                ((self.setChart.maxSeries.data || []).length &&
                    this.value >= (self.setChart.maxSeries.data || []).length)
            ) {
                return '';
            }
            return `REP ${this.value + 1}`;
        };
        this.setChart.options.tooltip.enabled = !showDataLabels;
        this.setChart.options.tooltip.formatter = function() {
            if (!this.series.userOptions) {
                return;
            }
            return `REP ${this.x + 1}: <b>${metrics[
                this.series.userOptions.metricIndex
            ].convertFormat(this.y)} ${
                metrics[this.series.userOptions.metricIndex].units
            }</b><br/>`;
        };
        metrics.forEach((metric, index) => {
            if (this.setChart.options.yAxis[index]) {
                this.setChart.options.yAxis[
                    index
                ].labels.formatter = function() {
                    return `<span style="display: block; width: 30px; text-align: ${
                        index > 0 ? 'left' : 'right'
                    }">${metric.convertFormat(this.value)}</span>`;
                };
            }
        });
        this.setChart.options.flag = Math.random();
        this.setChart.series = series;
        // this.setChart.getHighcharts().redraw();
    }

    initChart(isApp = false): any {
        const self = this;
        // watch will not detect when formatters change since function properties are ignored by angular.equals
        // hacky fix is to add flag: Math.random() as property of chart.options
        // therefore, the highcharts watch is triggered everytime
        // http://jsfiddle.net/3ku41tb2/
        return {
            options: {
                // flag: Math.random(),
                chart: {
                    height: 200,
                    backgroundColor: null,
                    type: 'line',
                    style: {
                        cursor: 'pointer',
                        fontFamily: 'Open Sans, Roboto-Regular',
                        fontSize: '12px',
                        fontWeight: 600,
                    },
                    spacingBottom: 5,
                },
                legend: {
                    itemStyle: {
                        fontFamily: 'Open Sans, Roboto-Regular',
                        fontSize: '12px',
                        fontWeight: 600,
                        color: '#56697d',
                    },
                },
                credits: {
                    enabled: false,
                },
                yAxis: [
                    {
                        title: {
                            text: '',
                        },
                        gridLineWidth: 1,
                        lineWidth: 0,
                        labels: {
                            style: {
                                color: '#56697d',
                            },
                            useHTML: true,
                        },
                        showFirstLabel: false,
                        tickPosition: 'outside',
                        minPadding: 0.05,
                        maxPadding: 0.1,
                    },
                    {
                        title: {
                            text: '',
                        },
                        opposite: true,
                        gridLineWidth: 1,
                        lineWidth: 0,
                        labels: {
                            style: {
                                color: '#56697d',
                            },
                            useHTML: true,
                        },
                        showFirstLabel: false,
                        tickPosition: 'outside',
                        minPadding: 0.05,
                        maxPadding: 0.1,
                    },
                ],
                tooltip: {
                    crosshairs: !isApp,
                    style: {
                        backgroundColor: 'rgba(74, 74, 74, 0.9)',
                        zIndex: 20,
                    },
                },
                xAxis: {
                    title: {
                        text: '',
                    },
                    tickWidth: 0,
                    tickInterval: 1,
                    startOnTick: false,
                    endOnTick: false,
                    labels: {
                        style: {
                            color: '#56697d',
                        },
                        useHTML: true,
                    },
                    // minPadding: 0.01,
                    maxPadding: 0.05,
                },
            },
            series: [
                self.getSetChartSeries([{}], [], null),
                self.getSetChartSeries([{}], [], null),
            ],
            title: {
                text: '',
            },
            func(chart) {
                if (chart && chart.reflow) {
                    setTimeout(() => {
                        if (chart && chart.reflow) {
                            // may have stopped existing between above check and timeout
                            chart.reflow();
                        }
                    }, 0);
                }
            },
        };
    }

    normalize(value, decimalPlaces?) {
        decimalPlaces = !decimalPlaces ? 2 : decimalPlaces;
        return !value || !isFinite(value) || value < 0
            ? null
            : parseFloat(value.toFixed(decimalPlaces));
    }

    getSetChartSeries(sets, setMetrics, seriesEvents, isApp?) {
        const filteredSets = sets.filter((set) => set && set.id);
        let values = [];
        let metrics;
        let series = [];
        setMetrics.forEach((setMetric, metricIndex) => {
            series = series.concat(
                filteredSets.map((set, index) => {
                    values = [];
                    if (set && Array.isArray(set.reps)) {
                        const reps = set.reps;
                        values = reps.map((rep) => {
                            let value = 0;
                            metrics = setMetric.postCollect(
                                rep.metrics.slice()
                            );
                            metrics.forEach((metric) => {
                                // Show converted stroke rate average for stroke rate metric
                                value +=
                                    setMetric.prop === MetricProperty.AVG_CYCLE_HS &&
                                    metric.stroke_rate_converted
                                        ? metric.stroke_rate_converted
                                        : setMetric.convertUnits(
                                              metric[setMetric.prop],
                                              metric.stroke_type
                                          ) || 0;
                            });
                            if (setMetric.averageOnReduce) {
                                value /= metrics.length;
                            }
                            return this.normalize(value);
                        });
                    }
                    return {
                        data: values,
                        cursor: 'pointer',
                        lineWidth: 3,
                        allowPointSelect: isApp,
                        point: {
                            events: isApp ? seriesEvents : {},
                        },
                        states: {
                            hover: {
                                enabled: !isApp,
                                halo: false,
                            },
                        },
                        marker: {
                            enabled: values.length <= MAX_POINTS,
                            radius: 6,
                            fillColor: this.colors[index][0],
                            symbol: this.symbols[index],
                            states: {
                                hover: {
                                    enabled: !isApp,
                                    lineColor: this.colors[index][1],
                                    lineWidth: 9,
                                },
                                select: {
                                    color: this.colors[index][0],
                                    lineColor: this.colors[index][1],
                                    lineWidth: 9,
                                },
                            },
                        },
                        events: isApp ? {} : seriesEvents || {},
                        showInLegend: false,
                        color: this.colors[index][0],
                        dashStyle: metricIndex > 0 ? 'Dash' : 'Solid',
                        yAxis: metricIndex,
                        metricIndex,
                        turboThreshold: 0,
                        id: `set${index}-metric${metricIndex}`,
                    };
                })
            );
        });

        return series;
    }
    convertPoolConfig(poolId) {
        let config = this.poolConfig[0];
        if (poolId > 2) {
            config = {
                id: poolId,
                // tslint:disable-next-line:no-bitwise
                _length: poolId & 0x1 ? (poolId - 1) / 20 : poolId / 20,
                // tslint:disable-next-line:no-bitwise
                unit: poolId & 0x1 ? 'yd' : 'm',
            };
            config.lengthInMetres =
                config.unit === 'yd'
                    ? (Math.round(config._length * 0.9144), 2)
                    : config._length;
            config.title = 'Course (' + config._length + config.unit + ')';
        } else {
            for (let i = 0; i < this.poolConfig.length; i++) {
                if (this.poolConfig[i].id === poolId) {
                    config = this.poolConfig[i];
                    break;
                }
            }
        }
        return config;
    }
    distance(multiplier: any, distance: any) {
        const pool = this.convertPoolConfig(distance);
        return Math.round((multiplier || 1) * pool._length) + pool.unit;
    }

    getRepChart(
        reps,
        metrics,
        height,
        seriesEvents,
        chartEvents,
        showDataLabels,
        groupToDepth,
        isApp = false
    ) {
        const series =
            this.getRepChartSeries(
                reps,
                metrics,
                groupToDepth,
                seriesEvents,
                isApp
            ) || [];
        const numSeries = series.filter((_series) => _series.data.length)
            .length;
        // const minSeries = getSeriesWithMinValues(series);
        this.repChart.maxSeries = this.getSeriesWithMaxValues(series);
        const firstRep = reps.filter((rep) => rep && rep.id)[0];
        const distance = firstRep.metrics ? firstRep.metrics[0].distance : 0;
        showDataLabels =
            showDataLabels &&
            numSeries <= 2 &&
            (this.repChart.maxSeries.data || []).length <=
                MAX_POINTS_FOR_DATA_LABEL_UNITS;
        const self = this;

        this.repChart.options.chart.height = height;
        this.repChart.options.chart.events = chartEvents;
        metrics.forEach((metric, index) => {
            if (this.repChart.options.yAxis[index]) {
                this.repChart.options.yAxis[
                    index
                ].labels.formatter = function() {
                    return `<span style="display: block; width: 30px; text-align: ${
                        index > 0 ? 'left' : 'right'
                    }">${metric.convertFormat(this.value)}</span>`;
                };
            }
        });

        if (numSeries === 1 && series[0] && metrics.length) {
            this.repChart.options.yAxis[0].plotLines = [
                {
                    color: '#9AA5B1',
                    dashStyle: 'solid',
                    value:
                        (series[0].data || []).length >= 2
                            ? series[0].data.reduce((a, b) => {
                                  return a + b;
                              }, 0) / series[0].data.length
                            : '',
                    width: 2,
                    label: {
                        useHTML: true,
                        align: 'right',
                        y: -11,
                        x: -5,
                        text:
                            (series[0].data || []).length >= 2
                                ? `Avg ${self.getAvgWithUnits(
                                      series[0].data,
                                      metrics[0]
                                  )}`
                                : '',
                        style: {
                            color: '#fff',
                            fontFamily: 'Open Sans',
                            fontSize: '11px',
                            textShadow: 'none',
                            backgroundColor: 'rgba(154, 165, 177, 0.7)',
                            padding: '4px 6px',
                            borderRadius: '6px 0 0 0',
                            turboThreshold: 0,
                            zIndex: 0,
                        },
                    },
                },
            ];
        } else {
            this.repChart.options.yAxis[0].plotLines = undefined;
        }
        this.repChart.options.plotOptions = {
            line: {
                dataLabels: this.getDataLabelOptions(
                    metrics,
                    showDataLabels,
                    (self.repChart.maxSeries.data || []).length
                ),
            },
        };
        this.repChart.options.xAxis.labels.step =
            Math.floor(
                (self.repChart.maxSeries.data || []).length / MAX_POINTS
            ) + 1;

        this.repChart.options.xAxis.labels.formatter = function() {
            if (
                this.value < 0 ||
                ((self.repChart.maxSeries.data || []).length &&
                    this.value >= (self.repChart.maxSeries.data || []).length)
            ) {
                return '';
            }
            return self.distance(
                (this.value + 1) * Math.pow(2, groupToDepth - 1),
                distance
            );
        };
        this.repChart.options.tooltip.enabled = !showDataLabels;
        this.repChart.options.tooltip.formatter = function() {
            if (!this.series.userOptions) {
                return;
            }
            return `Lap ${this.x + 1}: <b>${metrics[
                this.series.userOptions.metricIndex
            ].convertFormat(this.y)} ${
                metrics[this.series.userOptions.metricIndex].units
            }</b><br/>`;
        };
        this.repChart.options.flag = Math.random();
        this.repChart.series = series;
    }

    ungroupMetrics(rep) {
        if (rep._metricSource) {
            rep.metrics = rep._metricSource;
        }
    }

    groupMetrics(metric1, metric2) {
        const groupedMetric = { ...metric1 };
        groupedMetric.id += `,${metric2.id}`;
        groupedMetric.lap += `,${metric2.lap}`;
        groupedMetric.stroke_type =
            metric1.stroke_type === metric2.stroke_type
                ? metric1.stroke_type
                : `${metric1.stroke_type},${metric2.stroke_type}`;
        this.lapMetrics.forEach((lapMetric: LapMetric) => {
            const prop = lapMetric.prop;
            // For stroke_rate, convert the metrics first before adding and averaging
            let combinedValue =
                prop === MetricProperty.AVG_CYCLE_HS && metric1[prop] && metric2[prop]
                    ? // @ts-ignore
                      lapMetric.convertUnits(
                          metric1[prop],
                          metric1.stroke_type
                      ) +
                      // @ts-ignore
                      lapMetric.convertUnits(metric2[prop], metric2.stroke_type)
                    : metric1[prop] && metric2[prop]
                    ? metric1[prop] + metric2[prop]
                    : undefined;
            // @ts-ignore
            if (isNumber(combinedValue) && lapMetric.averageOnReduce) {
                combinedValue /= 2;
            }
            // Use stroke_rate_converted key to store value and keep rate unchanged
            if (prop === MetricProperty.AVG_CYCLE_HS) {
                groupedMetric.stroke_rate_converted = this.normalize(
                    combinedValue
                );
            } else {
                groupedMetric[prop] = this.normalize(combinedValue);
            }
        });
        groupedMetric._source1 = metric1;
        groupedMetric._source2 = metric2;
        return groupedMetric;
    }

    combineMetricsToDepth(rep, lapMetric, depth) {
        this.ungroupMetrics(rep);
        depth = depth && depth > 0 ? depth : 1;
        if (
            !lapMetric.isGroupable ||
            (depth > 1 && (rep.metrics || []).length % 2 !== 0)
        ) {
            depth = 1;
        }
        if (depth > 1) {
            rep._metricSource = [...rep.metrics];
        }
        for (let i = 0; i < depth - 1; i++) {
            const newMetrics = [];
            for (let j = 0; j < rep.metrics.length; j += 2) {
                const combinedMetric = this.groupMetrics(
                    rep.metrics[j],
                    rep.metrics[j + 1]
                );
                newMetrics.push(combinedMetric);
            }
            rep.metrics = newMetrics;
        }
    }

    getRepChartSeries(
        reps,
        repMetrics = this.lapMetrics,
        groupToDepth,
        seriesEvents,
        isApp
    ) {
        repMetrics = this.lapMetrics;
        const filteredReps = reps.filter((rep) => rep && rep.id);
        let values = [];
        let series = [];
        repMetrics.forEach((repMetric: LapMetric, metricIndex: number) => {
            series = series.concat(
                filteredReps.map((rep, index) => {
                    values = [];
                    if (rep && Array.isArray(rep.metrics)) {
                        this.combineMetricsToDepth(
                            rep,
                            repMetric,
                            groupToDepth
                        );
                        values = rep.metrics.map((metric) => {
                            // Show converted stroke rate average for stroke rate metric
                            return (
                                (repMetric.prop === MetricProperty.AVG_CYCLE_HS &&
                                    // @ts-ignore
                                    repMetric.convertUnits(
                                        metric[repMetric.prop],
                                        metric.stroke_type
                                    )) ||
                                0
                            );
                            // return metric[repMetric.prop];
                        });
                        // @ts-ignore
                        repMetric.postCollect(values);
                    }
                    return {
                        data: values,
                        cursor: 'pointer',
                        showInLegend: false,
                        lineWidth: 3,
                        allowPointSelect: isApp,
                        point: {
                            events: isApp ? seriesEvents : {},
                        },
                        states: {
                            hover: {
                                enabled: !isApp,
                                halo: false,
                            },
                        },
                        marker: {
                            enabled: values.length <= MAX_POINTS,
                            radius: 6,
                            fillColor: this.colors[index][0],
                            symbol: this.symbols[index],
                            states: {
                                hover: {
                                    enable: !isApp,
                                    lineColor: this.colors[index][1],
                                    lineWidth: 9,
                                },
                                select: {
                                    color: this.colors[index][0],
                                    lineColor: this.colors[index][1],
                                    lineWidth: 9,
                                },
                            },
                        },
                        events: isApp ? {} : seriesEvents || {},
                        color: this.colors[index][0],
                        dashStyle: metricIndex > 0 ? 'Dash' : 'Solid',
                        yAxis: metricIndex,
                        metricIndex,
                        id: `rep${index}-metric${metricIndex}`,
                    };
                })
            );
        });
        return series;
    }

    getAvgWithUnits(dataArray, metricType) {
        const avgValue =
            dataArray.reduce((a, b) => {
                return a + b;
            }, 0) / dataArray.length;

        if (metricType.prop === MetricProperty.SPLIT_TIME
         || metricType.prop === MetricProperty.TURN_TIME
         || metricType.prop === MetricProperty.TIME_UW
        ) {
            const convertToTime = new TimerConverter(avgValue, ConversionUnit.MILLISECONDS);
            return convertToTime.convert(ConversionUnit.TIMER_AUTO_ADVANCE);
        } else if (metricType.prop === MetricProperty.STROKES) {
            return avgValue.toFixed(0);
        }

        return `${avgValue.toFixed(2)} ${metricType.units}`;
    }
}
