<div
    class="avatar"
    [ngClass]="{ small: small, inversed: inversed, square: square }"
    [ngStyle]="{ width: imageWidth, height: imageHeight }"
>
    <img
        *ngIf="imageSrc !== ''"
        class="avatar-image"
        [src]="imageSrc"
        alt="Avatar Image"
    />
    <div
        *ngIf="imageSrc === '' && initials !== ''"
        class="initials"
        [ngStyle]="{ fontSize: initialsFontSize }"
    >{{initials}}</div>
    <div
        *ngIf="imageSrc === '' && initials === ''"
        class="empty-image"
    >
        <span class="material-icons-outlined">broken_image</span>
    </div>
</div>
