import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'injuryRiskToString',
})
export class InjuryRiskPipe implements PipeTransform {
    transform(injuryRisk: number): unknown {
        if (!injuryRisk) {
            return '-';
        }

        let injuryRiskString: string;
        if (injuryRisk >= 200) {
            injuryRiskString = 'Extreme';
        } else if (injuryRisk > 175) {
            injuryRiskString = 'High'
        } else if (injuryRisk > 150) {
            injuryRiskString = 'Elevated'
        } else if (injuryRisk > 130) {
            injuryRiskString = 'Slight'
        } else {
            injuryRiskString = 'Minimal'
        }

        return injuryRiskString;
    }
}