import { ConversionUnit } from "../../enums/ConversionUnit";
import InvalidDataError from "../../errors/InvalidDataError";
import DistanceConverter from './DistanceConverter';
import { Pool } from '../../enums/Pool';
import BaseConverter from './BaseConverter';

const MULTIPLE = 20;
const YARDS_ADJUSTMENT = 1;
const MIN_POOL_LEN_IDX_M = 300;     // 15m
const MAX_POOL_LEN_IDX_M = 2000;    // 100m
const MIN_POOL_LEN_IDX_Y = 329;     // 16.4 y = 15m
const MAX_POOL_LEN_IDX_Y = 2181;    // 109 y = 100m
const MIN_POOL_LENGTH_M = 15;
const MAX_POOL_LENGTH_M = 100;
const MIN_POOL_LENGTH_Y = 16.4;
const MAX_POOL_LENGTH_Y = 109;
const LCM_METERS = 50;
const SCM_METERS = 25;
const SCY_METERS = 22.86;
const LCM_YARDS = 54.6807;
const SCM_YARDS = 27.3403;
const SCY_YARDS = 25;

export default class PoolLengthToIdxConverter extends BaseConverter {
    private value: number;
    private unit: ConversionUnit;

    constructor(value: number, unit: ConversionUnit) {
        super();

        if (unit === ConversionUnit.POOL_LENGTH_IDX) {
            if (value !== Pool.LCM && value !== Pool.SCM && value !== Pool.SCY) {
                if (value % 2 === 0) {
                    if (value < MIN_POOL_LEN_IDX_M || value > MAX_POOL_LEN_IDX_M) {
                        throw new InvalidDataError(`value: ${value} must be >= ${MIN_POOL_LEN_IDX_M} AND <= ${MAX_POOL_LEN_IDX_M} for m -> pool_len_idx.`);
                    }
                } else {
                    if (value < MIN_POOL_LEN_IDX_Y || value > MAX_POOL_LEN_IDX_Y) {
                        throw new InvalidDataError(`value: ${value} must be >= ${MIN_POOL_LEN_IDX_Y} AND <= ${MAX_POOL_LEN_IDX_Y} for yd -> pool_len_idx.`);
                    }
                }
            }
        } else if (unit === ConversionUnit.METERS) {
            if (value < MIN_POOL_LENGTH_M || value > MAX_POOL_LENGTH_M) {
                throw new InvalidDataError(`value: ${value} must be >= ${MIN_POOL_LENGTH_M} AND <= ${MAX_POOL_LENGTH_M} for m.`);
            }
        } else if (unit === ConversionUnit.YARDS) {
            if (value < MIN_POOL_LENGTH_Y || value > MAX_POOL_LENGTH_Y) {
                throw new InvalidDataError(`value: ${value} must be >= ${MIN_POOL_LENGTH_Y} AND <= ${MAX_POOL_LENGTH_Y} for yd.`);
            }
        }
        
        if (PoolLengthToIdxConverter.convertsTo().indexOf(unit) === -1) {
            throw new InvalidDataError(`unit: ${unit} can't be handled.`);
        }

        this.value = value;
        this.unit = unit;
    }

    public static convertsFrom(): Array<ConversionUnit> {
        return [
            ConversionUnit.POOL_LENGTH_IDX,
            ConversionUnit.METERS,
            ConversionUnit.YARDS
        ];
    }
    
    public static convertsTo(): Array<ConversionUnit> {
        return [
            ConversionUnit.POOL_LENGTH_IDX,
            ConversionUnit.METERS,
            ConversionUnit.YARDS
        ];
    }

    public convert(to: ConversionUnit): number {
        switch (this.unit) {
            case ConversionUnit.METERS:
                return this.convertMeters(to);
            case ConversionUnit.YARDS:
                return this.convertYards(to);
            case ConversionUnit.POOL_LENGTH_IDX:
                return this.convertPoolLenIdx(to);
            default:
                throw new InvalidDataError(`${to} is not a valid unit, see "convertsTo()"`);
        }
    }

    private convertYards(to: ConversionUnit): number {
        switch (to) {
            case ConversionUnit.POOL_LENGTH_IDX:
                return (this.value * MULTIPLE) + YARDS_ADJUSTMENT;
            default:
                throw new InvalidDataError(`${to} is not a valid unit, see "convertsTo()"`);
        }
    }

    private convertMeters(to: ConversionUnit): number {
        switch (to) {
            case ConversionUnit.POOL_LENGTH_IDX:
                return this.value * MULTIPLE;
            default:
                throw new InvalidDataError(`${to} is not a valid unit, see "convertsTo()"`);
        }
    }

    private convertPoolLenIdx(to: ConversionUnit): number {
        const idxUnit = this.value % 2 === 0 ? ConversionUnit.METERS : ConversionUnit.YARDS;

        switch (to) {
            case ConversionUnit.METERS:
                if (this.value === Pool.LCM) {
                    return LCM_METERS;
                } else if (this.value === Pool.SCM) {
                    return SCM_METERS;
                } else if (this.value === Pool.SCY) {
                    return SCY_METERS;
                } else if (idxUnit === ConversionUnit.METERS) {
                    return this.value / MULTIPLE;
                } else {
                    const distanceConverter = new DistanceConverter(this.value / MULTIPLE, ConversionUnit.METERS);
                    return distanceConverter.convert(ConversionUnit.YARDS);
                }
            case ConversionUnit.YARDS:
                if (this.value === Pool.LCM) {
                    return LCM_YARDS;
                } else if (this.value === Pool.SCM) {
                    return SCM_YARDS;
                } else if (this.value === Pool.SCY) {
                    return SCY_YARDS;
                } else if (idxUnit === ConversionUnit.YARDS) {
                    return (this.value - YARDS_ADJUSTMENT) / MULTIPLE;
                } else {
                    const distanceConverter = new DistanceConverter((this.value - YARDS_ADJUSTMENT) / MULTIPLE, ConversionUnit.METERS);
                    return distanceConverter.convert(ConversionUnit.YARDS);
                }
            default:
                throw new InvalidDataError(`${to} is not a valid unit, see "convertsTo()"`);
        }
    }
}