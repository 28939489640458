import { Component, OnInit, Input, OnChanges } from '@angular/core';

import User from 'src/app/core/models/User';

const MAX_PER_ROW = 8;

@Component({
    selector: 'app-athlete-grid',
    templateUrl: './athlete-grid.component.html',
    styleUrls: ['./athlete-grid.component.scss']
})
export class AthleteGridComponent implements OnInit, OnChanges {
    @Input() users: Array<User>;
    @Input() teamId: number;
    @Input() showTritonScore: boolean;

    public athletes: Array<User>;

    constructor() {}

    ngOnInit(): void {
        this.getAthletes();
    }

    ngOnChanges(): void {
        this.getAthletes();
    }

    private getAthletes(): void {
        if (this.users) {
            const users = this.users;

            const filler = [];
            if (users.length < MAX_PER_ROW) {
                for (let i = 0; i < (MAX_PER_ROW - users.length); i++) {
                    filler.push({ filler: true });
                }
            }

            this.athletes = [...users, ...filler];
        }
    }
}
