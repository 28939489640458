import { ConversionUnit } from '../../enums/ConversionUnit';
import { Table } from '../../enums/Table';
import { deepCopy } from '../../utils/deepCopy';
import CrudModel from '../generic/CrudModel';
import DateTimeConverter from '../../utils/converters/DateTimeConverter';
import InvalidDataError from "../../errors/InvalidDataError";
import StrokeScore from './StrokeScore';
import StrokeMap from '../generic/StrokeMap';
import { StrokeType } from '../../enums/StrokeType';

export default class IntensityScore extends CrudModel<IntensityScore> {

    public id: number;
    public score: number;
    public distance: number;
    public distance_score: number;
    public swim_intensity_score: number;
    public activity_score: number;
    public active_sec: number;
    public rest_sec: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public workout_at: Date;
    public stroke_scores: Array<StrokeScore>;

    constructor() {
        super();
        this.score = null;
        this.distance = null;
        this.distance_score = null;
        this.swim_intensity_score = null;
        this.activity_score = null;
        this.active_sec = null;
        this.rest_sec = null;
        this.workout_at = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.stroke_scores = [];
    }

    public duplicate(): IntensityScore {
        const properties = deepCopy(this);
        const intensityScore = new IntensityScore();
        intensityScore.parseJson(properties);
        return intensityScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for IntensityScore parseJson');
        }

        this.id = json.id;
        this.score = json.score;
        this.distance = json.distance;
        this.distance_score = json.distance_score;
        this.swim_intensity_score = json.swim_intensity_score;
        this.activity_score = json.activity_score;
        this.active_sec = json.active_sec;
        this.rest_sec = json.rest_sec;

        if (json.created_at) {
            this.created_at = new Date(json.created_at);
        }

        if (json.updated_at) {
            this.updated_at = new Date(json.updated_at);
        }

        if (json.deleted_at) {
            this.deleted_at = new Date(json.deleted_at);
        }

        if (json.workout_at) {
            this.workout_at = new Date(json.workout_at);
        }

        if (json.stroke_scores) {
            const strokeScores: Array<StrokeScore> = [];

            json.stroke_scores.forEach((score: StrokeScore) => {
                const strokeScore = new StrokeScore();
                strokeScore.parseJson(score);
                strokeScores.push(strokeScore);
            });

            this.stroke_scores = strokeScores;
        }
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for IntensityScore parsedata');
        }

        if (!(data instanceof Array)) {
            data = [data];
        }

        this.id = data[0].intensity_score_id;
        this.score = data[0].intensity_score_score;
        this.distance = data[0].intensity_score_distance;
        this.distance_score = data[0].intensity_score_distance_score;
        this.swim_intensity_score = data[0].intensity_score_swim_intensity_score;
        this.activity_score = data[0].intensity_score_activity_score;
        this.active_sec = data[0].intensity_score_active_sec;
        this.rest_sec = data[0].intensity_score_rest_sec;

        if (data[0].intensity_score_created_at) {
            if (data[0].intensity_score_created_at instanceof Date) {
                this.created_at = data[0].intensity_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data[0].intensity_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].intensity_score_updated_at) {
            if (data[0].intensity_score_updated_at instanceof Date) {
                this.updated_at = data[0].intensity_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data[0].intensity_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].intensity_score_deleted_at) {
            if (data[0].intensity_score_deleted_at instanceof Date) {
                this.deleted_at = data[0].intensity_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data[0].intensity_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].intensity_score_workout_at) {
            if (data[0].intensity_score_workout_at instanceof Date) {
                this.workout_at = data[0].intensity_score_workout_at;
            } else {
                const workoutAtDateTimeConverter = new DateTimeConverter(data[0].intensity_score_workout_at, ConversionUnit.SQL_DATETIME);
                this.workout_at = workoutAtDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        let currentStrokeScoreId: number;
        let currentStrokeScoreData = [];

        for (let i = 0; i < data.length; i++) { // iterate and group stroke scores
            const curData = data[i];

            if (!currentStrokeScoreId) {
                currentStrokeScoreId = curData.stroke_score_id;
            }

            if (curData.intensity_score_id !== this.id) {
                throw new InvalidDataError(`StrokeScore with intensity_score_id ${curData.intensity_score_id} does not belong to IntensityScore with id ${this.id}`);
            } else if (curData.stroke_score_id === currentStrokeScoreId) {
                currentStrokeScoreData.push(curData);
            } else {
                currentStrokeScoreData = [];
                currentStrokeScoreData.push(curData);
                currentStrokeScoreId = curData.stroke_score_id;
            }

            if (currentStrokeScoreId) {
                const newStrokeScore = new StrokeScore();
                newStrokeScore.parseData(currentStrokeScoreData);
                this.stroke_scores.push(newStrokeScore);
            }
        }
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.INTENSITY_SCORES;
    }

    public getStrokeIntensityScores(): StrokeMap<number> {
        const strokeMap = new StrokeMap<number>();
        this.stroke_scores.forEach((score: StrokeScore) => {
            switch (score.stroke_type) {
                case StrokeType.FLY:
                    strokeMap.fly = score.stroke_intensity_score;
                    break;
                case StrokeType.BACK:
                    strokeMap.back = score.stroke_intensity_score;
                    break;
                case StrokeType.BREAST:
                    strokeMap.breast = score.stroke_intensity_score;
                    break;
                case StrokeType.FREE:
                    strokeMap.free = score.stroke_intensity_score;
                    break;
            }
        });

        return strokeMap;
    }
}
