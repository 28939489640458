import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'activity',
})
export class ActivityPipe implements PipeTransform {
    transform(start: Date | string): unknown {
        const startTime = new Date(start);
        let activity = '';
        const hours = startTime.getHours();
        if (hours >= 1 && hours <= 12) {
            activity = 'Morning';
        } else if (hours > 12 && hours <= 17) {
            activity = 'Afternoon';
        } else if (hours > 17 && hours <= 24) {
            activity = 'Evening';
        }
        return activity;
    }
}
