import { ConversionUnit } from '../../enums/ConversionUnit';
import InvalidDataError from '../../errors/InvalidDataError';
import SqlDateTimeValidator from '../validators/SqlDateTimeValidator';
import SqlDateValidator from '../validators/SqlDateValidator';
import BaseConverter from './BaseConverter';

export default class DateTimeConverter extends BaseConverter {

    private value: string | number | Date;
    private fromUnit: ConversionUnit;

    private readonly CUSTOM_TWELVE_HOUR_FORMAT_REGEX: RegExp = /^(0[1-9]|1[0-2]|[1-9]):([0-5][0-9]) ?(([aA]|[pP])\.[mM]\.|([aA]|[pP])[mM])$/g;
    // @todo: Determine a need for SQL_TIME_FORMAT_REGEX
    // private readonly SQL_TIME_FORMAT_REGEX: RegExp = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/g;

    constructor(value: string | number | Date, fromUnit: ConversionUnit) {
        super();

        if (DateTimeConverter.convertsFrom().indexOf(fromUnit) === -1) {
            throw new InvalidDataError(`DateTimeConverter constructor() Unit "${fromUnit}" cannot be handled. See "convertsFrom()"`);
        }

        if (fromUnit !== ConversionUnit.EPOCH_MS && !value) {
            throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" cannot be handled. Unit: ${fromUnit}`);
        }

        const valueType = typeof value;
        const valueString = value as string;

        if (fromUnit === ConversionUnit.JS_DATE) {
            if (!(value instanceof Date)) {
                throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" must be a javascript Date object when converting from unit "${fromUnit}"`);
            }

            if (value.toString() === 'Invalid Date') {
                throw new InvalidDataError(`DateTimeConverter constructor() Value is a javascript Date object but is an Invalid Date. Please check the source of your javascript Date object. Value: "${value}"`);
            }
        }

        if (valueType !== 'string' && (fromUnit === ConversionUnit.SQL_DATE || fromUnit === ConversionUnit.SQL_DATETIME)) {
            throw new InvalidDataError(`DateTimeConverter constructor() Value must a type of string and not a type of "${valueType}". Value: "${value}"`);
        }

        if (fromUnit === ConversionUnit.SQL_DATE && !SqlDateValidator.isValid(valueString)) {
            throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" is not in the proper SQL_DATE format.`);
        }

        if (fromUnit === ConversionUnit.SQL_DATETIME && !SqlDateTimeValidator.isValid(valueString)) {
            throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" is not in the proper SQL_DATETIME format.`);
        }

        if (fromUnit === ConversionUnit.EPOCH_MS) {
            if (valueType !== 'number') {
                throw new InvalidDataError(`DateTimeConverter constructor() Value must a type of number and not a type of "${valueType}". Value: "${value}"`);
            }

            if (isNaN(value as number)) {
                throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" must not be NaN. Unit: "${fromUnit}"`);
            }

            if (value < 0) {
                throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" must be greater than or equal to zero.`);
            }
        }

        if (fromUnit === ConversionUnit.CUSTOM_TWELVE_HOUR && !this.CUSTOM_TWELVE_HOUR_FORMAT_REGEX.test(valueString)) {
            throw new InvalidDataError(`DateTimeConverter constructor() Value "${value}" is not in the proper CUSTOM_TWELVE_HOUR format. See "CUSTOM_TWELVE_HOUR_FORMAT_REGEX"`);
        }

        this.fromUnit = fromUnit;
        this.value = value;
    }

    public static convertsFrom(): Array<ConversionUnit> {
        return [
            ConversionUnit.JS_DATE,
            ConversionUnit.EPOCH_MS,
            ConversionUnit.SQL_DATE,
            ConversionUnit.SQL_DATETIME,
            ConversionUnit.CUSTOM_TWELVE_HOUR,
        ];
    }

    public static convertsTo(): Array<ConversionUnit> {
        return [
            ConversionUnit.JS_DATE,
            ConversionUnit.EPOCH_MS,
            ConversionUnit.SQL_TIME,
            ConversionUnit.SQL_DATE,
            ConversionUnit.SQL_DATETIME,
        ];
    }

    public convert(toUnit: ConversionUnit): string | number | Date {
        if (this.fromUnit === ConversionUnit.CUSTOM_TWELVE_HOUR && toUnit !== ConversionUnit.SQL_TIME) {
            throw new InvalidDataError(`DateTimeConverter convert() Unit "${this.fromUnit}" can only be converted to SQL_TIME`);
        }

        if (this.fromUnit !== ConversionUnit.CUSTOM_TWELVE_HOUR && toUnit === ConversionUnit.SQL_TIME) {
            throw new InvalidDataError(`DateTimeConverter convert() Unit "${toUnit}" can only be converted from CUSTOM_TWELVE_HOUR`);
        }

        switch (toUnit) {
            case ConversionUnit.JS_DATE:
                return this.convertToJsDate();
            case ConversionUnit.SQL_DATE:
                return this.convertToSqlDate();
            case ConversionUnit.SQL_DATETIME:
                return this.convertToSqlDateTime();
            case ConversionUnit.EPOCH_MS:
                return this.convertToEpochMs();
            case ConversionUnit.SQL_TIME:
                return this.convertToTwentyFourHour();
            default:
                throw new InvalidDataError(`DateTimeConverter convert() Unit "${toUnit}" is not a valid unit. See "convertsTo()"`);
        }
    }

    private convertToJsDate(): Date {
        switch (this.fromUnit) {
            case ConversionUnit.JS_DATE:
                return this.value as Date;

            case ConversionUnit.SQL_DATE:
            case ConversionUnit.SQL_DATETIME:
                const valueString = this.value as string;
                return this.dateToUTC(valueString);

            case ConversionUnit.EPOCH_MS:
                return this.dateToUTC(new Date(this.value));
            default:
                throw new InvalidDataError(`DateTimeConverter convertToJsDate() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    private convertToSqlDate(): string {
        switch (this.fromUnit) {
            case ConversionUnit.JS_DATE:
                const valueDate = this.value as Date;
                const jsDate = this.dateToUTC(valueDate);
                return jsDate.toISOString().slice(0, 10);

            case ConversionUnit.SQL_DATE:
                return this.value as string;

            case ConversionUnit.SQL_DATETIME:
                const valueString = this.value as string;
                return valueString.slice(0, 10);

            case ConversionUnit.EPOCH_MS:
                const epochDate = this.dateToUTC(new Date(this.value));
                return epochDate.toISOString().slice(0, 10);
            default:
                throw new InvalidDataError(`DateTimeConverter convertToSqlDate() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    private convertToSqlDateTime(): string {
        switch (this.fromUnit) {
            case ConversionUnit.JS_DATE:
                const valueDate = this.value as Date;
                const jsDate = this.dateToUTC(valueDate);
                return jsDate.toISOString().slice(0, 19).replace('T', ' ');

            case ConversionUnit.SQL_DATE:
                return `${this.value} 00:00:00`;

            case ConversionUnit.SQL_DATETIME:
                return this.value as string;

            case ConversionUnit.EPOCH_MS:
                const epochDate = this.dateToUTC(new Date(this.value));
                return epochDate.toISOString().slice(0, 19).replace('T', ' ');
            default:
                throw new InvalidDataError(`DateTimeConverter convertToSqlDateTime() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    private convertToEpochMs(): number {
        let jsDate;
        switch (this.fromUnit) {
            case ConversionUnit.JS_DATE:
                jsDate = this.value as Date;
                return jsDate.getTime();

            case ConversionUnit.SQL_DATE:
            case ConversionUnit.SQL_DATETIME:
                jsDate = this.value as Date;
                const date = this.dateToUTC(jsDate);
                return date.getTime();

            case ConversionUnit.EPOCH_MS:
                return this.value as number;

            default:
                throw new InvalidDataError(`DateTimeConverter convertToEpochMs() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()"`);
        }
    }

    private convertToTwentyFourHour(): string {
        switch (this.fromUnit) {
            case ConversionUnit.CUSTOM_TWELVE_HOUR:
                const valueString = this.value as string;
                const time = valueString.replace(/[ .a-zA-Z]/g, '').split(':');
                const ampm = valueString.match(/[a-zA-Z]/g);
                let hour = parseInt(time[0], 10);

                if (ampm[0] === 'P' && hour < 12) {
                    hour += 12;
                }

                if (ampm[0] === 'A' && hour === 12) {
                    hour = 0;
                }

                const hourString = hour < 10 ? `0${hour}` : hour;

                return `${hourString}:${time[1]}:00`;
            default:
                throw new InvalidDataError(`DateTimeConverter convertToTwentyFourHour() Unit "${this.fromUnit}" is not a valid unit. See "convertsFrom()`);
        }
    }

    private dateToUTC(date: string | Date): Date {
        if (typeof date === 'string') {
            const splitArray = date.split(/[- :]/).map(item => parseInt(item, 10));

            return new Date(Date.UTC(
                splitArray[0] ? splitArray[0] : 0,
                splitArray[1] ? splitArray[1] - 1 : 0,
                splitArray[2] ? splitArray[2] : 0,
                splitArray[3] ? splitArray[3] : 0,
                splitArray[4] ? splitArray[4] : 0,
                splitArray[5] ? splitArray[5] : 0,
            ));
        }

        return new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        ));
    }
}
