export enum RepProperty {
    ID = 'id',
    UID = 'uid',
    SET_ID = 'set_id',
    STROKE = 'stroke',
    LAP_COUNT = 'lap_count',
    PACE = 'pace',
    TIME = 'time',
    AVG_VEL = 'avg_vel',
    CREATED_AT = 'created_at',
    UPDATED_AT = 'updated_at',
    AVG_STROKES = 'avg_strokes',
    AVG_BREATHS = 'avg_breaths',
    AVG_STROKE_RATE = 'avg_stroke_rate',
    AVG_DPS = 'avg_dps',
    AVG_STROKE_INDEX = 'avg_stroke_index',
    AVG_SPEED_OW = 'avg_speed_ow',
    AVG_STROKE_RATE_START = 'avg_stroke_rate_start',
    AVG_STROKE_RATE_MID = 'avg_stroke_rate_mid',
    AVG_STROKE_RATE_END = 'avg_stroke_rate_end',
    AVG_SWOLF = 'avg_swolf',
    AVG_TIME_OW = 'avg_time_ow',
    AVG_PUSH_STRENGTH = 'avg_push_strength',
    AVG_PUSHOFF_MAX_ACCX = 'avg_pushoff_max_accx',
    AVG_PUSHOFF_POWER = 'avg_pushoff_power',
    AVG_TURN_TIME = 'avg_turn_time',
    AVG_TRANSITION_TIME = 'avg_transition_time',
    AVG_PUSH_TIME = 'avg_push_time',
    DIVE_HANG_TIME = 'dive_hang_time',
    DIVE_BLOCK_TIME = 'dive_block_time',
    DIVE_TIME = 'dive_time',
    AVG_TURN_RATE = 'avg_turn_rate',
    AVG_PUSHOFF_DEPTH = 'avg_pushoff_depth',
    AVG_UW_SPD_CMPS = 'avg_uw_spd_cmps',
    AVG_UW_MAXDEPTH_CM = 'avg_uw_maxdepth_cm',
    AVG_TIME_UW = 'avg_time_uw',
    AVG_PERCENT_UW = 'avg_percent_uw',
    SPEED = 'speed',
    AVG_ICV_LEFT_CMPS = 'avg_icv_left_cmps',
    AVG_ICV_RIGHT_CMPS = 'avg_icv_right_cmps',
    AVG_ICV_LEFT_BREATH_CMPS = 'avg_icv_left_breath_cmps',
    AVG_ICV_RIGHT_BREATH_CMPS = 'avg_icv_right_breath_cmps',
}
