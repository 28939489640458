export enum Event {
    // Custom: this type can be used to pass any frontend or backend events specific
    // to that interface. Distinguishing the events from eachother will be the job
    // of said frontend or backend
    CUSTOM = 'event:custom',

    // Bluetooth
    UNIT_SCAN_STARTED = 'bluetooth:scan_started',
    UNIT_SCAN_COMPLETE = 'bluetooth:scan_complete',
    UNIT_ADVERTISED = 'bluetooth:unit_advertised',
    UNIT_ADVERTISEMENT_UPDATED = 'bluetooth:unit_advertisement_updated',

    // Core Workout
    CORE_WORKOUT_STARTED = 'coreworkout:started',
    CORE_WORKOUT_ENDED = 'coreworkout:ended',
    CORE_WORKOUT_NEW_METRICS = 'coreworkout:new_metrics',
    CORE_WORKOUT_STATE_UPDATE = 'coreworkout:state_update',
    CORE_WORKOUT_BATTERY_UPDATE = 'coreworkout:battery_update',
    CORE_WORKOUT_SUSPENDED = 'coreworkout:suspended',
    CORE_WORKOUT_UNSUSPENDED = 'coreworkout:unsuspended',
    CORE_WORKOUT_PRIORITIZED = 'coreworkout:prioritized',
    CORE_WORKOUT_EXECUTION_STARTED = 'coreworkout:execution_started',
    CORE_WORKOUT_FATAL_ERROR = 'coreworkout:fatal_error',
    CORE_WORKOUT_MAX_ITERATIONS_HIT = 'coreworkout:max_iterations_hit',

    // LIVE_SESSION
    LIVE_SESSION_STARTED = 'livesession:started',
    LIVE_SESSION_ENDED = 'livesession:ended',

    // Live Workouts
    LIVE_WORKOUT_STARTED = 'liveworkout:workout_started',
    LIVE_WORKOUT_CURRENT_REP_UPDATED = 'liveworkout:current_rep_updated',
    LIVE_WORKOUT_BATTERY_UPDATED = 'liveworkout:battery_updated',
    LIVE_WORKOUT_UPDATED = 'liveworkout:workout_updated',
    LIVE_WORKOUT_ENDED = 'liveworkout:ended',
    LIVE_WORKOUT_FATAL_ERROR = "liveworkout:fatal_error",

    // Firmware Update
    FIRMWARE_UPDATE_STARTED = 'firmwareupdate:started',
    FIRMWARE_UPDATE_FAILED = 'firmwareupdate:failed',
    FIRMWARE_UPDATE_COMPLETE = 'firmwareupdate:complete',
    FIRMWARE_UPDATE_QUEUED = 'firmwareupdate:queued',
    FIRMWARE_UPDATE_DEQUEUED = 'firmwareupdate:dequeued',
    FIRMWARE_UPDATE_PROGRESS = 'firmwareupdate:progress',

    // Workout Recovery
    WORKOUT_RESTORE_COMPLETE = "workoutrecovery:restore_complete",

    // Metric Reader
    METRIC_READ = 'metric:read_success',

    // Unit Meric Reader
    UNIT_METRIC_READER_UNIT_FOUND = 'unitmetricreader:found',
    UNIT_METRIC_READER_UNIT_NOT_FOUND = 'unitmetricreader:not_found',
    UNIT_METRIC_READER_STARTED = 'unitmetricreader:started',
    UNIT_METRIC_READER_FINISHED = 'unitmetricreader:finished',
    UNIT_METRIC_READER_FATAL_ERROR = 'unitmetricreader:fatal_error',
}
