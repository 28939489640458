import { Pipe, PipeTransform } from '@angular/core';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import TimerConverter from 'src/app/core/utils/converters/TimerConverter';

@Pipe({
    name: 'msToTime',
})
export class MsToTimePipe implements PipeTransform {
    transform(milliseconds: number): string {
        const timerConverter = new TimerConverter(milliseconds, ConversionUnit.MILLISECONDS);
        return timerConverter.convert(ConversionUnit.TIMER_AUTO_ADVANCE);
    }
}
