import { ConversionUnit } from '../../enums/ConversionUnit';
import IConverter from '../../interfaces/IConverter';

export default class BaseConverter implements IConverter {
    public static convertsFrom(): Array<ConversionUnit> {
        throw new Error('You need to implement this');
    }
    
    public static convertsTo(): Array<ConversionUnit> {
        throw new Error('You need to implement this');
    }

    public convert(_: ConversionUnit): any {
        throw new Error('You need to implement this');
    }
}