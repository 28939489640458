export enum CustomEvent {
    REP_LIST_REP_SELECTED = 'ui:rep_list_rep_selected',
    SET_LIST_SET_SELECTED = 'ui:set_list_set_selected',
    FAVOURITE_DISPLAY_METRIC_SELECTED = 'ui:favourite_display_metric_selected',
    DISPLAY_METRIC_SELECTED = 'ui:display_metric_selected',
    GRAPH_REP_SELECTED = 'ui:graph_rep_selected',
    GRAPH_LAP_SELECTED = 'ui:graph_lap_selected',
    THEME_CHANGED = 'ui:theme_changed',
    DEFAULT_VIEW_CHANGED = 'ui:default_view_changed',
    WORKOUT_TYPE_SELECTED = 'ui:workout_type_selected',
    DIALOG_USER_INPUT_SUBMITTED = 'dialog:user_input_submitted',
    DIALOG_REGISTER_ABOUT_ME = 'dialog:register_about_me',
    DIALOG_REGISTER_ABOUT_SWIMMING = 'dialog:register_about_swimming',
    DIALOG_REGISTER_CHANGE_STATE = 'dialog:register_change_state',
    DIALOG_REGISTER_CLOSE = 'dialog:register_close',
    COMPLETE_REGISTRATION = 'complete_registration',
    TIME_SELECTED = 'time_picker:time_selected',
    DATE_SELECTED = 'date_picker:date_selected',
    WORKOUT_CONFIRM_RESUME_SELECTED = 'ui:workout_confirm_resume_selected',
    WORKOUT_CONFIRM_DISCARD_SELECTED = 'ui:workout_confirm_discard_selected',
    WORKOUT_CONFIRM_RETRIEVE_SELECTED = 'ui:workout_confirm_retrieve_selected',
    WORKOUT_RETRIEVE_DATA_SELECTED = 'ui:workout_retrieve_data_selected',
    WORKOUT_RECOVERY_COMPLETE = 'ui:workout_recovery_complete',
    WORKOUT_PROGRESS_INCREMENT = 'ui:workout_progress_increment',
    WORKOUT_SYNC_STARTED = 'workout_sync:started',
    WORKOUT_SYNC_SUCCESS = 'workout_sync:success',
    WORKOUT_SYNC_FAILED = 'workout_sync:failed',
    WORKOUT_SYNC_COMPLETED = 'workout_sync:completed',
    CONNECTIVITY_CHANGE = 'connectivity:change_status',
    NUMBERS_CRUNCHED = 'ui:numbers_crunched_received',
    ADD_RPE = 'ui:add_rpe_received',
    ADD_RPE_CANCELLED = 'ui:add_rpe_cancelled',
    REFRESH_FEED = 'ui:refresh_feed',
    LIVE_SESSION_TIMER_INTERVAL = 'ui:live_session_timer_interval',
    TEAM_UPDATED = 'team_updated',
    INVITE_SENT = 'invite_sent',
    USER_UPDATED = 'user_updated',
    ATHLETE_TILE_CLICKED = 'ui:athlete_tile_clicked',
    ATHLETE_SELECTED = 'ui:athlete_selected',
    TAB_SELECTED = 'ui:tab_selected',
    WORKOUT_CREATED = 'ui:workout_created',
    TREE_TEAM_SELECTED = 'ui:tree_team_selected',
    TRAINING_LOG_DATE_SELECTED = 'ui:training_log_date_selected',
    TRAINING_LOG_DATE_CLEARED = 'ui:training_log_date_cleared',
    TRAINING_LOG_PREVIOUS_CLICKED = 'ui:training_log_previous_clicked',
    TRAINING_LOG_NEXT_CLICKED = 'ui:training_log_next_clicked',
}
