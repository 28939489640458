import { ConversionUnit } from "../../enums/ConversionUnit";
import { MetricProperty } from "../../enums/MetricProperty";
import { StrokeType } from "../../enums/StrokeType";
import { SwimPhase } from "../../enums/SwimPhase";
import { Table } from '../../enums/Table';
import { deepCopy } from "../../utils/deepCopy";
import CrudModel from "../generic/CrudModel";
import DateTimeConverter from "../../utils/converters/DateTimeConverter";
import FocusMetricScore from "./FocusMetricScore";
import InvalidDataError from "../../errors/InvalidDataError";

export default class FocusPhaseScore extends CrudModel<FocusPhaseScore> {

    public id: number;
    public focus_score_id: number;
    public stroke_score_id: number;
    public phase: SwimPhase;
    public stroke_type: StrokeType;
    public pool_length: number;
    public likeyou_score: number;
    public myself_score: number;
    public everyone_score: number;
    public created_at?: Date;
    public updated_at?: Date;
    public deleted_at?: Date;
    public focus_metric_scores: Array<FocusMetricScore>

    constructor() {
        super();
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }

    public duplicate(): FocusPhaseScore {
        const properties = deepCopy(this);
        const focusPhaseScore = new FocusPhaseScore();
        focusPhaseScore.parseJson(properties);
        return focusPhaseScore;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public parseJson(json: any): void {
        if (!json) {
            throw new InvalidDataError('No Data for FocusPhaseScore parseJson');
        }

        this.id = json.id;
        this.focus_score_id = json.focus_score_id;
        this.stroke_score_id = json.stroke_score_id;
        this.phase = json.phase;
        this.stroke_type = json.stroke_type;
        this.pool_length = json.pool_length;
        this.likeyou_score = json.likeyou_score;
        this.myself_score = json.myself_score;
        this.everyone_score = json.everyone_score;
        this.created_at = json.created_at;
        this.updated_at = json.updated_at;
        this.deleted_at = json.deleted_at;

        if (json.focus_metric_scores) {
            const focusMetricScores: Array<FocusMetricScore> = [];

            json.focus_metric_scores.forEach((score: FocusMetricScore) => {
                const focusMetricScore = new FocusMetricScore();
                focusMetricScore.parseJson(score);
                focusMetricScores.push(focusMetricScore);
            });

            this.focus_metric_scores = focusMetricScores;
        }
    }

    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('No Data for FocusPhaseScore parsedata');
        }

        this.id = data[0].focus_phase_score_id;
        this.focus_score_id = data[0].focus_phase_score_focus_score_id;
        this.stroke_score_id = data[0].focus_phase_score_stroke_score_id;
        this.phase = data[0].focus_phase_score_phase;
        this.stroke_type = data[0].focus_phase_score_stroke_type;
        this.pool_length = data[0].focus_phase_score_pool_length;
        this.likeyou_score = data[0].focus_phase_score_likeyou_score;
        this.myself_score = data[0].focus_phase_score_myself_score;
        this.everyone_score = data[0].focus_phase_score_everyone_score;

        if (data[0].focus_phase_score_created_at) {
            if (data[0].focus_phase_score_created_at instanceof Date) {
                this.created_at = data[0].focus_phase_score_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data[0].focus_phase_score_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].focus_phase_score_updated_at) {
            if (data[0].focus_phase_score_updated_at instanceof Date) {
                this.updated_at = data[0].focus_phase_score_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data[0].focus_phase_score_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data[0].focus_phase_score_deleted_at) {
            if (data[0].focus_phase_score_deleted_at instanceof Date) {
                this.deleted_at = data[0].focus_phase_score_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data[0].focus_phase_score_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        const focusMetricScores: Array<FocusMetricScore> = []

        for (let i = 0; i < data.length; i++) {
            const focusMetricScore = new FocusMetricScore();
            focusMetricScore.parseData(data[i]);
            if (focusMetricScore.focus_phase_score_id !== this.id) {
                throw new InvalidDataError(`FocusMetricScore with focus_phase_score_id ${focusMetricScore.focus_phase_score_id} does not belong to FocusPhaseScore with id ${this.id}`);
            }
            focusMetricScores.push(focusMetricScore);
        }

        this.focus_metric_scores = focusMetricScores;
    }

    public getHighestScoringMetric(): FocusMetricScore {
        if (!this.focus_metric_scores.length) {
            throw new InvalidDataError(`Focus Phase Score has no focus_metric_scores and cannot get the highest score.`);
        }

        let highestMetricScore: FocusMetricScore = this.focus_metric_scores[0];
        this.focus_metric_scores.forEach(score => {
            if (score.myself_score > highestMetricScore.myself_score) {
                highestMetricScore = score;
            }
        });

        return highestMetricScore;
     }

     public getLowestScoringMetric(): FocusMetricScore {
        if (!this.focus_metric_scores.length) {
            throw new InvalidDataError(`Focus Phase Score has no focus_metric_scores and cannot get the lowest score.`);
        }

        let lowestMetricScore: FocusMetricScore = this.focus_metric_scores[0];
        this.focus_metric_scores.forEach(score => {
            if (score.myself_score < lowestMetricScore.myself_score) {
                lowestMetricScore = score;
            }
        });

        return lowestMetricScore;
     }

     public getDerivedProperties(): Array<string> {
        return [];
    }

    public getLowestScoringApprovedMetric(): FocusMetricScore {
        if (!this.focus_metric_scores.length) {
            throw new InvalidDataError(`Focus Phase Score has no focus_metric_scores and cannot get the lowest score.`);
        }

        const unApprovedMetricProperties: Array<MetricProperty> = [MetricProperty.UW_SPD_CMPS, MetricProperty.SPEED_OW, MetricProperty.STROKE_INDEX, MetricProperty.STROKES, MetricProperty.SWOLF];
        let lowestMetricScore: FocusMetricScore;
        let minScore = Infinity;

        this.focus_metric_scores.forEach((score: FocusMetricScore) => {
            const index = unApprovedMetricProperties.findIndex((property: MetricProperty) => property === score.metric_property);

            if (score.myself_score < minScore && index === -1) {
                lowestMetricScore = score;
                minScore = score.myself_score;
            }
        });

        return lowestMetricScore;
    }

    public getTableName(): string {
        return Table.FOCUS_PHASE_SCORES;
    }
}
