import { ConversionUnit } from '../enums/ConversionUnit';
import { MetricProperty } from '../enums/MetricProperty';
import { RepProperty } from '../enums/RepProperty';
import { SetProperty } from '../enums/SetProperty';
import { SwimPhase } from '../enums/SwimPhase';
import DisplayMetric from '../models/DisplayMetric';
import InvalidDataError from '../errors/InvalidDataError';

export default class DisplayMetricService {
    // Singleton
    private static instance: DisplayMetricService;

    private displayMetrics: Array<DisplayMetric> = [];

    private constructor() { }

    public static getInstance(): DisplayMetricService {
        if (!DisplayMetricService.instance) {
            DisplayMetricService.instance = new DisplayMetricService();
        }

        return DisplayMetricService.instance;
    }

    // @todo: Remove this stub function once sql side is finished
    public async get(id: number): Promise<DisplayMetric> {
        for (const displayMetric of this.displayMetrics) {
            if (displayMetric.id === id) {
                return Promise.resolve(displayMetric.duplicate());
            }
        }

        const displayMetrics = await this.getAll();

        for (const displayMetric of displayMetrics) {
            if (displayMetric.id === id) {
                return Promise.resolve(displayMetric.duplicate());
            }
        }

        return Promise.reject(new InvalidDataError(`get() No display metric found for id ${id}`));
    }

    // @todo: Implement once sql side is finished
    /*public async get(id: number, dataSource: ISqlDisplayMetricDataSource): Promise<DisplayMetric> {
        for (const displayMetric of this.displayMetrics) {
            if (displayMetric.id === id) {
                return Promise.resolve(displayMetric.duplicate());
            }
        }
        const foundDisplayMetric = await dataSource.get(id);
        this.displayMetrics.push(foundDisplayMetric);
        return Promise.resolve(foundDisplayMetric.duplicate());
    }*/

    // @todo: Remove this stub function once sql side is finished
    public async getAll(): Promise<Array<DisplayMetric>> {
        const displayMetrics = await DisplayMetricService.list();

        this.displayMetrics = displayMetrics;

        return Promise.resolve(displayMetrics);
    }

    // @todo: Implement once sql side is finished. Note: dataSource.getAll is currently not implemented
    /*public async getAll(dataSource: ISqlDisplayMetricDataSource): Promise<Array<DisplayMetric>> {
        const displayMetrics = await dataSource.getAll();
        this.displayMetrics = displayMetrics;
        return Promise.resolve(displayMetrics);
    }*/

    // @todo: Update/remove this function once sql side is finished, note: this is only used in tile-grid.component
    public async getDefaults(): Promise<Array<DisplayMetric>> {
        const list = await DisplayMetricService.list();
        const defaults: DisplayMetric[] = [];

        list.forEach((metric) => {
            if (metric.default === true) {
                defaults.push(metric);
            }
        })

        return Promise.resolve(defaults);
    }

    // @todo: Remove this function once sql side is finished
    public static list(): Promise<Array<DisplayMetric>> {
        const displayMetricsData = [
            {
                display_metric_id: 1,
                display_metric_property: MetricProperty.SPLIT_TIME,
                display_metric_rep_property: RepProperty.TIME,
                display_metric_set_property: SetProperty.TIME,
                display_metric_title: 'Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: false,
                display_metric_description: 'Swimming time in milliseconds for the length',
                display_metric_default: true,
                display_metric_phase: SwimPhase.GENERAL,
            },
            {
                display_metric_id: 2,
                display_metric_property: MetricProperty.AVERAGE_SPD_CMPS,
                display_metric_rep_property: RepProperty.AVG_UW_SPD_CMPS,
                display_metric_set_property: SetProperty.AVG_UW_SPD_CMPS,
                display_metric_title: 'Pace (Speed)',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: false,
                display_metric_description: 'Average speed over full split (split time divided by pool length)',
                display_metric_default: false,
                display_metric_phase: SwimPhase.GENERAL,
            },
            {
                display_metric_id: 3,
                display_metric_property: MetricProperty.SWOLF,
                display_metric_rep_property: RepProperty.AVG_SWOLF,
                display_metric_set_property: SetProperty.AVG_SWOLF,
                display_metric_title: 'SWOLF',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: null,
                display_metric_displaying_unit: null,
                display_metric_advanced: false,
                display_metric_description: 'Swim Golf: Split Seconds + Strokes',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 4,
                display_metric_property: MetricProperty.STROKES,
                display_metric_rep_property: RepProperty.AVG_STROKES,
                display_metric_set_property: SetProperty.AVG_STROKES,
                display_metric_title: 'Stroke Count',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: null,
                display_metric_displaying_unit: null,
                display_metric_advanced: false,
                display_metric_description: 'Number of strokes',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 5,
                display_metric_property: MetricProperty.CYCLE_START_HS,
                display_metric_rep_property: RepProperty.AVG_STROKE_RATE_START,
                display_metric_set_property: SetProperty.AVG_STROKE_RATE_START,
                display_metric_title: 'Stroke Rate (Start)',
                display_metric_abbreviation: 'cyc/min',
                display_metric_tracking_unit: ConversionUnit.HS_PER_CYCLE,
                display_metric_displaying_unit: ConversionUnit.CYCLES_PER_MINUTE,
                display_metric_advanced: true,
                display_metric_description: 'Stroke Rate / Cycle Time of cycles 2-6',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 6,
                display_metric_property: MetricProperty.CYCLE_MID_HS,
                display_metric_rep_property: RepProperty.AVG_STROKE_RATE_MID,
                display_metric_set_property: SetProperty.AVG_STROKE_RATE_MID,
                display_metric_title: 'Stroke Rate (Mid)',
                display_metric_abbreviation: 'cyc/min',
                display_metric_tracking_unit: ConversionUnit.HS_PER_CYCLE,
                display_metric_displaying_unit: ConversionUnit.CYCLES_PER_MINUTE,
                display_metric_advanced: true,
                display_metric_description: 'Stroke Rate / Cycle Time of middle 4 cycles',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 7,
                display_metric_property: MetricProperty.CYCLE_END_HS,
                display_metric_rep_property: RepProperty.AVG_STROKE_RATE_END,
                display_metric_set_property: SetProperty.AVG_STROKE_RATE_END,
                display_metric_title: 'Stroke Rate (End)',
                display_metric_abbreviation: 'cyc/min',
                display_metric_tracking_unit: ConversionUnit.HS_PER_CYCLE,
                display_metric_displaying_unit: ConversionUnit.CYCLES_PER_MINUTE,
                display_metric_advanced: true,
                display_metric_description: 'Stroke Rate / Cycle Time of last 4 cycles',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 8,
                display_metric_property: MetricProperty.AVG_CYCLE_HS,
                display_metric_rep_property: RepProperty.AVG_STROKE_RATE,
                display_metric_set_property: SetProperty.AVG_STROKE_RATE,
                display_metric_title: 'Stroke Rate (Avg.)',
                display_metric_abbreviation: 'cyc/min',
                display_metric_tracking_unit: ConversionUnit.HS_PER_CYCLE,
                display_metric_displaying_unit: ConversionUnit.CYCLES_PER_MINUTE,
                display_metric_advanced: false,
                display_metric_description: 'Average time of each stroke cycle, including all but the first stroke cycle. Note that you can customize the units for stroke rate in the Account Preferences page.',
                display_metric_default: true,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 9,
                display_metric_property: MetricProperty.STROKE_INDEX,
                display_metric_rep_property: RepProperty.AVG_STROKE_INDEX,
                display_metric_set_property: SetProperty.AVG_STROKE_INDEX,
                display_metric_title: 'Stroke Index',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.SCALED_STROKE_INDEX,
                display_metric_displaying_unit: ConversionUnit.METERS_SQUARED_PER_CYCLE_PER_SECOND,
                display_metric_advanced: true,
                display_metric_description: 'Distance per cycle * Average Speed',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 10,
                display_metric_property: MetricProperty.SPEED_OW,
                display_metric_rep_property: RepProperty.AVG_SPEED_OW,
                display_metric_set_property: SetProperty.AVG_SPEED_OW,
                display_metric_title: 'Speed Overwater',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: false,
                display_metric_description: 'Average speed during overwater phase',
                display_metric_default: true,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 11,
                display_metric_property: MetricProperty.TIME_OW,
                display_metric_rep_property: RepProperty.AVG_TIME_OW,
                display_metric_set_property: SetProperty.AVG_TIME_OW,
                display_metric_title: 'Time Overwater',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time in overwater phase, from breakout to last stroke',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 12,
                display_metric_property: MetricProperty.DPS,
                display_metric_rep_property: RepProperty.AVG_DPS,
                display_metric_set_property: SetProperty.AVG_DPS,
                display_metric_title: 'DPS',
                display_metric_abbreviation: 'm/str',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_CYCLE,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_CYCLE,
                display_metric_advanced: false,
                display_metric_description: 'Distance per stroke',
                display_metric_default: true,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 13,
                display_metric_property: MetricProperty.BREATHS,
                display_metric_rep_property: RepProperty.AVG_BREATHS,
                display_metric_set_property: SetProperty.AVG_BREATHS,
                display_metric_title: 'Breath Count',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: null,
                display_metric_displaying_unit: null,
                display_metric_advanced: false,
                display_metric_description: 'Number of breaths',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 14,
                display_metric_property: MetricProperty.TURN_TIME,
                display_metric_rep_property: RepProperty.AVG_TURN_TIME,
                display_metric_set_property: SetProperty.AVG_TURN_TIME,
                display_metric_title: 'Turn Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time to switch directions',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 15,
                display_metric_property: MetricProperty.TURN_RATE,
                display_metric_rep_property: RepProperty.AVG_TURN_RATE,
                display_metric_set_property: SetProperty.AVG_TURN_RATE,
                display_metric_title: 'Turn Rate',
                display_metric_abbreviation: 'RPM',
                display_metric_tracking_unit: ConversionUnit.RPM,
                display_metric_displaying_unit: ConversionUnit.RPM,
                display_metric_advanced: true,
                display_metric_description: 'Speed of turn in revolutions per minute',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 16,
                display_metric_property: MetricProperty.TRANSITION_TIME,
                display_metric_rep_property: RepProperty.AVG_TRANSITION_TIME,
                display_metric_set_property: SetProperty.AVG_TRANSITION_TIME,
                display_metric_title: 'Transition Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: false,
                display_metric_description: 'Time from last stroke entry to next split push end',
                display_metric_default: true,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 17,
                display_metric_property: MetricProperty.PUSH_STRENGTH,
                display_metric_rep_property: RepProperty.AVG_PUSH_STRENGTH,
                display_metric_set_property: SetProperty.AVG_PUSH_STRENGTH,
                display_metric_title: 'Push Strength',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: null,
                display_metric_displaying_unit: null,
                display_metric_advanced: true,
                display_metric_description: 'Index of accumulated total acceleration during pushoff',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 18,
                display_metric_property: MetricProperty.PUSH_TIME,
                display_metric_rep_property: RepProperty.AVG_PUSH_TIME,
                display_metric_set_property: SetProperty.AVG_PUSH_TIME,
                display_metric_title: 'Push Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time spent pushing off the wall',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            // Currently will not be available for launch. Will be added in the future.
            // {
            //     display_metric_id: 19,
            //     display_metric_property: MetricProperty.PUSHOFF_POWER,
            //     display_metric_rep_property: RepProperty.AVG_PUSHOFF_POWER,
            //     display_metric_set_property: SetProperty.AVG_PUSHOFF_POWER,
            //     display_metric_title: 'Push Power',
            //     display_metric_abbreviation  'W/kg',
            //     display_metric_tracking_unit: ConversionUnit.WATTS_PER_KILOGRAM,
            //     display_metric_displaying_unit: ConversionUnit.WATTS_PER_KILOGRAM,
            //     display_metric_advanced: true,
            //     display_metric_description: 'Maximum (speed * acceleration) during pushoff',
            //     display_metric_default: false,
            //     display_metric_phase: SwimPhase.TRANSITION,
            // },
            {
                display_metric_id: 20,
                display_metric_property: MetricProperty.PUSHOFF_MAX_ACCX,
                display_metric_rep_property: RepProperty.AVG_PUSHOFF_MAX_ACCX,
                display_metric_set_property: SetProperty.AVG_PUSHOFF_MAX_ACCX,
                display_metric_title: 'Push Max Accel',
                display_metric_abbreviation: 'm/s2',
                display_metric_tracking_unit: ConversionUnit.SCALED_PUSHOFF_MAX_ACCEL,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND_SQUARED,
                display_metric_advanced: false,
                display_metric_description: 'Peak acceleration during pushoff',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 21,
                display_metric_property: MetricProperty.DIVE_TIME,
                display_metric_rep_property: RepProperty.DIVE_TIME,
                display_metric_set_property: SetProperty.AVG_DIVE_TIME,
                display_metric_title: 'Dive Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time from first movement to dive entry',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 22,
                display_metric_property: MetricProperty.DIVE_HANG_TIME,
                display_metric_rep_property: RepProperty.DIVE_HANG_TIME,
                display_metric_set_property: SetProperty.AVG_DIVE_HANG_TIME,
                display_metric_title: 'Dive Hang Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time from feet off to dive entry',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 23,
                display_metric_property: MetricProperty.DIVE_BLOCK_TIME,
                display_metric_rep_property: RepProperty.DIVE_BLOCK_TIME,
                display_metric_set_property: SetProperty.AVG_DIVE_BLOCK_TIME,
                display_metric_title: 'Dive Block Time',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: true,
                display_metric_description: 'Time from first movement to feet off (Reaction Time without reaction)',
                display_metric_default: false,
                display_metric_phase: SwimPhase.TRANSITION,
            },
            {
                display_metric_id: 24,
                display_metric_property: MetricProperty.TIME_UW,
                display_metric_rep_property: RepProperty.AVG_TIME_UW,
                display_metric_set_property: SetProperty.AVG_TIME_UW,
                display_metric_title: 'Time Underwater',
                display_metric_abbreviation: '',
                display_metric_tracking_unit: ConversionUnit.MILLISECONDS,
                display_metric_displaying_unit: ConversionUnit.TIMER_AUTO_MS_S,
                display_metric_advanced: false,
                display_metric_description: 'Time in underwater phase, from end of push to breakout',
                display_metric_default: false,
                display_metric_phase: SwimPhase.UNDERWATER,
            },
            {
                display_metric_id: 25,
                display_metric_property: MetricProperty.UW_SPD_CMPS,
                display_metric_rep_property: RepProperty.SPEED,
                display_metric_set_property: SetProperty.SPEED,
                display_metric_title: 'Speed Underwater',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: false,
                display_metric_description: 'Average speed during underwater phase',
                display_metric_default: true,
                display_metric_phase: SwimPhase.UNDERWATER,
            },
            {
                display_metric_id: 26,
                display_metric_property: MetricProperty.PUSHOFF_DEPTH,
                display_metric_rep_property: RepProperty.AVG_PUSHOFF_DEPTH,
                display_metric_set_property: SetProperty.AVG_PUSHOFF_DEPTH,
                display_metric_title: 'Pushoff Depth',
                display_metric_abbreviation: 'cm',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS,
                display_metric_displaying_unit: ConversionUnit.CENTIMETERS,
                display_metric_advanced: true,
                display_metric_description: 'Depth of swimmer\'s head at point that feet leave the wall',
                display_metric_default: false,
                display_metric_phase: SwimPhase.UNDERWATER,
            },
            {
                display_metric_id: 27,
                display_metric_property: MetricProperty.PERCENT_UNDERWATER,
                display_metric_rep_property: RepProperty.AVG_PERCENT_UW,
                display_metric_set_property: SetProperty.AVG_PERCENT_UW,
                display_metric_title: '% Underwater',
                display_metric_abbreviation: '%',
                display_metric_tracking_unit: ConversionUnit.PERCENT,
                display_metric_displaying_unit: ConversionUnit.PERCENT,
                display_metric_advanced: true,
                display_metric_description: 'Percent of time underwater vs overwater',
                display_metric_default: false,
                display_metric_phase: SwimPhase.UNDERWATER,
            },
            {
                display_metric_id: 28,
                display_metric_property: MetricProperty.UW_MAXDEPTH_CM,
                display_metric_rep_property: RepProperty.AVG_UW_MAXDEPTH_CM,
                display_metric_set_property: SetProperty.AVG_UW_MAXDEPTH_CM,
                display_metric_title: 'Max Depth',
                display_metric_abbreviation: 'cm',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS,
                display_metric_displaying_unit: ConversionUnit.CENTIMETERS,
                display_metric_advanced: true,
                display_metric_description: 'Maximum depth during underwater phase',
                display_metric_default: false,
                display_metric_phase: SwimPhase.UNDERWATER,
            },
            {
                display_metric_id: 29,
                display_metric_property: MetricProperty.ICV_LEFT_CMPS,
                display_metric_rep_property: RepProperty.AVG_ICV_LEFT_CMPS,
                display_metric_set_property: SetProperty.AVG_ICV_LEFT_CMPS,
                display_metric_title: 'ICV Left',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: true,
                display_metric_description: 'Speed gained and lost during each left stroke',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 30,
                display_metric_property: MetricProperty.ICV_RIGHT_CMPS,
                display_metric_rep_property: RepProperty.AVG_ICV_RIGHT_CMPS,
                display_metric_set_property: SetProperty.AVG_ICV_RIGHT_CMPS,
                display_metric_title: 'ICV Right',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: true,
                display_metric_description: 'Speed gained and lost during each right stroke',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 31,
                display_metric_property: MetricProperty.ICV_LEFT_BREATH_CMPS,
                display_metric_rep_property: RepProperty.AVG_ICV_LEFT_BREATH_CMPS,
                display_metric_set_property: SetProperty.AVG_ICV_LEFT_BREATH_CMPS,
                display_metric_title: 'ICV Breath (L)',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: true,
                display_metric_description: 'Speed gained and lost during each breath stroke on the left',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            },
            {
                display_metric_id: 32,
                display_metric_property: MetricProperty.ICV_RIGHT_BREATH_CMPS,
                display_metric_rep_property: RepProperty.AVG_ICV_RIGHT_BREATH_CMPS,
                display_metric_set_property: SetProperty.AVG_ICV_RIGHT_BREATH_CMPS,
                display_metric_title: 'ICV Breath (R)',
                display_metric_abbreviation: 'm/s',
                display_metric_tracking_unit: ConversionUnit.CENTIMETERS_PER_SECOND,
                display_metric_displaying_unit: ConversionUnit.METERS_PER_SECOND,
                display_metric_advanced: true,
                display_metric_description: 'Speed gained and lost during each breath stroke on the right',
                display_metric_default: false,
                display_metric_phase: SwimPhase.OVERWATER,
            }
        ];

        const displayMetrics: Array<DisplayMetric> = [];
        for (const displayMetric of displayMetricsData) {
            const newDisplayMetric = new DisplayMetric();
            newDisplayMetric.parseData(displayMetric);
            displayMetrics.push(newDisplayMetric);
        }

        return Promise.resolve(displayMetrics);
    }
}
