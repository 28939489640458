import { Component, OnInit } from '@angular/core';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts';
// @ts-ignore
HighchartsMore(Highcharts);
@Component({
    selector: 'app-triton-set-graph',
    templateUrl: './triton-set-graph.component.html',
    styleUrls: ['./triton-set-graph.component.scss'],
})
export class TritonSetGraphComponent implements OnInit {
    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: any;
    constructor() {}

    ngOnInit() {}

    setGraphOptions() {
        this.chartOptions = {};
    }
}
