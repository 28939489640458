import { CommonModule } from '@angular/common';
// import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// import { AvatarModule } from 'src/app/components/avatar/avatar.module';
import { MaterialModule } from 'src/app/material.module';
import { PipesModule } from 'src/app/pipes.module';
import { TritonCardBodyComponent } from 'src/app/components/triton-card/triton-card-body/triton-card-body.component';
import { TritonCardComponent } from 'src/app/components/triton-card/triton-card.component';
import { TritonCardSessionHeaderComponent } from 'src/app/components/triton-card/triton-card-session-header/triton-card-session-header.component';
import { TritonCardSessionHeaderTabletComponent } from 'src/app/components/triton-card/triton-card-session-header/triton-card-session-header-tablet.component';
import { TritonChartModule } from 'src/app/components/triton-chart/triton-chart.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        PipesModule,
        TritonChartModule,
    ],
    declarations: [
        TritonCardComponent,
        TritonCardSessionHeaderComponent,
        TritonCardSessionHeaderTabletComponent,
        TritonCardBodyComponent,
    ],
    exports: [
        TritonCardComponent,
        TritonCardSessionHeaderComponent,
        TritonCardSessionHeaderTabletComponent,
        TritonCardBodyComponent,
    ],
})
export class TritonCardModule {}
