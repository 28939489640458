import IEventBus from '../interfaces/IEventBus';
import { Event } from '../enums/Event'
import { deepCopy } from '../utils/deepCopy';
import IEventListener from '../interfaces/IEventListener';

export default class EventBusService implements IEventBus {
    // Singleton
    private static instance: EventBusService;
    private constructor() { }

    // Instance
    private listeners: any = { };
    private nextId: number = 1;

    public static getInstance(): EventBusService {
        if (!EventBusService.instance) {
            EventBusService.instance = new EventBusService();
        }

        return EventBusService.instance;
    }

    /**
     * Listen to events
     *
     * @param event
     * @param listener
     */
    addListener(event: Event, listener: (data?: any) => void): IEventListener {
        if (!this.listeners[event.valueOf()]) {
            this.listeners[event.valueOf()] = { };
        }

        const id = this.nextId;
        this.nextId++;

        this.listeners[event.valueOf()][id] = listener;

        return {
            id,
            remove: () => {
                delete this.listeners[event.valueOf()][id];
            }
        }
    }

    /**
     * Emit events with data, each listener recieves their own
     * copy of the data
     *
     * @param event
     * @param data
     */
    emit(event: Event, data?: any): void {
        if (!this.listeners[event.valueOf()]) {
            return;
        }

        Object.keys(this.listeners[event.valueOf()]).forEach(key => {
            const listener = this.listeners[event.valueOf()][key];

            if (data) {
                try {
                    listener(deepCopy(data));
                } catch (error) {
                    console.error(`EventBus: emit() error for listener id: ${key} for Event: ${event.valueOf()}`, error);
                }
            } else {
                try {
                    listener();
                } catch (error) {
                    console.error(`EventBus: emit() error for listener id: ${key} for Event: ${event.valueOf()}`, error);
                }
            }
        });
    }
}