import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'upload',
        loadChildren: () =>
            import('src/app/pages/upload/upload.module')
                .then(m => m.UploadPageModule)
    },
    {
        path: 'workout-details',
        loadChildren: () =>
            import('src/app/pages/workout-detail/workout-detail.module')
                .then(m => m.WorkoutDetailPageModule)
    },
    {
        path: 'analysis-preview',
        loadChildren: () =>
            import('src/app/pages/analysis-preview/analysis-preview.module')
                .then(m => m.AnalysisPreviewModule)
    },
    {
        path: 'race',
        loadChildren: () =>
            import('src/app/pages/race/race.module')
                .then(m => m.RacePageModule)
    },
    {
        path: 'users',
        loadChildren: () =>
            import('src/app/pages/users/users.module')
                .then(m => m.UsersPageModule)
    },
    {
        path: 'teams',
        loadChildren: () =>
            import('src/app/pages/teams/teams.module')
                .then(m => m.TeamsPageModule)
    },
    {
        path: '',
        redirectTo: 'upload',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
