import { Injectable } from '@angular/core';

import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import { EmptyState } from 'src/app/enums/EmptyState';
import { WorkoutType } from 'src/app/core/enums/WorkoutType';
import DisplayMetric from 'src/app/core/models/DisplayMetric';
import DisplayMetricService from 'src/app/core/services/DisplayMetricService';
import IConverter from 'src/app/core/interfaces/IConverter';
import PoolLengthToIdxConverter from 'src/app/core/utils/converters/PoolLengthToIdxConverter';
import User from 'src/app/core/models/User';
import Workout from 'src/app/core/models/Workout';
import { MetricProperty } from 'src/app/core/enums/MetricProperty';

@Injectable({
  providedIn: 'root'
})
export default class DisplayMetricAngularService {
    public displayMetrics: Array<DisplayMetric>;
    public service: DisplayMetricService;

    constructor() {
        this.service = DisplayMetricService.getInstance();
        this.displayMetrics = [];
    }

    private async init(): Promise<void> {
        try {
            this.displayMetrics = await this.service.getDefaults();
        } catch (error) {
            console.error(`DisplayMetricAngularService init()`, error);
        }
    }

    public getDisplayDistance(user: User, type: WorkoutType, workout: Workout, distance: number): string {
        if (!distance) {
            return EmptyState.NO_DATA;
        }

        let displayDistance: string;

        if (workout.distance_unit) {
            switch (type) {
                case WorkoutType.MOTION:
                case WorkoutType.DRYLAND_TRAINING:
                case WorkoutType.WEIGHT_TRAINING:
                    return EmptyState.NO_DATA;
            }

            displayDistance = distance + workout.distance_unit;

            return displayDistance;
        }

        const unitType = workout.getDistanceUnit();

        let convertedNumber: number;
        try {
            const converter: IConverter = new PoolLengthToIdxConverter(workout.pool_length, ConversionUnit.POOL_LENGTH_IDX);
            convertedNumber = converter.convert(unitType);
        } catch (error) {
            console.error(error);
            return EmptyState.NO_DATA;
        }

        displayDistance = (convertedNumber * distance).toFixed(0) + unitType;

        return displayDistance;
    }

    public async getInitialDisplayMetric(): Promise<DisplayMetric> {
        if (!this.displayMetrics.length) {
            try {
                await this.init();
            } catch (error) {
                console.error(`DisplayMetricAngularService getInitialDisplayMetric()`, error);
            }
        }

        return Promise.resolve(this.displayMetrics[0]);
    }

    public async getDisplayMetricForMetricProperty(property: MetricProperty): Promise<DisplayMetric> {
        const displayMetricList = await DisplayMetricService.list();
        for (let i = 0; i < displayMetricList.length; i++) {
            if (displayMetricList[i].property === property) {
                return displayMetricList[i];
            }
        }
    }
}
