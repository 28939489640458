import { Pipe, PipeTransform } from '@angular/core';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import TimerConverter from 'src/app/core/utils/converters/TimerConverter';

@Pipe({
    name: 'dateToTime',
})
export class DateToTimePipe implements PipeTransform {
    transform(start: Date, end: Date): unknown {
        const endDate = new Date(end);
        const startDate = new Date(start);
        const milliseconds = endDate.getTime() - startDate.getTime();

        const timerConverter = new TimerConverter(milliseconds, ConversionUnit.MILLISECONDS);
        return timerConverter.convert(ConversionUnit.TIMER_AUTO_ADVANCE);
    }
}
