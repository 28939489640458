import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import IEventListener from 'src/app/core/interfaces/IEventListener';

import User from 'src/app/core/models/User';
import EventBusService from 'src/app/core/services/EventBusService';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
import { CustomEvent } from 'src/app/enums/CustomEvent';
import { Event } from 'src/app/core/enums/Event';
import Team from 'src/app/core/models/Team';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit, OnDestroy {

    @Input() img?: string;
    @Input() width?: number;
    @Input() height?: number;
    @Input() small?: boolean;
    @Input() user?: User;
    @Input() team?: Team;
    @Input() inverse?: boolean;
    @Input() fontSize?: number;
    @Input() square?: boolean;

    private eventBus: EventBusService;
    private customEventHandler: IEventListener;

    imageWidth = '60px';
    imageHeight = '60px';
    imageSrc: string;
    initials: string;
    initialsFontSize = '32px';
    inversed: boolean;

    constructor(
        private eventBusAngularService: EventBusAngularService,
        private ngZone: NgZone
    ) {
        this.imageSrc = '';
        this.initials = '';
        this.inversed = false;
        this.eventBus = eventBusAngularService.service;
        this.customEventHandler = this.eventBus.addListener(Event.CUSTOM, this.onCustomEvent);
    }

    ngOnInit(): void {
        if (this.width) {
            this.imageWidth = `${this.width}px`;

            if (!this.height) {
                this.imageHeight = `${this.width}px`;
            }
        }

        if (this.height) {
            this.imageHeight = `${this.height}px`;

            if (!this.width) {
                this.imageWidth = `${this.height}px`;
            }
        }

        if (this.fontSize) {
            this.initialsFontSize = `${this.fontSize}px`;
        }

        if (this.img) {
            this.imageSrc = this.img;
        }

        if (this.user) {
            const firstName = this.user.first_name;
            const lastName = this.user.last_name;
            const email = this.user.email;

            const firstNameChar = firstName ? firstName.charAt(0).toUpperCase() : '';
            const lastNameChar = lastName ? lastName.charAt(0).toUpperCase() : '';

            let initials: string;
            if (firstNameChar && lastNameChar) {
                initials = firstNameChar + lastNameChar;
            } else if (firstNameChar && !lastName) {
                initials = firstNameChar;
            } else if (!firstName && lastName) {
                initials = lastNameChar;
            } else {
                initials += email.charAt(0).toUpperCase();
            }

            this.initials = initials;
        }

        if (this.team) {
            for (let i = 0; i < this.team.name.length; i++) {
                if (this.team.name.charAt(i).match(/[a-z]/i)) {
                    this.initials = this.team.name.charAt(i).toUpperCase();
                    break;
                }
            }
        }

        if (this.inverse) {
            this.inversed = this.inverse;
        }
    }

    ngOnDestroy(): void {
        this.customEventHandler.remove();
    }

    private onCustomEvent = (data: any) => {
        const type: CustomEvent = data.type;

        switch (type) {
            case CustomEvent.USER_UPDATED:
                if (this.user) {
                    if (this.user.id === data.user.id) {
                        if (this.imageSrc != data.user.image) {
                            this.ngZone.run(() => {
                                this.imageSrc = data.user.image;
                            });
                        }
                    }
                }
                break;
        }
    }
}
