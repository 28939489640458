import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-triton-card',
    templateUrl: './triton-card.component.html',
    styleUrls: ['./triton-card.component.scss'],
})
export class TritonCardComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
