import { Pipe, PipeTransform } from '@angular/core';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';
import TimerConverter from 'src/app/core/utils/converters/TimerConverter';

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {
    transform(start: Date, end: Date): any {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const ms = endDate.getTime() - startDate.getTime();
        const timerConverter = new TimerConverter(ms, ConversionUnit.MILLISECONDS);
        return timerConverter.convert(ConversionUnit.DURATION);
    }
}
