export enum StrokeName {
    ALL = 'All',
    FLY = 'Fly',
    BACK = 'Back',
    BREAST = 'Breast',
    FREE = 'Free',
    IM = 'IM',
    SWIM = 'Swim',
    KICK = 'Kick',
}
