import { NgModule } from '@angular/core';
import { DurationPipe } from './pipes/duration/duration.pipe';
import { ActivityPipe } from './pipes/activity/activity.pipe';
import { PoolPipe } from './pipes/pool/pool.pipe';
import { MsToTimePipe } from './pipes/ms-to-time/ms-to-time.pipe';
import { DateToTimePipe } from './pipes/date-to-time/date-to-time.pipe';
import { MetricPipe } from './pipes/metric/metric.pipe';
import { InjuryRiskPipe } from './pipes/injury-risk/injury-risk.pipe';

@NgModule({
    declarations: [
        DurationPipe,
        ActivityPipe,
        PoolPipe,
        MsToTimePipe,
        DateToTimePipe,
        InjuryRiskPipe,
        MetricPipe,
    ],
    exports: [
        DurationPipe,
        ActivityPipe,
        InjuryRiskPipe,
        PoolPipe,
        MsToTimePipe,
        DateToTimePipe,
    ],
})
export class PipesModule {}
