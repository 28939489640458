import * as Highcharts from 'highcharts';
import { Component, Input, OnInit } from '@angular/core';
import { CustomEvent } from 'src/app/enums/CustomEvent';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
// import ThemeAngularService from 'src/app/services/theme/theme-angular.service';
import { Event } from 'src/app/core/enums/Event';
import IEventListener from 'src/app/core/interfaces/IEventListener';
import StrokeTotals from 'src/app/core/models/generic/StrokeTotals';
import EventBusService from 'src/app/core/services/EventBusService';

export interface TritonStrokeChartData {
    fly: number;
    back: number;
    breast: number;
    free: number;
    kick: number;
}

@Component({
    selector: 'app-triton-stroke-chart',
    templateUrl: './triton-stroke-chart.component.html',
    styleUrls: ['./triton-stroke-chart.component.scss'],
})
export class TritonStrokeChartComponent implements OnInit {

    @Input() data: StrokeTotals;
    @Input() title: string;
    @Input() height: string | number;
    @Input() width: number;

    private tritonStrokeColors = {
        fly: '#f0e03e',
        back: '#ef7e40',
        breast: '#3ad0e3',
        free: '#7669ff',
        kick: '#c93ae3',
    };
    private onThemeChangedHandler: IEventListener;
    private titleColor: string;
    private eventBus: EventBusService;

    public updateFlag: boolean;

    Highcharts: typeof Highcharts = Highcharts;
    chartOptions: Highcharts.Options;

    constructor(
        private eventBusAngularService: EventBusAngularService,
        // private themeAngularService: ThemeAngularService
    ) {
        this.eventBus = this.eventBusAngularService.service;
    }

    ngOnInit() {
        this.onThemeChangedHandler = this.eventBus.addListener(Event.CUSTOM, this.onThemeChanged);

        // if (this.themeAngularService.darkMode) {
        //     this.titleColor = '#FFFFFF';
        // } else {
            this.titleColor = '#000000';
        // }

        if (this.data) {
            this.renderChart();
        }
    }

    ngOnDestroy() {
        this.onThemeChangedHandler.remove();
    }

    renderChart() {
        this.chartOptions = {
            chart: {
                type: 'column',
                height: this.height || 300,
                style: {
                    overflow: 'visible',
                    fontFamily: 'PT Sans'
                },
                backgroundColor: 'none',
                spacingTop: 0,
                spacingBottom: 0
            },
            title: {
                text: this.title || null,
                floating: false,
                style: {
                    color: this.titleColor,
                    display: 'block',
                    'margin-bottom': 15,
                    fontSize: '1.2vw'
                }
            },
            credits: {
                enabled: false,
            },
            subtitle: {
                text: '',
            },
            legend: {
                enabled: false,
            },
            xAxis: {
                type: 'category',
                categories: [
                    'Fly',
                    'Back',
                    'Breast',
                    'Free',
                    'Kick',
                ],
                labels: {
                    formatter() {
                        return `
                            <div style="display: flex; flex-direction: column; overflow: visible; text-align: center; width: 50px; height: 50px;">
                            <img src="../../../../assets/images/icon-square-${this.value.toString().toLowerCase()}.svg" alt="stroke" />
                            <p style="height: 10px">${this.value}</p>
                            </div>
                            `;
                    },
                    useHTML: true,
                    enabled: true,
                    padding: 2
                },
            },
            tooltip: {
                enabled: true,
            },
            yAxis: {
                min: 0,
                labels: {
                    enabled: false,
                },
                title: {
                    text: null,
                },
                visible: false,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    borderRadius: 4,
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 300,
                            maxHeight: 50,
                        },
                    },
                ],
            },
            series: [
                {
                    name: 'count',
                    type: 'column',
                    data: [
                        {
                            color: this.tritonStrokeColors.fly,
                            y: this.data.fly,
                            pointWidth: 40,
                            pointPadding: 0
                        },
                        {
                            color: this.tritonStrokeColors.back,
                            y: this.data.back,
                            pointWidth: 40,
                            pointPadding: 0
                        },
                        {
                            color: this.tritonStrokeColors.breast,
                            y: this.data.breast,
                            pointWidth: 40,
                            pointPadding: 0
                        },
                        {
                            color: this.tritonStrokeColors.free,
                            y: this.data.free,
                            pointWidth: 40,
                            pointPadding: 0
                        },
                        {
                            color: this.tritonStrokeColors.kick,
                            y: this.data.kick,
                            pointWidth: 40,
                            pointPadding: 0
                        },
                    ],
                },
            ],
        };
    }

    public onThemeChanged = (data: any): void => {
        const type: CustomEvent = data.type;
        const isDarkMode: boolean = data.darkMode;

        switch (type) {
            case CustomEvent.THEME_CHANGED:
                if (isDarkMode) {
                    this.titleColor = '#FFFFFF';
                } else {
                    this.titleColor = '#000000';
                }
                this.renderChart();
                this.updateFlag = true;
                break;
        }
    }
}
