import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AvatarModule } from 'src/app/components/avatar/avatar.module';

import { AthleteTileComponent } from './athlete-tile.component';

@NgModule({
    imports: [
        CommonModule,
        AvatarModule,
    ],
    declarations: [AthleteTileComponent],
    exports: [AthleteTileComponent],
})
export class AthleteTileModule {}
