import InvalidDataError from '../errors/InvalidDataError';

export enum ConversionUnit {
    // Distance
    CENTIMETERS = 'cm',
    INCHES = 'in',
    FEET = 'ft',
    YARDS = 'yd',
    METERS = 'm',
    KILOMETERS = 'km',
    MILES = 'mi',

    // Stroke Rate
    SECONDS_PER_CYCLE = 's/cycle',
    CYCLES_PER_MINUTE = 'cycle/min',
    SECONDS_PER_STROKE = 's/str',
    STROKES_PER_MINUTE = 'str/min',

    // Speed
    CENTIMETERS_PER_SECOND = 'cm/s',
    FEET_PER_SECOND = 'ft/s',
    YARDS_PER_SECOND = 'yd/s',
    METERS_PER_SECOND = 'm/s',
    SECONDS_PER_100Y = 's/100y',
    SECONDS_PER_100M = 's/100m',

    // Dps (Some distance units used as well)
    HS_PER_CYCLE = 'hs/cycle',
    CENTIMETERS_PER_CYCLE = 'cm/cycle',
    FEET_PER_CYCLE = 'f/cycle',
    YARDS_PER_CYCLE = 'yd/cycle',
    METERS_PER_CYCLE = 'm/cycle',

    // RPM
    RPM = 'RPM',

    // Power
    WATTS_PER_KILOGRAM = 'w/kg',

    // Acceleration
    SCALED_PUSHOFF_MAX_ACCEL = 'scaled_pushoff_max_accel',
    METERS_PER_SECOND_SQUARED = 'm/s^2',

    // Stroke Index
    SCALED_STROKE_INDEX = 'stroke_index_scaled',
    METERS_SQUARED_PER_CYCLE_PER_SECOND = 'meters_squared_per_cycle_per_second',

    // Time
    MILLISECONDS = 'milliseconds',
    SECONDS = 'seconds',
    MINUTES = 'minutes',
    HOURS = 'hours',

    // Timer Format
    DURATION = 'HHh MMm',
    TIMER_HHMMSS = 'HH:MM:SS',
    TIMER_HHMMSS_MS = 'HH:MM:SS.MS',
    TIMER_AUTO = 'H?H?:M?M?:S?S',
    TIMER_AUTO_MS = 'H?H?:M?M?:S?S.MS',
    TIMER_AUTO_PAD = 'HH?:MM?:SS',
    TIMER_AUTO_PAD_MS = 'HH?:MM?:SS.MS',
    TIMER_AUTO_ADVANCE = 'H?H:M?M:SS',
    TIMER_AUTO_ADVANCE_MS = 'H?H:M?M:SS.MS',
    TIMER_AUTO_ADVANCE_PAD = 'HH?:MM:SS',
    TIMER_AUTO_ADVANCE_PAD_MS = 'HH?:MM:SS.MS',
    TIMER_AUTO_MS_S = 'MM?:SS.MSs?',

    // Date Format
    JS_DATE = 'js_date',
    EPOCH_MS = 'epoch_ms',
    SQL_TIME = '24:MM:SS',
    SQL_DATE = 'YYYY-MM-DD',
    SQL_DATETIME = 'YYYY-MM-DD HH:MM:SS',
    CUSTOM_TWELVE_HOUR = '12:MM_AM_PM',

    // General
    POOL_LENGTH_IDX = 'pool_len_idx',
    PERCENT = '%',
    MV = 'mV',
}

export function conversionUnitToFullName(unit: ConversionUnit): string {
    switch (unit) {
        // Distance
        case ConversionUnit.CENTIMETERS:
            return 'centimeters';
        case ConversionUnit.FEET:
            return 'feet';
        case ConversionUnit.YARDS:
            return 'yards';
        case ConversionUnit.METERS:
            return 'meters';
        case ConversionUnit.KILOMETERS:
            return 'kilometers';
        case ConversionUnit.MILES:
            return 'miles';

        // Stroke Rate
        case ConversionUnit.SECONDS_PER_CYCLE:
            return 'seconds per cycle';
        case ConversionUnit.CYCLES_PER_MINUTE:
            return 'cycles per minute';
        case ConversionUnit.SECONDS_PER_STROKE:
            return 'seconds per stroke';
        case ConversionUnit.STROKES_PER_MINUTE:
            return 'strokes per minute';

        // Speed
        case ConversionUnit.CENTIMETERS_PER_SECOND:
            return 'centimeters per second';
        case ConversionUnit.FEET_PER_SECOND:
            return 'feet per second';
        case ConversionUnit.YARDS_PER_SECOND:
            return 'yards per second';
        case ConversionUnit.METERS_PER_SECOND:
            return 'meters per second';
        case ConversionUnit.SECONDS_PER_100Y:
            return 'seconds per 100 yards';
        case ConversionUnit.SECONDS_PER_100M:
            return 'seconds per 100 meters';

        // Dps
        case ConversionUnit.HS_PER_CYCLE:
            return 'hs per cycle';
        case ConversionUnit.CENTIMETERS_PER_CYCLE:
            return 'centimeters per cycle';
        case ConversionUnit.FEET_PER_CYCLE:
            return 'feet per cycle';
        case ConversionUnit.YARDS_PER_CYCLE:
            return 'yards per cycle';
        case ConversionUnit.METERS_PER_CYCLE:
            return 'meters per cycle';

        // RPM
        case ConversionUnit.RPM:
            return 'rpm';

        // Power
        case ConversionUnit.WATTS_PER_KILOGRAM:
            return 'watts per kilogram';

        // Acceleration
        case ConversionUnit.SCALED_PUSHOFF_MAX_ACCEL:
            return 'scaled pushoff max acceleration'
        case ConversionUnit.METERS_PER_SECOND_SQUARED:
            return 'meters per second squared';

        // Time
        case ConversionUnit.MILLISECONDS:
            return 'milliseconds';
        case ConversionUnit.SECONDS:
            return 'seconds';
        case ConversionUnit.MINUTES:
            return 'minutes';
        case ConversionUnit.HOURS:
            return 'hours';

        // Timer Format
        case ConversionUnit.DURATION:
            return 'Duration';
        case ConversionUnit.TIMER_HHMMSS:
            return 'Timer HH:MM:SS';
        case ConversionUnit.TIMER_HHMMSS_MS:
            return 'Timer HH:MM:SS.MS';
        case ConversionUnit.TIMER_AUTO:
            return 'Timer H?H:M?M:S?S';
        case ConversionUnit.TIMER_AUTO_MS:
            return 'Timer H?H:M?M:S?S.MS';
        case ConversionUnit.TIMER_AUTO_PAD:
            return 'Timer HH?:MM?:SS';
        case ConversionUnit.TIMER_AUTO_PAD_MS:
            return 'Timer HH?:MM?:SS.MS';
        case ConversionUnit.TIMER_AUTO_ADVANCE:
            return 'Timer H?H:M?M:SS';
        case ConversionUnit.TIMER_AUTO_ADVANCE_MS:
            return 'Timer H?H:M?M:SS.MS';
        case ConversionUnit.TIMER_AUTO_ADVANCE_PAD:
            return 'Timer HH?:MM:SS';
        case ConversionUnit.TIMER_AUTO_ADVANCE_PAD_MS:
            return 'Timer HH?:MM:SS.MS';

        // Date Format
        case ConversionUnit.JS_DATE:
            return 'js date';
        case ConversionUnit.EPOCH_MS:
            return 'epoch ms';
        case ConversionUnit.CUSTOM_TWELVE_HOUR:
            return 'Custom 12 hour time';
        case ConversionUnit.SQL_TIME:
            return 'sql time';
        case ConversionUnit.SQL_DATE:
            return 'sql date';
        case ConversionUnit.SQL_DATETIME:
            return 'sql datetime';

        // General
        case ConversionUnit.POOL_LENGTH_IDX:
            return 'pool length idx';
        case ConversionUnit.PERCENT:
            return 'percent';
        case ConversionUnit.MV:
            return 'millivolts';

        default:
            throw new InvalidDataError(`ConversionUnit conversionUnitToFullName(): unknown Conversion Unit ${unit}`);
    }
}
