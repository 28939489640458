<div class="athlete-grid">
    <app-athlete-tile
        [user]="athlete"
        [index]="i"
        [teamId]="teamId"
        [isFiller]="athlete.filler"
        [showTritonScore]="showTritonScore"
        *ngFor="let athlete of athletes; index as i"
    ></app-athlete-tile>
</div>
