import { ConversionUnit } from '../enums/ConversionUnit';
import { Privacy } from '../enums/Privacy';
import { Table } from '../enums/Table';
import { UserRole } from '../enums/UserRole';
import DateTimeConverter from '../utils/converters/DateTimeConverter';
import { deepCopy } from '../utils/deepCopy';
import CrudModel from './generic/CrudModel';

export default class UserTeam extends CrudModel<UserTeam> {

    public id: number;
    public role: UserRole;
    public privacy: Privacy;
    public user_id: number;
    public team_id: number;
    public created_at: Date;
    public updated_at: Date;
    public deleted_at: Date;

    constructor () {
        super();
        this.role = UserRole.MEMBER;
        this.privacy = Privacy.ALL;
        this.user_id = null;
        this.team_id = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
    }

    public parseJson(json: any): void {
        this.id = json.id;
        this.role = json.role;
        this.privacy = json.privacy;
        this.user_id = json.user_id;
        this.team_id = json.team_id;
        if (json.created_at) {
            this.created_at = new Date(json.created_at);
        }

        if (json.updated_at) {
            this.updated_at = new Date(json.updated_at);
        }

        if (json.deleted_at) {
            this.deleted_at = new Date(json.deleted_at);
        }
    }

    public parseData(data: any): void {
        this.id = data.user_team_id;
        this.role = data.user_team_role;
        this.privacy = data.user_team_privacy;
        this.user_id = data.user_team_user_id;
        this.team_id = data.user_team_team_id;
 
        if (data.user_team_created_at) {
            if (data.user_team_created_at instanceof Date) {
                this.created_at = data.user_team_created_at;
            } else {
                const createdDateTimeConverter = new DateTimeConverter(data.user_team_created_at, ConversionUnit.SQL_DATETIME);
                this.created_at = createdDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.user_team_updated_at) {
            if (data.user_team_updated_at instanceof Date) {
                this.updated_at = data.user_team_updated_at;
            } else {
                const updatedDateTimeConverter = new DateTimeConverter(data.user_team_updated_at, ConversionUnit.SQL_DATETIME);
                this.updated_at = updatedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }

        if (data.user_team_deleted_at) {
            if (data.user_team_deleted_at instanceof Date) {
                this.deleted_at = data.user_team_deleted_at;
            } else {
                const deletedDateTimeConverter = new DateTimeConverter(data.user_team_deleted_at, ConversionUnit.SQL_DATETIME);
                this.deleted_at = deletedDateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
            }
        }
    }

    public duplicate(): UserTeam {
        const properties = deepCopy(this);
        const newCopy = new UserTeam();
        newCopy.parseJson(properties);

        return newCopy;
    }

    public object(): any {
        throw new Error('Method not implemented.');
    }

    public isCoach(): boolean {
        const isAdmin = this.isAdmin();
        
        if (this.role === UserRole.COACH || isAdmin) {
            return true;
        }

        return false;
    }

    public isAdmin(): boolean {
        if (this.role === UserRole.ADMIN || this.role === UserRole.ADMIN_PARENT_TEAM) {
            return true;
        }
   
        return false;
    }

    public getDerivedProperties(): Array<string> {
        return [];
    }

    public getTableName(): string {
        return Table.USER_TEAMS;
    }
}
