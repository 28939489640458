import InvalidDataError from '../errors/InvalidDataError';
import { MetricName } from './MetricName';

export enum MetricProperty {
    ID = 'id',
    REP_ID = 'rep_id',
    STROKE_TYPE = 'stroke_type',
    LAP = 'lap',
    SPLIT_TIME = 'split_time',
    STROKES = 'strokes',
    BREATHS = 'breaths',
    CADENCE = 'cadence',
    DPS = 'dps',
    TURN_TIME = 'turn_time',
    PACE_TIME = 'pace_time',
    TIME_UW = 'time_uw',
    DP_KICK = 'dp_kick',
    STROKE_INDEX = 'stroke_index',
    SWOLF = 'swolf',
    DISTANCE = 'distance',
    SWIM_END = 'swim_end',
    DIVE = 'dive',
    TURN_TYPE = 'turn_type',
    PUSH_STRENGTH = 'push_strength',
    LATENCY = 'latency',
    RETRIES = 'retries',
    CREATED_AT = 'created_at',
    UPDATED_AT = 'updated_at',
    REP_START_MS = 'rep_start_ms',
    REP_NUMBER = 'rep_number',
    LENGTH_NUMBER = 'length_number',
    STROKE_FIRST_S = 'stroke_first_s',
    STROKE_LAST_S = 'stroke_last_s',
    START_TYPE = 'start_type',
    METRIC_TYPE = 'metric_type',
    DK_COUNT = 'dk_count',
    DIR_AND_CONF = 'dir_and_conf',
    STROKE_CONF = 'stroke_conf',
    CYCLE_START_HS = 'cycle_start_hs',
    CYCLE_MID_HS = 'cycle_mid_hs',
    CYCLE_END_HS = 'cycle_end_hs',
    MAX_ACC_MID_CMPS = 'max_acc_mid_cmps',
    MAX_ACC_END_CMPS = 'max_acc_end_cmps',
    AVG_CYCLE_HS = 'avg_cycle_hs',
    UW_DIST_CM = 'uw_dist_cm',
    PUSHOFF_POWER = 'pushoff_power',
    VERSION = 'version',
    METRIC_NUMBER = 'metric_number',
    SETREP_NUMBER = 'setrep_number',
    SPLIT_START_MS = 'split_start_ms',
    SPLIT_END_MS = 'split_end_ms',
    FEET_START_MS = 'feet_start_ms',
    FEET_END_MS = 'feet_end_ms',
    BO_MS = 'bo_ms',
    GLIDE_END_MS = 'glide_end_ms',
    TURN_START_MS = 'turn_start_ms',
    TURN_END_MS = 'turn_end_ms',
    TURN_MAX_SPIN = 'turn_max_spin',
    REP_END_MS = 'rep_end_ms',
    TURN_MAX_DIVE = 'turn_max_dive',
    REP_STROKE_TYPE = 'rep_stroke_type',
    POOL_LEN_CM = 'pool_len_cm',
    POOL_LEN_IDX = 'pool_len_idx',
    POOL_HEADING = 'pool_heading',
    PUSHOFF_MAX_ACCX = 'pushoff_max_accx',
    END_CONF = 'end_conf',
    AVERAGE_SPD_CMPS = 'average_spd_cmps',
    UW_MAXDEPTH_CM = 'uw_maxdepth_cm',
    STROKES_TO_FIRST_BREATH = 'strokes_to_first_breath',
    STROKES_AFTER_LAST_BREATH = 'strokes_after_last_breath',
    UW_SPD_CMPS = 'uw_spd_cmps',
    PACE_TIME_S = 'pace_time_s',
    REST_TIME_S = 'rest_time_s',
    PUSHOFF_ANGLE = 'pushoff_angle',
    DK_CYCLE_HS = 'dk_cycle_hs',
    DK_UPKICK_ACCEL = 'dk_upkick_accel',
    DK_DNKICK_ACCEL = 'dk_dnkick_accel',
    UW_ENTRY_MS = 'uw_entry_ms',
    TRANSITION_START_MS = 'transition_start_ms',
    TRANSITION_END_MS = 'transition_end_ms',
    PUSHOFF_DEPTH = 'pushoff_depth',
    REF_EPOCH_UTC = 'ref_epoch_utc',
    STROKE_SPD_CMPS = 'stroke_spd_cmps',
    INTENSITY = 'intensity',
    IS_GOOD = 'is_good',
    IS_CORE = 'is_core',
    LABELED = 'labeled',
    TIME_OW = 'time_ow',
    TURN_RATE = 'turn_rate',
    TRANSITION_TIME = 'transition_time',
    PUSH_TIME = 'push_time',
    PUSH_MAX_ACC = 'push_max_acc',
    DIVE_TIME = 'dive_time',
    DIVE_HANG_TIME = 'dive_hang_time',
    DIVE_BLOCK_TIME = 'dive_block_time',
    PERCENT_UNDERWATER = 'percent_underwater',
    SPEED_OW = 'speed_ow',
    ADDED_AT = 'added_at',
    MODIFIED_AT = 'modified_at',
    METRIC_COUNT = 'metric_count',
    READ_COUNT = 'read_count',
    ICV_LEFT_CMPS = 'icv_left_cmps',
    ICV_RIGHT_CMPS = 'icv_right_cmps',
    ICV_LEFT_BREATH_CMPS = 'icv_left_breath_cmps',
    ICV_RIGHT_BREATH_CMPS = 'icv_right_breath_cmps',
}

export function metricPropertyToName(metricProperty: MetricProperty): MetricName {
    switch (metricProperty) {
        case MetricProperty.STROKES:
            return MetricName.STROKES;
        case MetricProperty.AVG_CYCLE_HS:
            return MetricName.AVG_CYCLE_HS;
        case MetricProperty.BREATHS:
            return MetricName.BREATHS;
        case MetricProperty.DPS:
            return MetricName.DPS;
        case MetricProperty.TURN_TIME:
            return MetricName.TURN_TIME;
        case MetricProperty.TIME_UW:
            return MetricName.TIME_UW
        case MetricProperty.STROKE_INDEX:
            return MetricName.STROKE_INDEX;
        case MetricProperty.SWOLF:
            return MetricName.SWOLF;
        case MetricProperty.PUSH_STRENGTH:
            return MetricName.PUSH_STRENGTH;
        case MetricProperty.PUSHOFF_MAX_ACCX:
            return MetricName.PUSHOFF_MAX_ACCX
        case MetricProperty.UW_MAXDEPTH_CM:
            return MetricName.UW_MAXDEPTH_CM;
        case MetricProperty.UW_SPD_CMPS:
            return MetricName.UW_SPD_CMPS;
        case MetricProperty.PUSHOFF_DEPTH:
            return MetricName.PUSHOFF_DEPTH;
        case MetricProperty.TRANSITION_TIME:
            return MetricName.TRANSITION_TIME;
        case MetricProperty.PUSH_TIME:
            return MetricName.PUSH_TIME
        case MetricProperty.PERCENT_UNDERWATER:
            return MetricName.PERCENT_UNDERWATER;
        case MetricProperty.SPEED_OW:
            return MetricName.SPEED_OW;
        case MetricProperty.CYCLE_START_HS:
            return MetricName.CYCLE_START_HS;
        case MetricProperty.CYCLE_MID_HS:
            return MetricName.CYCLE_MID_HS;
        case MetricProperty.CYCLE_END_HS:
            return MetricName.CYCLE_END_HS;
        case MetricProperty.DIVE_TIME:
            return MetricName.DIVE_TIME;
        case MetricProperty.DIVE_HANG_TIME:
            return MetricName.DIVE_HANG_TIME;
        case MetricProperty.DIVE_BLOCK_TIME:
            return MetricName.DIVE_BLOCK_TIME;
        default:
            throw new InvalidDataError(`MetricProperty metricPropertyToName(): Unknown metric property ${metricProperty}`);
    }
}
