export function deepCopy<T>(value: T): T {
    let val: any;

    if (value && Array.isArray(value)) {
        val = [...value];
    } else if (value && typeof value === 'object') {
        val = { ...value };
    } else {
        return value;
    }

    Object.keys(val).forEach((key) => {
        if (val[key]) {
            if ((Array.isArray(val[key]) || (typeof val[key] === 'object')) && !(val[key] instanceof Date)) {
                val[key] = deepCopy(val[key]);
            }

            if (val[key] instanceof Date) {
                const dateObject = val[key] as Date;

                if (key.endsWith('_at')) {
                    val[key] = dateObject.toISOString();
                } else if (key.endsWith('_date')) {
                    const parts = dateObject.toISOString().split(' ');
                    val[key] = parts[0];
                } else {
                    val[key] = val[key].toISOString();
                }
            }
        }
    });

    return <T> val;
};
