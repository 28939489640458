import { Component, OnInit, Input } from '@angular/core';

import { EmptyState } from 'src/app/enums/EmptyState';
import { Privacy } from 'src/app/core/enums/Privacy';
import { UserRole } from 'src/app/core/enums/UserRole';
import AuthAngularService from 'src/app/services/auth/auth-angular.service';
import User from 'src/app/core/models/User';
import { Router } from '@angular/router';
import IEventBus from 'src/app/core/interfaces/IEventBus';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
import { Event } from 'src/app/core/enums/Event';
import { CustomEvent } from 'src/app/enums/CustomEvent';

@Component({
    selector: 'app-athlete-tile',
    templateUrl: './athlete-tile.component.html',
    styleUrls: ['./athlete-tile.component.scss']
})
export class AthleteTileComponent implements OnInit {
    @Input() user: User;
    @Input() teamId: number;
    @Input() isFiller: boolean;
    @Input() index: number;
    @Input() showTritonScore: boolean;
    public athleteImage: string;
    public athleteName: string;
    public tritonScore: string;
    public isPrivate: boolean;
    private eventBus: IEventBus;

    constructor(
        private authAngularService: AuthAngularService,
        eventBusAngularService: EventBusAngularService,
        private router: Router
    ) {
        this.athleteImage = '';
        this.athleteName = '';
        this.tritonScore = EmptyState.TIME_GENERIC;
        this.isPrivate = true;
        this.eventBus = eventBusAngularService.service;
    }

    ngOnInit(): void {
        if (this.user && !this.isFiller) {
            const user = this.user.duplicate();

            if (this.showTritonScore) {
                const userTeam = user.getUserTeam(this.teamId);

                switch (userTeam.privacy) {
                    case Privacy.COACH_ONLY:
                        const authUser = this.authAngularService.getUser();
                        const authUserTeam = authUser.getUserTeam(this.teamId);
                        const authUserRole = authUserTeam.role;

                        if (authUserRole === UserRole.ADMIN_PARENT_TEAM
                        || authUserRole === UserRole.ADMIN
                        || authUserRole === UserRole.COACH
                        ) {
                            this.isPrivate = true;
                        }

                        break;
                    case Privacy.ALL:
                        this.isPrivate = false;

                        break;
                    default:
                        throw new Error(`AthleteTileComponent ngOnInit(): Unknown user team privacy ${userTeam.privacy}`);
                }
            } else {
                this.isPrivate = false;
            }

            if (user.image) {
                this.athleteImage = user.image;
            } else {
                this.updateUrl();
            }

            this.athleteName = user.full_name ? user.full_name : 'User Id: ' + user.id.toString();
        }
    }

    public updateUrl(): void {
        this.athleteImage = '';
    }

    public goToAthleteView(userId: number): void {
        if (!this.isPrivate) {
            this.router.navigate([`/dashboard/athlete-details/${userId}/workout-activities`]);
        }
    }

    public onClick(userId: number): void {
        if (!this.isPrivate) {
            this.eventBus.emit(Event.CUSTOM, { type: CustomEvent.ATHLETE_TILE_CLICKED, index: this.index, userId });
        }
    }
}
