import { ConversionUnit } from '../../enums/ConversionUnit';
import { tableToPrefix } from '../../enums/Table';
import InvalidDataError from '../../errors/InvalidDataError';
import DateTimeConverter from '../../utils/converters/DateTimeConverter';
import SqlDateTimeValidator from '../../utils/validators/SqlDateTimeValidator';
import SqlDateValidator from '../../utils/validators/SqlDateValidator';
import Model from './Model';

export default class CrudModel<T> extends Model<T> {
    public id?: number;
    public created_at?: Date;
    public deleted_at?: Date;
    public updated_at?: Date;

    constructor() {
        super();

        this.getTableName.bind(this);
        this.getDerivedProperties.bind(this);
        this.parseData.bind(this);
    }

    /**
     *  Table name in the database that's rows represent one of these instances
     */
    public getTableName(): string {
        throw new Error('Classes that extend CrudModel must implement getTableName()');
    }

    /**
     *  Return an array of keys that are calculated at runtime not parsed
     *  in parseJson() (Model<T>) or parseData
     */
    public getDerivedProperties(): Array<string> {
        throw new Error('Classes that extend CrudModel must implement getDerivedProperties()');
    }

    /**
     * Override parseJson from Model<T> and unset the derived properties.
     * These should never be parsed and always be rederived afer parsing is complete.
     */
    public parseJson(json: any): void {
        super.parseJson(json);

        // @TODO: check if the model was properly initialized....
        // ie null (NOT undefined) for CrudModels etc. and potentially throw an 
        // InvalidStateError('this model is not properly initialized')
        // Should probably check using the Tables enum like in CrudModelBuilder

        // clear derived properties if necessary
        const derivedProperties = this.getDerivedProperties();
        derivedProperties.forEach((derivedProperty: string) => {
            (this as any)[derivedProperty] = undefined;
        });
    }

    /**
     * Loads properties from object returned from a database query. Requires
     * that each property begins with the 'table_' prefix
     * 
     * @param data - object representing a row in the database
     */
    public parseData(data: any): void {
        if (!data) {
            throw new InvalidDataError('CrudModel.parseData(): No Data');
        }

        // @TODO: Is there a way to check if the model was properly initialized....
        // ie null (NOT undefined) for Models etc. and potentially throw an 
        // InvalidStateError('this CrudModel is not properly initialized')
        const table = this.getTableName();
        const keyPrefix = tableToPrefix(table);
        const keys = Object.keys(data);
        const derivedProperties = this.getDerivedProperties();

        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const property = key.substr(keyPrefix.length);

            if (derivedProperties.indexOf(property) !== -1) {
                continue; // Ignore derived properties
            } else if (!key.startsWith(keyPrefix)) {
                continue; // Ignore data for other models (ie when joins are done in the query)
            }  else if (!this.hasOwnProperty(property)) {
                // @TODO in the future we should check if the proprty is actually
                // meant for a submodels table, otherwise we should throw the error
                // throw new InvalidDataError(`CrudModel.parseData(): Unknown property '${property}' for key '${key}'`);
                continue;
            }

            // Database naming conventions dictate that DATETIME columns must end in '_at',
            // DATE columns must end in '_date', relations into other tables must be numbers 
            // and end in '_id'. These postfixes are exclusively reserved for those data types.
            if (key.endsWith('_at')) {
                if (!data[key]) {
                    (this as any)[property] = null;
                } else if (data[key] instanceof Date) {
                    (this as any)[property] = data[key];
                } else if (SqlDateTimeValidator.isValid(data[key])) {
                    const dateTimeConverter = new DateTimeConverter(data[key], ConversionUnit.SQL_DATETIME);
                    (this as any)[property] = dateTimeConverter.convert(ConversionUnit.JS_DATE) as Date;
                } else {
                    throw new InvalidDataError(`CrudModel.parseData(): ${key} value '${data[key]}' is not a proper SQL_DATE or SQL_DATETIME format`);
                }
            } else if (key.endsWith('_date')) {
                if (!data[key]) {
                    (this as any)[property] = null;
                } else if (data[key] instanceof Date) {
                    (this as any)[property] = data[key];
                } else if (SqlDateValidator.isValid(data[key])) {
                    const dateConverter = new DateTimeConverter(data[key], ConversionUnit.SQL_DATE);
                    (this as any)[property] = dateConverter.convert(ConversionUnit.JS_DATE) as Date;
                } else {
                    throw new InvalidDataError(`CrudModel.parseData(): ${key} value '${data[key]}' is not a proper SQL_DATE or SQL_DATETIME format`);
                }
            } else if (key.endsWith('_id') && data[key] !== null) {
                if (!(typeof data[key] === 'number')) {
                    throw new InvalidDataError(`CrudModel.parseJson() '${key}' exists but is not a number, '_id' is a reserved postfix for numbers representing row id's. ${key}: ${data[key]}`);
                }

                (this as any)[property] = data[key];
            } else if (!(typeof (this as any)[property] === 'object')) {
                (this as any)[property] = data[key];
            }

            // @TODO: User CrudModelBuilder to build any CrudModels we can identify
        }
    }
}