import { ChartModule } from 'angular-highcharts';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgModule } from '@angular/core';

import { TritonActivityGaugeComponent } from './triton-activity-gauge/triton-activity-gauge.component';
import { TritonRepGraphComponent } from './triton-rep-graph/triton-rep-graph.component';
import { TritonSetGraphComponent } from './triton-set-graph/triton-set-graph.component';
import { TritonStrokeChartComponent } from './triton-stroke-chart/triton-stroke-chart.component';
import { TritonVerticalBarComponent } from './triton-vertical-bar/triton-vertical-bar.component';
import { TwIconModule } from '../tw-icon/tw-icon.module';
import { RaceAnalysisChartComponent } from './race-analysis-chart/race-analysis-chart.component';

@NgModule({
    imports: [
        ChartModule,
        CommonModule,
        HighchartsChartModule,
        TwIconModule,
    ],
    declarations: [
        TritonActivityGaugeComponent,
        TritonStrokeChartComponent,
        TritonVerticalBarComponent,
        TritonSetGraphComponent,
        TritonRepGraphComponent,
        RaceAnalysisChartComponent
    ],
    exports: [
        TritonSetGraphComponent,
        TritonRepGraphComponent,
        TritonActivityGaugeComponent,
        TritonStrokeChartComponent,
        TritonVerticalBarComponent,
        RaceAnalysisChartComponent
    ],
})
export class TritonChartModule { }
