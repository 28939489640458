import InvalidDataError from '../errors/InvalidDataError';
import { StrokeName } from './StrokeName';

export enum StrokeType {
    ALL = 0,
    FLY = 1,
    BACK = 2,
    BREAST = 3,
    FREE = 4,
    IM = 5,
    SWIM = 6,
    KICK = 8
}

export function strokeTypeToName(stroke: StrokeType): StrokeName {
    switch (stroke) {
        case StrokeType.ALL:
            return StrokeName.ALL;
        case StrokeType.FLY:
            return StrokeName.FLY;
        case StrokeType.BACK:
            return StrokeName.BACK;
        case StrokeType.BREAST:
            return StrokeName.BREAST;
        case StrokeType.FREE:
            return StrokeName.FREE;
        case StrokeType.IM:
            return StrokeName.IM;
        case StrokeType.SWIM:
            return StrokeName.SWIM;
        case StrokeType.KICK:
            return StrokeName.KICK;
        default:
            throw new InvalidDataError(`strokeToName(): Invalid StrokeType ${stroke}`);
    }
}
