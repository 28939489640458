import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import IEventBus from 'src/app/core/interfaces/IEventBus';
import IEventListener from 'src/app/core/interfaces/IEventListener';
import User from 'src/app/core/models/User';
import EventBusAngularService from 'src/app/services/event-bus/event-bus-angular.service';
import { Event } from 'src/app/core/enums/Event';
import { Router } from '@angular/router';
import { CustomEvent } from 'src/app/enums/CustomEvent';

@Component({
    selector: 'app-athelete-chooser',
    templateUrl: './athlete-chooser.component.html',
    styleUrls: ['./athlete-chooser.component.scss'],
})
export class AthleteChooserComponent implements OnInit, OnDestroy {
    public teamId: number;
    public users: Array<User>;
    public data: Array<any>;
    public title: string;
    public subtitle: string;
    private eventBus: IEventBus;
    private eventListener: IEventListener;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any,
        public router: Router,
        public dialogRef: MatDialogRef<any>,
        angularEventBusService: EventBusAngularService
    ) {
        this.eventBus = angularEventBusService.service;
        this.users = [];
        this.data = [];

        this.eventListener = this.eventBus.addListener(Event.CUSTOM, this.onEvent);
        this.teamId = this.dialogData.teamId;
        this.title = this.dialogData.title;
        this.subtitle = this.dialogData.subtitle;
        this.users = this.dialogData.users;
        this.data = this.dialogData.data;
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.eventListener.remove();
    }

    public onEvent = (data: any) => {
        switch (data.type) {
            case CustomEvent.ATHLETE_TILE_CLICKED:
                this.selectAthlete(data.index);
                break;
        }
    }

    selectAthlete(index: number): void {
        if (this.users.length <= index) {
            console.error(`athlete-selection.selectAthlete() index '${index}' out of bounds for array of length '${this.users.length}`);
            return;
        }

        this.eventBus.emit(Event.CUSTOM, { type: CustomEvent.ATHLETE_SELECTED, user: this.users[index], data: this.data[index] });

        this.dialogRef.close();
    }
}
