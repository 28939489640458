import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import TimerConverter from 'src/app/core/utils/converters/TimerConverter';
import { ConversionUnit } from 'src/app/core/enums/ConversionUnit';

@Component({
    selector: 'app-race-analysis-chart',
    templateUrl: './race-analysis-chart.component.html',
    styleUrls: ['./race-analysis-chart.component.scss'],
})
export class RaceAnalysisChartComponent implements OnInit, OnChanges {
    @Input() data: Array<Highcharts.SeriesOptionsType>;
    @Input() isTime: boolean;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() categories: Array<string>;
    public chart: Chart;

    constructor() {}

    ngOnInit(): void {
        this.renderChart();
    }

    ngOnChanges(): void {
        this.renderChart();
    }

    private renderChart(): void {
        this.chart = new Chart({
            chart: {
                type: 'line',
                backgroundColor: '#F8F8F8'
            },
            title: {
                text: this.title,
                align: 'left',
                style: {
                    fontWeight: 'bold',
                    fontSize: '16px'
                }
            },
            subtitle: {
                text: this.subtitle,
                align: 'left',
                style: {
                    color: '#299de0',
                    fontWeight: 'bold',
                    fontSize: '16px'
                }
            },
            legend: {
                align: 'right',
                layout: 'vertical',
                labelFormatter(): string {
                    return `${this.name} Final Avg.`;
                },
                verticalAlign: 'top',
                floating: true,
                itemStyle: {
                    fontSize: '16px',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                formatter(): string {
                    let cumulativeValue = 0;
                    for (let i = 0; i <= this.point.index; i++) {
                        cumulativeValue += this.series.points[i].y;
                    }

                    cumulativeValue = Math.round(cumulativeValue * 100) / 100;

                    const timeMetric: boolean = this.series.chart.title.element.textContent === 'Metric: Time';

                    let html = `<div style="display: flex;">
                    <img style="width: 32px; height: 32px; border-radius: 100%;" src="${this.series.options.description}" />
                    <div style="font-size: 16px; color: #FFFFFF; font-weight: bold; padding-top: 5px; padding-left: 5px;">${this.series.name}</div>
                    </div>
                    <span style="color: #FFFFFF; font-size: 14px; text-align: center;">Lap ${this.point.index + 1} Split: ${this.y}</span>
                    `;

                    if (timeMetric) {
                        html += `
                        <div style="color: #FFFFFF; font-size: 14px; display: block;">${this.point.category} Split: ${cumulativeValue} </div>
                        `;
                    } else {
                        const avgValue = Math.round((cumulativeValue / (this.point.index + 1)) * 100) / 100;
                        html += `
                        <div style="color: #FFFFFF; font-size: 14px; display: block;">${this.point.category} Avg: ${avgValue} </div>
                        `;
                    }

                    return html;
                },
                backgroundColor: '#354E7950',
                borderWidth: 0,
                style: {
                    opacity: 100
                },
                useHTML: true
            },
            xAxis: {
                categories: this.categories,
                startOnTick: true
            },
            yAxis: {
                labels: this.isTime ? {
                    formatter: function () {
                        const label = this.axis.defaultLabelFormatter.call(this);
                        const timeInSeconds = Math.abs(Number(label));
                        const timerConverter = new TimerConverter(timeInSeconds, ConversionUnit.SECONDS);
                        const converted = timerConverter.convert(ConversionUnit.TIMER_AUTO_ADVANCE_PAD_MS);
                        return converted;
                    }
                } : { }
            },
            plotOptions: {
                line: {
                    marker: {
                        symbol: 'circle',
                        radius: 10
                    },
                    lineWidth: 6,
                },
                series: {
                    events: {
                        checkboxClick(event): void {
                            if (!event.checked) {
                                this.hide();
                            } else {
                                this.show();
                            }
                        }
                    },
                }
            },
            responsive: {
                rules: [
                    {
                        chartOptions: {
                            title: {
                                style: {
                                    fontSize: '14px'
                                }
                            },
                            subtitle: {
                                style: {
                                    fontSize: '12px',
                                    width: 100,
                                    textOverflow: 'wrap'
                                }
                            },
                            plotOptions: {
                                line: {
                                    marker: {
                                        radius: 5
                                    },
                                    lineWidth: 2,
                                }
                            },
                            yAxis: {
                                labels: {
                                    style: {
                                        fontSize: '10px'
                                    }
                                }
                            }
                        },
                        condition: {
                            maxWidth: 450
                        }
                    }
                ]
            },
            series: this.data
        });
    }
}
