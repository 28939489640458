import { Injectable } from "@angular/core";
import User from "src/app/core/models/User";

@Injectable({
    providedIn: 'root',
})
export default class AuthAngularService {
    public getUser(): User {
        return new User();
    }
}
